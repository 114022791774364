import { CommonIconButtonProps, StandardIconButton } from './StandardIconButton'
import { SendIcon, SendActiveIcon } from 'icons'

const SendIconButton: React.FC<CommonIconButtonProps> = ({ id, active, size = 'medium', onClick, disabled }) => (
    <StandardIconButton
        id={id}
        color='secondary'
        icon={active ? SendActiveIcon : SendIcon}
        onClick={onClick}
        size={size}
        disabled={disabled}
    />
)

export { SendIconButton }
