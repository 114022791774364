import { Reducer } from 'react'
import { BookingContextState, BookingAction } from './types'

interface BookingReducer {
    (): Reducer<BookingContextState, BookingAction>
}

const reducer: BookingReducer = () => {
    return (state, action) => {
        switch (action.method) {
            case 'set-status':
                return { ...state, status: action.status }

            case 'clear-session':
                sessionStorage.removeItem('classroomId')
                sessionStorage.removeItem('userId')
                sessionStorage.removeItem('auth')
                sessionStorage.removeItem('role')
                sessionStorage.removeItem('diagnoseStatus')
                sessionStorage.removeItem('isTeensClass')
                sessionStorage.removeItem('callbackUrl')
                return {
                    ...state,
                    status: 'no-session'
                }

            case 'set-classroom':
                sessionStorage.setItem('classroomId', action.classroomId)
                sessionStorage.setItem('userId', action.userId)
                sessionStorage.setItem('auth', action.auth)
                sessionStorage.setItem('isTeensClass', action.isTeens)
                sessionStorage.setItem('role', action.check.user.role)
                sessionStorage.setItem('callbackUrl', action.callbackUrl)

                return {
                    ...state,
                    status: 'validated',
                    classroomId: action.classroomId,
                    auth: action.auth,
                    check: action.check,
                    user: action.check.user
                }

            default:
                return state
        }
    }
}

export { reducer }
