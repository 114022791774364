import { RefObject } from 'react'
import { styled } from '@mui/material'
import { WaitingForOthers } from 'components/status-panels'
import { ZoomLayout, BorZoomLayout } from 'components/participants'
import { SlidesPresentation } from './components'
import { BreakoutRoomsState } from 'pages/useBreakouts'

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}))
const ParticipantsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '30%',
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    '> div': {
        width: '100%'
    }
}))

const SlidesView: React.FC<{
    breakouts?: BreakoutRoomsState
    wrapperRef?: RefObject<HTMLDivElement>
}> = ({ breakouts, wrapperRef }) => (
    <Wrapper>
        <ParticipantsWrapper ref={wrapperRef} id='teacher-slides-participants'>
            <WaitingForOthers breakouts={breakouts} />
            {breakouts?.isActive ? (
                <BorZoomLayout breakouts={breakouts} />
            ) : (
                <ZoomLayout breakouts={breakouts} />
            )}
        </ParticipantsWrapper>
        <SlidesPresentation />
    </Wrapper>
)

export { SlidesView }
