import { styled, IconButton, IconButtonProps } from '@mui/material'
import { Icon, IconProps, IconSize } from './Icon'

type StandardIconButtonProps = {
    id?: string
    disabled?: boolean
    onClick?: IconButtonProps['onClick']
    icon: IconProps['src']
    color: IconButtonProps['color']
    size?: IconSize
    children?: React.ReactNode
}

const StyledButton = styled(IconButton)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: theme.spacing(5),
        height: theme.spacing(5)
    }
}))

const StandardIconButton: React.FC<StandardIconButtonProps> = ({ id, icon, color, size = 'medium', onClick, disabled, children }) => (
    <StyledButton id={id} size={size} color={color} onClick={onClick} disabled={disabled}>
        <Icon src={icon} />
        {children}
    </StyledButton>
)

type CommonIconButtonProps = {
    id?: string
    active?: boolean
    size?: IconSize
    onClick?: StandardIconButtonProps['onClick']
    disabled?: boolean
    children?: React.ReactNode
}

export type { StandardIconButtonProps, CommonIconButtonProps }
export { StandardIconButton }
