import { styled } from '@mui/material'
import { CommonButtonProps, StandardButton } from 'components/buttons'
import { MicActiveIconLight, MicDisabledIconLight, OrangeAlertIcon } from 'icons'

const AlertWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    transform: 'translate(20px, -18px) scale(0.85)',
    height: '24px'
}))

const MicLightIconButton: React.FC<CommonButtonProps & { alert?: boolean }> = ({ id, active, onClick, alert }) => (
    <StandardButton
        id='mic-light-icon-button'
        color={active ? 'primary' : 'secondary'}
        iconSrc={active ? MicActiveIconLight : MicDisabledIconLight }
        onClick={onClick}
    >
        {alert && !active ? (
            <AlertWrapper>
                <img src={OrangeAlertIcon} />
            </AlertWrapper>
        ) : null}
    </StandardButton>
)

export { MicLightIconButton }
