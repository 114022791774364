import { useState } from 'react'

export type SavingCallbacks = [done: () => void, error: (e: Error) => void]
export type SavingStatus = 'idle' | 'saving' | 'error'

export const useSavingStatus = (): [SavingStatus, () => SavingCallbacks] => {
    const [status, setStatus] = useState<SavingStatus>('idle')
    const [, setRequestCounter] = useState(0)

    const saving = (): SavingCallbacks => {
        setRequestCounter(c => {
            if (c === 0) {
                setStatus('saving')
            }

            return c + 1
        })

        return [
            () => setRequestCounter(c => {
                if (c <= 1) {
                    setStatus(s => s === 'error' ? s : 'idle')
                }

                return c - 1
            }),
            () => setRequestCounter(c => {
                setStatus('error')

                return c - 1
            })
        ]
    }

    return [status, saving]
}
