import { styled } from '@mui/system'

const StatusPanel = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.neutral.alternate,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center'
}))

export { StatusPanel }
