import { ExceptionType } from '.'

class GOCException extends Error {
    public constructor (public readonly exceptionType: ExceptionType, message?: string) {
        super(message ?? `${ExceptionType [exceptionType]}`)
    }
}

const isGocException = (e: any): e is GOCException => {
    return e instanceof GOCException
}

export { GOCException, isGocException }
