import { RefObject, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ConferenceContext } from 'contexts/conference'
import { updateTimeout, useLayoutUpdate } from 'components/useLayoutUpdate'

const useCanvasResize = (
    wrapperRef: RefObject<HTMLDivElement>,
    canvasRef: RefObject<HTMLCanvasElement>,
    sidebarOpen: boolean
): void => {
    const location = useLocation()
    const conference = useContext(ConferenceContext)

    const resizeCanvas = (): void => {
        const wrapper = wrapperRef.current
        const canvas = canvasRef.current

        if (wrapper && canvas) {
            const size = {
                height: wrapper.clientHeight,
                width: wrapper.clientWidth
            }
            canvas.setAttribute('height', size.height.toString())
            canvas.setAttribute('width', size.width.toString())
            conference.setCanvas(canvas, size)
        }
    }

    const hideCanvas = (): void => {
        const wrapper = wrapperRef.current
        const canvas = canvasRef.current
        if (wrapper && canvas) {
            wrapper.style.visibility = 'hidden'
            canvas.style.opacity = '0'
        }
    }

    const {
        triggerLayoutUpdate,
        addLayoutUpdateStartListener,
        addLayoutUpdateEndListener,
        removeLayoutUpdateStartListener,
        removeLayoutUpdateEndListener
    } = useLayoutUpdate()
    useEffect(() => {
        if (wrapperRef.current) {
            const resObs = new ResizeObserver(triggerLayoutUpdate)
            resObs.observe(wrapperRef.current)

            let showCanvasTimeout: NodeJS.Timeout
            const showCanvas = (): void => {
                const wrapper = wrapperRef.current
                const canvas = canvasRef.current
                if (wrapper && canvas) {
                    resizeCanvas()
                    showCanvasTimeout = setTimeout(() => {
                        wrapper.style.visibility = 'visible'
                        canvas.style.opacity = '1'
                    }, updateTimeout)
                }
            }
            showCanvas()

            addLayoutUpdateStartListener(() => {
                clearTimeout(showCanvasTimeout)
                hideCanvas()
            })

            addLayoutUpdateEndListener(showCanvas)
            return () => {
                removeLayoutUpdateStartListener(hideCanvas)
                removeLayoutUpdateEndListener(showCanvas)
            }
        }
    }, [])
    useEffect(() => resizeCanvas(), [canvasRef.current])
    useEffect(() => hideCanvas(), [sidebarOpen, location?.pathname])
}

export { useCanvasResize }
