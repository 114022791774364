import { CommonButtonProps, StandardButton } from './StandardButton'
import { ClassIcon, ClassActiveIcon } from 'icons'

const ClassButton: React.FC<CommonButtonProps> = ({ id, active, disabled, onClick }) => (
    <StandardButton
        id={id}
        color={active ? 'primary' : 'secondary'}
        iconSrc={active ? ClassActiveIcon : ClassIcon}
        disabled={disabled}
        onClick={onClick}
        text={!active ? 'Return to Class' : 'Class'}
    />
)

export { ClassButton }
