import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { WallstreetEnglishLogo } from 'components/logo/WallstreetEnglishLogo'
import { BackgroundType, ModalWrapper } from 'components/wrappers'

const AppError: React.FC = () => {
    const e = useRouteError()

    if (isRouteErrorResponse(e)) {
        return (
            <>{e.status} {e.statusText}</>
        )

    } else if (process.env.NODE_ENV === 'production') {
        return (
            <ModalWrapper background={BackgroundType.white}>
                <WallstreetEnglishLogo />
            </ModalWrapper>
        )

    } else {
        console.error(e)

        return (
            <ModalWrapper background={BackgroundType.white}>
                <WallstreetEnglishLogo />
            </ModalWrapper>
        )
    }
}

export { AppError }
