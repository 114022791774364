import { createContext } from 'react'
import { SlideDeckInfo } from 'api/cms/fetch-ppts'
import { IPlayer, ActiveSlide } from 'ispring'

type ISpringStatus = ISpringContextState['status']
type SetPresentationUrl = (url: string) => void
type Close = (activeSlide: ActiveSlide) => void

type ISpringContextState = {
    status: 'no-slidedecks-assigned' | 'loading'

} | {
    status: 'waiting-for-session-joined'

} | {
    status: 'ready'
    decks: SlideDeckInfo[]
    storedActiveSlide: ActiveSlide

    setPresentationUrl: SetPresentationUrl
    storedPresentationUrl: string | undefined

} | {
    status: 'presentation-selected'

    storedActiveSlide: ActiveSlide
    setPresentationUrl: SetPresentationUrl
    presentationUrl: string
    close: Close

} | {
    status: 'player-ready'
    decks: SlideDeckInfo[]

    presentationUrl: string
    storedActiveSlide: ActiveSlide
    setPresentationUrl: SetPresentationUrl
    close: Close

    currentDeck: SlideDeckInfo
    player: IPlayer
}

const ISpringContext = createContext<ISpringContextState>({
    status: 'waiting-for-session-joined'
})

export type { ISpringContextState, ISpringStatus, SetPresentationUrl, Close }
export { ISpringContext }
