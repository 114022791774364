import { ClassroomId, User, ChatMessage, ChatList, NotificationCount, WseUserId } from 'api/types'
import { PencilAnnotation, EraserFreeAnnotation, TextBoxAnnotation } from 'primus/types/events'

type CueCard = {
    contentId: string
    imageURL: string
} | null

type CueCardData = {
    contentId: string
    type: string
    subType: string
    attributes: string | null
    altText: string | null
    path: string
}

type StudentCueCard = {
    id: string
    cueCard: CueCard
}

type CueCardInfo = {
    activityNumber: string
    activityMedia: CueCardData[]
}

type UserState = User & {
    photoUri: string
    micHardMuted: boolean
    camHardMuted: boolean
    online: boolean
    joined: boolean
    loggedIn: boolean
    isAway: boolean
    deviceType: string
    accessToken: null
    loginDetails: any[]
    previousBreakoutRoom: number | null
    isClassResultsViewed: boolean | null
    cueCard: CueCard | null
    expelled: boolean
}

type Unit = {
    'id': string
    'code': string
}

type Participants = {
    'max': number
    'booked': number
}

type Booking = {
    'student': User & {
        'role': 'student'
    }
    '_id': string
    'id': string
    'result': boolean
    /**
     * Date string
     */
    'booked': string
    'attended': boolean
}

/**
 * DCAppData
*/

type WBAnnotation = PencilAnnotation | EraserFreeAnnotation | TextBoxAnnotation

type WBSlideAnnotations = {
    index: number
    annotations: WBAnnotation[]
} | null

type WBBoardMount = {
    label: string
    slides: WBSlideAnnotations[]
    activeSlide: number
    activePanel: string
    stepIndex: number
    url: string
    layout: string
}

type Board = {
    index: 0
    type: string
    mount: WBBoardMount | null
    label: string
    annotations: WBAnnotation[]
}

type BreakoutRoomId = 0 | 1 | 2 | 3

type BreakoutRoom = {
    id: BreakoutRoomId
    title: string
    users: WseUserId[]
}

type DcAppBreakouts = {
    isActive: boolean
    timeSlot: number
    startTime: number | null
    teacherRoom: BreakoutRoomId | null
    remainingTime: number | null
    rooms: BreakoutRoom[]
}

type Privilege = {
    id: string
    role: string
}
type ExternalFile = any
type Pointer = {
    boardIndex: number
    isActive: boolean
    slideIndex: number
    annotation: {
        ow: number
        x: number
        y: number
    }
}

type WBData = {
    activeBoard: number
    boards: Board[]
    breakouts: DcAppBreakouts
    currentPrivilege: Privilege
    externalFiles: ExternalFile[]
    pointer: Pointer
    cueCardInfo: CueCardInfo[]
}

type DcAppChatData = {
    notificationCount: NotificationCount
    privateChat: ChatList
    publicChat: ChatMessage[]
}

type Session = {
    timeLeft: number
}

type DcAppData = {
    activeScreen: 'avChat' | 'whiteboard'
    chatData: DcAppChatData
    students: UserState[]
    teacher: UserState
    wbData: WBData
    session: Session
    lastMsgId: number
}

export const ONLINE_ENCOUNTER = '11'
export const SOCIAL_CLUB = '32'
export const COMPLEMENTARY_CLASS = '27'
export type ActivityType = typeof ONLINE_ENCOUNTER | typeof SOCIAL_CLUB | typeof COMPLEMENTARY_CLASS | '16'

type ClassroomState = {
    'teacher': User & { role: 'teacher' }
    'Levels': string[]
    'standby': any[]
    '_id': string
    'id': ClassroomId
    'code': string

    /**
     * This is really classroom type id
     */
    'activityType': ActivityType
    'classTypeName': 'Online Encounter'
    /**
     * Unix timestamp
     */
    'date': string
    'duration': number
    'unit'?: Unit
    'StudyPlace': string
    'centerType': string
    'participants': Participants
    'complete': boolean
    'closed': boolean
    'Scd': string
    'online': boolean
    'bookings': Booking[]
    'dcAppData': DcAppData
}

export type {
    ClassroomState, UserState, WBData, WBSlideAnnotations, DcAppChatData,
    NotificationCount, Unit, BreakoutRoom, DcAppBreakouts, WBAnnotation, WBBoardMount, Board, StudentCueCard, CueCardInfo, CueCard
}
