import { createContext } from 'react'
import { BookingContextState } from './types'

const initialState: BookingContextState = {
    status: 'checking',
    clearSession: () => void 0,
    generateQuickslots: () => void 0,
    restoreBooking: () => void 0
}

const BookingContext = createContext<BookingContextState>(initialState)
export { initialState, BookingContext }
