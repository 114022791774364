import { useContext, useEffect, useState } from 'react'
import { styled, Button } from '@mui/material'
import { ConferenceContext } from 'contexts/conference'
import { ChatClient, ChatNotifications, ConversationType, getChatVisibility } from 'chat'
import { ChevronDownIcon } from 'icons'
import { NotificationsIndicator } from 'components/buttons'
import { getDisplayName } from 'utils/get-display-name'
import { BookingContext } from 'contexts/booking'

const Wrapper = styled('div')(({ theme }) => ({
    overflow: 'auto',
    height: '100%'
}))
const ChatSelector = styled(Button)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: '100%',
    height: theme.spacing(8),
    borderRadius: theme.spacing(1),
    fontSize: theme.spacing(2.25),
    backgroundColor: theme.palette.neutral.main,
    color: theme.palette.neutral.dark
}))
const IndicatorWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(1)
}))
const SelectChatButton = styled('img')(({ theme }) => ({
    width: theme.spacing(3.5),
    marginLeft: theme.spacing(1),
    transform: 'rotate(-90deg)'
}))

type ConversationsListProps = {
    chatClient: ChatClient
    openConversation: (conversation: ConversationType) => void
}
const ConversationsList: React.FC<ConversationsListProps> = ({ chatClient, openConversation }) => {
    const conference = useContext(ConferenceContext)
    const booking = useContext(BookingContext)

    const [visibleConversations, setVisibleConversations] = useState<ConversationType[]>([])
    const [notifications, setNotifications] = useState<ChatNotifications>(chatClient.notifications)

    useEffect(() => {
        if (chatClient) {
            const visibleConversations = chatClient.conversations.filter(
                conversation => getChatVisibility(conversation.chatId, conference.participants)
            )

            setVisibleConversations(visibleConversations)
        }
    }, [conference.participants])

    useEffect(() => {
        setNotifications(chatClient.notifications)

        const handler = (): void => {
            setNotifications({ ...chatClient.notifications })
        }

        chatClient.addNotificationHandler(handler)

        return () => chatClient.removeNotificationHandler(handler)
    }, [])

    return (
        <Wrapper id='conversations-list-wrapper'>
            {notifications && visibleConversations.map((conversation, index) =>
                <ChatSelector key={conversation.chatId} onClick={() => openConversation(conversation)}>
                    <p>{getDisplayName(
                        conversation,
                        conversation.role === 'teacher' ? 'teacher-name' : 'student-name',
                        booking.status === 'validated' && booking.user.role === 'teacher'
                    )}</p>
                    <IndicatorWrapper>
                        {notifications[conversation.chatId] > 0 &&
                            <NotificationsIndicator className='conversation-notifications-indicator'>
                                {notifications[conversation.chatId]}
                            </NotificationsIndicator>
                        }
                        <SelectChatButton src={ChevronDownIcon} />
                    </IndicatorWrapper>
                </ChatSelector>
            )}
        </Wrapper>
    )
}

export { ConversationsList }
