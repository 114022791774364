import { WhiteboardIcon, WhiteboardActiveIcon } from 'icons'
import { NavButton } from './NavButton'

const WhiteboardButton: React.FC<{ id: string; disabled?: boolean }> = ({ id, disabled }) => (
    <NavButton
        id={id}
        text='Whiteboard'
        path='/teacher/classroom/whiteboard'
        icon={WhiteboardIcon}
        activeIcon={WhiteboardActiveIcon}
        disabled={disabled}
    />
)

export { WhiteboardButton }
