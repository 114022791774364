import { styled } from '@mui/material'
import { CloseButton } from './breakout-rooms/Buttons'
import { Wrapper, InnerWrapper, CloseButtonWrapper } from 'components/CustomModal'

const CueCardImageWrapper = styled('img')(({ theme }) => ({
    width: '520px',
    height: '286px',
    border: 'solid 1px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px'
}))

type ViewCueCardsProps = {
    close: () => void
    imageURL: string
}

const ViewCueCardsModal: React.FC<ViewCueCardsProps> = ({ close, imageURL }) => {
    return (
        <Wrapper>
            <InnerWrapper id='view-cue-card-modal-inner-wrapper'>
                <CloseButtonWrapper><CloseButton id='view-cue-card-modal-close-button' onClick={close} /></CloseButtonWrapper>
                <h4>Cue Cards</h4>
                <CueCardImageWrapper src={imageURL} alt='cue card image' />
            </InnerWrapper>
        </Wrapper>
    )
}

export { ViewCueCardsModal }
