import { styled } from '@mui/system'
import { LogoIcon } from 'icons'

type LogoContainerProps = {
    children?: React.ReactNode
}

const LogoImage = styled('img')(() => ({
    position: 'absolute',
    top: 0,
    left: '28px'
}))

const Wrapper = styled('div')(() => ({
    padding: '0 28px',
    position: 'relative',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}))

const LogoContainer: React.FC<LogoContainerProps> = ({ children }) => {
    return (
        <Wrapper>
            <LogoImage src={LogoIcon} />
            {children}
        </Wrapper>
    )
}

export { LogoContainer }
