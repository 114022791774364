import { useState } from 'react'
import { styled } from '@mui/material'
import { ActivityRating } from 'contexts/assessment'
import { InfoIcon } from 'icons'

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: `0 ${theme.spacing(1)}`
}))
const ActivityTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    width: theme.spacing(12),
    h3: {
        fontSize: theme.spacing(4),
        fontWeight: '800',
        color: theme.palette.primary.darker,
        marginRight: theme.spacing(1)
    }
}))
const TooltipWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: theme.spacing(2),
    height: theme.spacing(2)
}))
const Tooltip = styled('div')<{ visible: boolean }>(({ theme, visible }) => ({
    position: 'absolute',
    top: theme.spacing(3.5),
    left: theme.spacing(-2),
    width: theme.spacing(30),
    backgroundColor: theme.palette.neutral.dark,
    color: theme.palette.neutral.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    opacity: visible ? 1 : 0,
    pointerEvents: visible ? 'auto' : 'none',
    willChange: 'opacity',
    transition: 'opacity 0.2s ease-out',
    span: {
        fontSize: theme.spacing(1.8),
        fontWeight: '700',
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        textAlign: 'center',
        aspectRatio: '1',
        borderRadius: '50%',
        color: theme.palette.neutral.dark
    },
    p: {
        fontSize: theme.spacing(1.6),
        lineHeight: 1.2,
        marginLeft: theme.spacing(1)
    },
    div: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        '&:nth-of-type(1) span': {
            backgroundColor: '#F9B3BD'
        },
        '&:nth-of-type(2) span': {
            backgroundColor: '#FFEADD'
        },
        '&:nth-of-type(3) span': {
            backgroundColor: '#A7D692'
        },
        '&:nth-of-type(4)': {
            marginBottom: 0,
            span: {
                backgroundColor: '#6CBE3E'
            }
        }
    },
    '&:before': {
        content: '""',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 10px 10px 10px',
        borderColor: `transparent transparent ${theme.palette.neutral.dark} transparent`,
        position: 'absolute',
        top: theme.spacing(-1),
        left: theme.spacing(1.75)
    }
}))
const ActivityTasks = styled('div')<{ itemCount: number }>(({ theme, itemCount }) => ({
    width: `calc(100% - ${theme.spacing(12)})`,
    display: itemCount >= 4 ? 'flex' : 'grid',
    flexWrap: 'wrap',
    gridAutoFlow: 'column',
    gridAutoColumns: itemCount >= 4 ? '50%' : `calc(100% / ${itemCount} - ${Math.round(+theme.spacing(1).split('px')[0] / itemCount)}px)`,
    fontSize: theme.spacing(1.8),
    lineHeight: 1.2,
    gap: theme.spacing(1),
    p: {
        width: itemCount >= 4 ? '40%' : 'auto',
        wordWrap: 'break-word'
    },
    span: {
        fontWeight: '700',
        marginRight: theme.spacing(0.5)
    }
}))

const ActivityDetails: React.FC<{ currentActivity: ActivityRating }> = ({ currentActivity }) => {
    const [tooltipVisible, setTooltipVisibility] = useState<boolean>(false)

    return (
        <Wrapper>
            <ActivityTitle>
                <h3>{currentActivity.number}</h3>
                <TooltipWrapper onMouseEnter={() => setTooltipVisibility(true)} onMouseLeave={() => setTooltipVisibility(false)}>
                    <img src={InfoIcon} />
                    <Tooltip visible={tooltipVisible}>
                        <div><span>1</span><p>Demonstrates no target language at all.</p></div>
                        <div><span>2</span><p>Only demonstrates target language with heavy prompting and modeling.</p></div>
                        <div><span>3</span><p>Demonstrates several examples of target language with some errors.</p></div>
                        <div><span>4</span><p>Demonstrates target language comfortably with few and only minor errors.</p></div>
                    </Tooltip>
                </TooltipWrapper>
            </ActivityTitle>
            <ActivityTasks itemCount={currentActivity.ratings.length}>
                {currentActivity.ratings.map(t => (
                    <p key={t.rating.id}>
                        <span>{currentActivity.number}</span>
                        {t.description}
                    </p>
                ))}
            </ActivityTasks>
        </Wrapper>
    )
}

export { ActivityDetails }
