import { styled } from '@mui/material'
import { ConferenceContext } from 'contexts/conference'
import { useFeatureCheck } from 'hooks/useFeatureCheck'
import { FC, useContext, useEffect, useRef } from 'react'

const Canvas = styled('canvas')({
    position: 'absolute',
    height: '100%',
    width: '100%'
})

const Video = styled('video')({
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover'
})

const HEIGHT = 135
const WIDTH = 240

export const Camera: FC<Record<string, never>> = ({ }) => {
    const conference = useContext(ConferenceContext)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const { isFullFeaturedMode } = useFeatureCheck()
    const isRenderSelfViewWithVideoElement = conference.status === 'session-joined' && conference.isRenderSelfViewWithVideoElement

    useEffect(() => {
        if (canvasRef.current) {
            conference.setCanvas(canvasRef.current, { height: HEIGHT, width: WIDTH }, true)
            canvasRef.current.setAttribute('height', HEIGHT + '')
            canvasRef.current.setAttribute('width', WIDTH + '')
        }
    }, [canvasRef.current])

    useEffect(() => {
        if (
            conference.status === 'session-joined' &&
            !conference.isVideoMuted && document.querySelector('#self-video-element') !== null &&
            isRenderSelfViewWithVideoElement
        ) {
            void conference.rtcSession.setVideoMuted(conference.isVideoMuted)
        }
    }, [conference.status])

    if (!isFullFeaturedMode) {
        return (
            !isRenderSelfViewWithVideoElement ? <Canvas ref={canvasRef} /> : <Video id='self-video-element' height={HEIGHT} width={WIDTH} />
        )

    } else {
        return null
    }
}
