import { useContext, useEffect, useState } from 'react'
import { ConferenceContext } from 'contexts/conference'
import { FormControl, ListSubheader, MenuItem, styled } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { StepButton } from './breakout-rooms/Buttons'
import { useLocation, useNavigate } from 'react-router-dom'
import { Wrapper, InnerWrapper, CloseButtonWrapper } from 'components/CustomModal'
import crossIcon from './cross-Icon.svg'
import dropDownIcon from './dropDownIcon.svg'
import CloseBtn from './closeIcon.svg'
import RefreshIcon from './refreshIcon.svg'
import { RectangleButton } from 'components/buttons'
import { CueCardInfo } from 'api/classroom/ClassroomState'

const InnerContainer = styled(InnerWrapper)(() => ({
    maxWidth: '600px !important',
    padding: '40px 40px 40px 41px',
    maxHeight: '800px !important'
}))

export const numberToAlphabet = (number: number): string => {
    if (number >= 1 && number <= 26) {
        return String.fromCharCode(64 + number)
    } else {
        return 'Invalid input'
    }
}

export const sortingArray = (studentData: ParticipantData[]): ParticipantData[] => {
    const sortedStudentData = studentData.sort((a: { firstName: string }, b: { firstName: string }) =>
        a.firstName.localeCompare(b.firstName, 'en', { sensitivity: 'base' }))
    const uniqueStudentData = sortedStudentData.filter(
        (student, index, sortedStudentArray) => index === sortedStudentArray.findIndex(sortedStudent => sortedStudent.id === student.id)
    )
    return uniqueStudentData
}

const StudentItem = styled('p')(() => ({
    textAlign: 'left',
    width: '424px',
    padding: '8px 0px 6px 0px',
    fontFamily: 'Noto Sans',
    fontSize: '16px',
    fontWeight: '700',
    fontStyle: 'normal',
    position: 'relative',
    color: 'rgba(0, 0, 0, 0.95))'
}))

const CustomMenuItem = styled(MenuItem)(() => ({
    width: '372px',
    margin: '0px',
    padding: '8px 8px 8px 24px',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#000',
    fontFamily: 'Noto Sans',
    lineHeight: 'unset !important',
    '&:hover': {
        width: '100%'
    }
}))
const CustomizedSelectItem = styled('div')(() => ({
    span: {
        position: 'absolute',
        right: '130px',
        top: '51px',
        fontSize: '36px',
        color: 'rgb(197, 190, 190)'
    },
    img: {
        position: 'absolute',
        right: '144px',
        top: '49px',
        cursor: 'pointer'
    }
}))
const SlideItem = styled('img')(() => ({
    width: '86px',
    height: '48px',
    display: 'flex',
    position: 'absolute',
    right: 0,
    margin: 0,
    top: '36px'
}))
const StepButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    margin: ' 40px 0px 0px 0px',
    gap: '21px',
    button: {
        marginLeft: theme.spacing(1),
        fontWeight: 600
    }
}))
const SelectContainer = styled('div')(() => ({
    maxHeight: '460px',
    overflow: 'auto',
    width: '530px'
}))
const DropdownIcon = styled('img')(() => ({
    right: '10px',
    position: 'absolute',
    pointerEvents: 'none' // added to cover overall div click
}))
const CueCardCloseButton = styled('img')(() => ({
    cursor: 'pointer'
}))
const RectangleButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    position: 'relative'
}))

type ParticipantData =
{
    id: string
    firstName: string
    lastName: string
    isSelected?: boolean
}

type SelectedDataModal = [CueCardInfo, number]

type CueCardItem = {
    id: string
    cueCard: {
        contentId: string
        imageURL: string
    } | null
    activityNumber?: string
    indexValue?: string
    mediaIndex?: number
}

type ActivityData = {
    activityNumber: string
    index: number
}

type CueCardItemArray = CueCardItem[]

const ShareCueCardsModal: React.FC<{ close: () => void }> = ({ close }) => {
    const navigate = useNavigate()
    const conference = useContext(ConferenceContext)

    const cueCardResponse = conference.status === 'session-joined' && conference.cueCardInfo

    const cueCardForStudent = conference.status === 'session-joined' && conference.cueCard

    // function to get the student list without teacher
    const studentList = (): ParticipantData[] => {

        const studentData = conference.participants
            .filter(p => p.role !== 'teacher')
            .map(data => ({
                id: data.wseUserId,
                firstName: data.firstName,
                lastName: data.lastName
            }))

        const sortedStudentData = sortingArray(studentData)
        return sortedStudentData
    }

    const [studentNameList, setStudentNameList] = useState<ParticipantData[]>(studentList())

    const [selectedCueCards, setSelectedCueCards] = useState<CueCardItemArray>([])
    const [isCueCardSelected, setIsCueCardSelected] = useState<boolean>(true)
    const [isCueCardChanged, setIsCueCardChanged] = useState<boolean>(false)

    const [isCueCardEdit, setIsCueCardEdit] = useState<boolean>(false)
    const location = useLocation()
    const isCueCardPage = location.pathname === '/teacher/classroom/cue-cards'

    // Function to handle the data based on selection and unselection
    const menuItemHandlerNew = (selectedStatus: boolean, participant: ParticipantData, selectedValues?: SelectedDataModal | null): void => {

        if (cueCardForStudent) {
            const selectedCueCardsArray = selectedCueCards.length > 0 ? selectedCueCards : cueCardForStudent

            const updatedStudents = selectedCueCardsArray.map(student => {
                if (student.id === participant.id) {
                    if (selectedStatus && selectedValues) {
                        const mediaIndex = selectedValues[1]
                        return {
                            ...student,
                            cueCard: {
                                contentId: selectedValues[0].activityMedia[mediaIndex].contentId,
                                imageURL: selectedValues[0].activityMedia[mediaIndex].path
                            },
                            activityNumber: selectedValues[0].activityNumber,
                            indexValue: numberToAlphabet(mediaIndex + 1)
                        }
                    } else {
                        return {
                            id: participant.id,
                            cueCard: null
                        }
                    }
                }

                return student
            })

            const updateStudentSelection = studentNameList.map(user =>
                user.id === participant.id ? { ...user, isSelected: !!selectedStatus } : user
            )

            setStudentNameList(updateStudentSelection)
            setSelectedCueCards(updatedStudents)
        }

    }

    // Function called while selecting the dropdown option
    const handleChange = (event: SelectChangeEvent<''>, participant: ParticipantData): void => {
        const selectedValues = event.target.value as unknown as SelectedDataModal
        menuItemHandlerNew(true, participant, selectedValues)
        setIsCueCardChanged(true)
    }

    // Function called while clicking cross icon for the selected value
    const clearCueCard = (participant: ParticipantData): void => {
        menuItemHandlerNew(false, participant, null)
        setIsCueCardChanged(true)
    }

    // Function called while clicking clear all selection button at bottom
    const clearAllCueCard = (): void => {
        setStudentNameList(studentList())
        const newSelectedCueCardArray = selectedCueCards.map(data => {
            return {
                id: data.id,
                cueCard: null
            }
        })
        setSelectedCueCards(newSelectedCueCardArray)
    }

    // Function called while share cue cards button at bottom
    const onShareCueCardsClick = (): void => {
        navigate('/teacher/classroom/cue-cards')
        close()
        if (conference.status === 'session-joined') {

            if (selectedCueCards.length > 0) {
                // If the student left the session and teacher shares the cue card for others, then if the left out student enters again
                // he should not see the cue card
                const updatedCueCards = selectedCueCards.map(cueCard => {
                    const matchingStudent = studentNameList.find(student => student.id === cueCard.id)
                    if (matchingStudent) {
                        return {
                            ...cueCard,
                            cueCard: cueCard.id === matchingStudent.id ? cueCard.cueCard : null
                        }
                    } else {
                        return {
                            id: cueCard.id,
                            cueCard: null
                        }
                    }
                })
                conference.setCueCardForStudent(updatedCueCards)
                conference.primus.send('cueCard:add', {
                    cueCards: updatedCueCards
                })
            }
        }
    }

    // Function used to show the cue card image thumbail for the selected students
    const showCueCardImage = (participantId: string): string => {
        const cueCardImage = selectedCueCards ? selectedCueCards.find(data => data.id === participantId) : null
        return cueCardImage?.cueCard?.imageURL ?? ''
    }

    // useEffect to update the student list when new student added or removed
    useEffect(() => {
        const updatedStudentList = studentList()

        if (updatedStudentList.length > studentNameList.length) {
            updatedStudentList.forEach((updatedstudent: ParticipantData) => {
                const filteredStudentNameList = studentNameList.find(student => student.id === updatedstudent.id)
                if (!filteredStudentNameList) {
                    studentNameList.push({
                        id: updatedstudent.id,
                        firstName: updatedstudent.firstName,
                        lastName: updatedstudent.lastName
                    })
                }
            })

        } else {
            studentNameList.forEach((student, index) => {
                const filteredUpdatedStudentList = updatedStudentList.find((updatedstudent: { id: string }) => updatedstudent.id === student.id)
                if (!filteredUpdatedStudentList) {
                    studentNameList.splice(index, 1)
                }
            })

        }

        const sortedStudentNameList = sortingArray(studentNameList)
        setStudentNameList(sortedStudentNameList)
    }, [conference.participants])

    // useEffect for make the share cue card button enable if any one selection done
    useEffect(() => {
        const isCueCardSaved = cueCardForStudent ? cueCardForStudent.find(data => data.cueCard) : false

        const selectedStudentNameList = studentNameList.find(data => data.isSelected)

        if (isCueCardSaved) {
            setIsCueCardSelected(true)
            if (isCueCardChanged || !selectedStudentNameList || !isCueCardPage) {
                setIsCueCardEdit(true)
                setIsCueCardSelected(false)
                if (!isCueCardPage && !selectedStudentNameList) {
                    setIsCueCardSelected(true)
                }
            }
        } else {
            setIsCueCardSelected(!selectedStudentNameList)
        }
    }, [studentNameList])

    useEffect(() => {

        const isCueCardAvailable = cueCardForStudent ? cueCardForStudent.find(data => data.cueCard) : false

        if (isCueCardAvailable && cueCardForStudent) {

            // once after refresh set the activity number and index from the cue card saved data
            const updatedCueCardForStudent = cueCardForStudent.map(student => {
                let matchingActivity: ActivityData | undefined

                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                cueCardResponse ? cueCardResponse.forEach(activity => {
                    if (student.cueCard?.contentId) {
                        const filteredActivity = activity.activityMedia.find((media: { contentId: string }) => media.contentId === student.cueCard?.contentId)
                        if (filteredActivity) {
                            matchingActivity = {
                                activityNumber: activity.activityNumber,
                                index: activity.activityMedia.indexOf(filteredActivity)
                            }
                        }
                    }
                }) : null

                if (matchingActivity) {
                    return {
                        ...student,
                        activityNumber: matchingActivity.activityNumber,
                        mediaIndex: matchingActivity.index,
                        indexValue: numberToAlphabet(matchingActivity.index + 1)
                    }
                } else {
                    return student
                }
            })

            setSelectedCueCards(updatedCueCardForStudent)
        }

        if (isCueCardAvailable && cueCardForStudent) {

            const cueCardUsers: ParticipantData[] = []

            cueCardForStudent.forEach(data => {

                const filters = studentNameList.find(user =>
                    user.id === data.id
                )

                if (!filters) return

                cueCardUsers.push(
                    { ...filters, isSelected: !!data.cueCard }
                )
            })

            setStudentNameList(cueCardUsers)
        }
    }, [])

    return (
        <Wrapper>
            <InnerContainer id='cueCard-modal-inner-wrapper'>
                <CloseButtonWrapper>
                    <CueCardCloseButton
                        id='cueCard-modal-close-button'
                        src={CloseBtn}
                        alt='cueCardCloseButton'
                        onClick={close}
                    />
                </CloseButtonWrapper>
                <h4>Share Cue Cards</h4>
                <SelectContainer>
                    {studentNameList.map((participant, participantIndex) => (
                        <FormControl key={participantIndex} sx={{
                            display: 'flex',
                            position: 'relative',
                            width: '519px',
                            margin: '0px 0px 12px 0px'
                        }}>
                            <StudentItem>{participant.firstName} {participant.lastName}</StudentItem>
                            <Select
                                IconComponent={() => (
                                    <DropdownIcon src={dropDownIcon} alt='DropdownIcon' />
                                )}
                                className='Textbox'
                                name='option'
                                sx={() => ({
                                    borderRadius: '0px',
                                    width: '424px',
                                    height: '48px',
                                    padding: '10px 12px',
                                    border: '2px solid rgba(0, 0, 0, 0.14)',
                                    margin: '0px',
                                    '& .MuiSelect-select': {
                                        padding: '0px !important'
                                    },
                                    '& .MuiSelect-icon': {
                                        fill: 'none !important'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderStyle: 'none !important'
                                    }
                                })}
                                value={''} // value att needed to pass the event for onChange
                                id={`grouped-select-${participantIndex}`}
                                data-testid={`grouped-select-${participantIndex}`}
                                onChange={(ev: SelectChangeEvent<''>) => handleChange(ev, participant)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                renderValue={() => {
                                    const selectedStudent = selectedCueCards.find(data => (data.id === participant.id && data.cueCard))

                                    if ((selectedCueCards && selectedCueCards.length === 0) || (selectedCueCards.length > 0 && !selectedStudent)) {
                                        return <p
                                            style={{
                                                fontSize: '16px',
                                                color: 'rgba(0, 0, 0, 0.30)',
                                                position: 'relative'
                                            }}
                                        >
                                            Select a Cue Card
                                        </p>
                                    } else {
                                        return `Activity ${selectedStudent?.activityNumber} Student ${selectedStudent?.indexValue}`
                                    }
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: theme => ({
                                            borderRadius: '0px 0px 8px 8px',
                                            height: '192px',
                                            border: '1px solid rgba(0, 0, 0, 0.14)',
                                            boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.06), 0px 3px 1px 0px rgba(0, 0, 0, 0.02)',
                                            padding: '0px 8px 8px 8px',
                                            '& .MuiMenu-list': {
                                                paddingTop: '16px'
                                            }
                                        })
                                    }

                                }}
                            >
                                {cueCardResponse ? cueCardResponse.map((activities, index) => (
                                    [
                                        <ListSubheader
                                            id='category'
                                            key={`activity-${activities.activityNumber}`}
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                lineHeight: '38px',
                                                padding: '8px'
                                            }}>
                                            Activity {activities.activityNumber}
                                        </ListSubheader>,
                                        ...activities.activityMedia.map((data, mediaIndex) => (
                                            <CustomMenuItem
                                                data-testid={`activity-${activities.activityNumber}-media-${mediaIndex}`}
                                                key={`activity-${activities.activityNumber}-media-${mediaIndex}`}
                                                value={[activities as any, mediaIndex]}>
                                                {activities.activityNumber} {'Student ' + numberToAlphabet(mediaIndex + 1)}
                                            </CustomMenuItem>
                                        ))

                                    ]
                                )) : 'null'}
                            </Select>

                            {studentNameList[participantIndex].isSelected ? (
                                <>
                                    <CustomizedSelectItem>
                                        <span>|</span>
                                        <img alt='Cross Icon Alt Text' src={crossIcon} className='crossIcon' onClick={() => clearCueCard(participant)} />
                                    </CustomizedSelectItem>
                                    <SlideItem
                                        data-testid='cueCardImage'
                                        src={selectedCueCards.length > 0 ? showCueCardImage(participant.id) : ''}
                                        alt='cue card image' sx={{
                                            border: 'solid 1px rgba(0, 0, 0, 0.06)'
                                        }} />
                                </>
                            ) : (
                                <SlideItem
                                    data-testid='cue-card-thumbnail-grey'
                                    sx={{
                                        backgroundColor: '#D9D9D9'
                                    }}
                                />
                            )}
                        </FormControl>
                    ))}
                </SelectContainer>
                <StepButtonsWrapper>
                    <RectangleButtonContainer>
                        <RectangleButton
                            id='cuecard-modal-clear-button'
                            text='Clear all selection'
                            color='inherit'
                            onClick={clearAllCueCard}
                            iconSrc={RefreshIcon}
                            customColor='#F12C3E'
                        />
                    </RectangleButtonContainer>
                    <StepButton
                        id='cuecard-modal-share-button'
                        text={(isCueCardEdit && isCueCardPage) ? 'Update Cue Cards' : 'Share Cue Cards'}
                        disabled={isCueCardSelected}
                        onClick={onShareCueCardsClick}
                    />
                </StepButtonsWrapper>
            </InnerContainer>
        </Wrapper>
    )
}

export { ShareCueCardsModal }
