import { Category, getRootCategories, getUnitRatingsByGuid } from 'api/unit-assessment'
import { ExceptionType, GOCException } from 'exceptions'
import { findUnit } from './find-unit'
import { ActivityRating, TaskRating, groupRatings } from './group-ratings'

export type UnitAssessment = {
    activitiesRatings: ActivityRating[]
    tasksRatings: TaskRating[]
    unit: Category
}

export const getUnitAssessment = async (auth: string, unitId: string): Promise<UnitAssessment> => {
    const unit = findUnit(unitId, await getRootCategories(auth, 3))
    if (unit) {
        return {
            ...groupRatings(await getUnitRatingsByGuid(auth, unit.id)),
            unit
        }

    } else {
        throw new GOCException(ExceptionType.UnitAssessmentNotFound, 'Unit assessment not found')
    }
}
