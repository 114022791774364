import { ActivityType, Unit } from 'api/classroom/ClassroomState'

export type SnbStage = 'L1' | 'L2' | 'N2' | 'N3' | 'N4' | 'N5' | 'N6'
type StageId = 0 | 1 | 2 | 3 | 4 | 5 | 6

const oscarStageValues: Record<StageId, string> = {
    0: 'l1',
    1: 'l2',
    2: 'waystage',
    3: 'upper_waystage',
    4: 'threshold',
    5: 'milestone',
    6: 'mastery'
}

const snbStageValues: Record<SnbStage, { id: StageId }> = {
    L1: { id: 0 },
    L2: { id: 1 },
    N2: { id: 2 },
    N3: { id: 3 },
    N4: { id: 4 },
    N5: { id: 5 },
    N6: { id: 6 }
}

const classTypes: Record<ActivityType, string> = {
    11: 'online_encounter',
    32: 'online_social_club',
    27: 'online_complementary_class',
    16: 'channel_wse'
}

export type Query = [
    classType: string,
    unitId: string,
    stage: string
]

type GetQuery = (levels: SnbStage[], activityType: ActivityType, unit?: Unit) => Query

export const getStage = (levels: SnbStage[]): string => {
    const stageIds = levels.map(level => snbStageValues[level].id)
    if (stageIds.find(id => id === void 0)) {
        throw new Error('Unknown class level')
    }

    return oscarStageValues[Math.min(...stageIds) as StageId]
}

export const getQuery: GetQuery = (levels, activityType, unit) => {
    const stage = levels.length > 0 ? getStage(levels) : 'null'
    const classType = classTypes[activityType]
    const unitId = unit ? unit.id : 'null'

    return [classType, unitId, stage]
}
