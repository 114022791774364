import { styled, keyframes } from '@mui/material'

const NormalAnimation = keyframes`
  25%{ transform: scaleY(1) }
  50%{ transform: scaleY(0.4) }
  75%{ transform: scaleY(0.6) }
`
const QuietAnimation = keyframes`
  25%{ transform: scaleY(0.5) }
  50%{ transform: scaleY(0.5) }
  75%{ transform: scaleY(0.7) }
`
const SpeakerIndicator = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    margin: `0 ${theme.spacing(1)}`,
    span: {
        transform: 'scaleY(0.4)',
        height: '80%',
        width: '3px',
        backgroundColor: theme.palette.neutral.main,
        borderRadius: theme.spacing(1),
        margin: '0 2px',
        animationDuration: '1.2s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
        animationName: NormalAnimation,
        '&:first-of-type, &:last-of-type': {
            animationName: QuietAnimation,
            margin: 0
        }
    }
}))

const SpeakingIndicator: React.FC = () => {

    return (
        <SpeakerIndicator><span /><span /><span /></SpeakerIndicator>
    )
}

export { SpeakingIndicator }
