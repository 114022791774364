import { BookingContext } from 'contexts/booking'
import { useContext, useEffect, useState } from 'react'

export const useMediaDevices = (): any | undefined => {
    const [devices, setDevices] = useState<MediaDeviceInfo[]>()
    const [stream, setStream] = useState<MediaStream>()
    const booking = useContext(BookingContext)

    useEffect(() => {
        const setMedia = async (): Promise<void> => {
            const configurations = [
                { audio: true, video: true },
                { audio: false, video: true },
                { audio: true, video: false }
            ]

            for (const config of configurations) {
                try {
                    const mediaStream = await navigator.mediaDevices.getUserMedia(config)
                    setStream(mediaStream)
                    return
                } catch (error) {
                    console.log('Error getting media devices:', error)
                }
            }
        }
        if (booking.status === 'validated') {
            void setMedia()
        }
    }, [booking.status])

    useEffect(() => {
        if (stream) {
            void navigator.mediaDevices.enumerateDevices().then(devices => {
                setDevices(devices)
            })

            return () => {
                for (const track of [...stream.getAudioTracks(), ...stream.getVideoTracks()]) {
                    track.stop()
                }
            }
        }
    }, [stream])

    return { devices, stream }
}
