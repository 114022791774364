import { useRef, RefObject } from 'react'
import { styled } from '@mui/material'
import { WaitingForOthers } from 'components/status-panels'
import { ZoomLayout } from 'components/participants'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import { useWhiteBoardAnnotations } from 'annotation-tool/useWhiteBoardAnnotations'
import { ASPECT_RATIO } from 'zoom/config'

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}))
const ParticipantsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '30%',
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    '> div': {
        width: '100%'
    }
}))
const WhiteboardContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: '70%',
    position: 'relative'
}))
const IframeWrapper = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    margin: 'auto',
    position: 'relative',
    borderRadius: theme.spacing(1.5),
    '&:after': {
        content: '""',
        outline: `solid ${theme.spacing(10)} ${theme.palette.neutral.main} `,
        borderRadius: theme.spacing(1.5),
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid rgba(0, 0, 0, 0.14)',
        height: `calc((100vw - 2 * var(--page-side-margin) - 2px) / ${ASPECT_RATIO})`,
        maxHeight: '100%',
        aspectRatio: `${ASPECT_RATIO}`
    }
}))

const AnnotationsCanvas = styled('canvas')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 0,
    bottom: 0,
    zIndex: 2,
    borderRadius: theme.spacing(1.5),
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)'
}))

const Whiteboard: React.FC = () => {
    const containerRef = useRef<HTMLDivElement>(null)
    const textBoxRef = useRef<HTMLDivElement>(null)
    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const annotationsCanvasRef = useRef<HTMLCanvasElement>(null)
    const { annotationsCanvasParams } =
        useWhiteBoardAnnotations(containerRef, annotationsCanvasRef, undefined, textBoxRef, textAreaRef)

    return (
        <WhiteboardContainer id='student-whiteboard-container'>
            <IframeWrapper ref={containerRef}>
                <AnnotationsCanvas
                    ref={annotationsCanvasRef}
                    height={annotationsCanvasParams.height}
                    width={annotationsCanvasParams.width}
                />
            </IframeWrapper>
        </WhiteboardContainer>
    )
}

const WhiteboardView: React.FC<{
    breakouts?: BreakoutRoomsState
    wrapperRef?: RefObject<HTMLDivElement>
}> = ({ breakouts, wrapperRef }) => (
    <Wrapper>
        <ParticipantsWrapper ref={wrapperRef} id='student-whiteboard-participants'>
            <WaitingForOthers breakouts={breakouts} />
            <ZoomLayout breakouts={breakouts} />
        </ParticipantsWrapper>
        <Whiteboard />
    </Wrapper>
)

export { WhiteboardView }
