import { styled, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { RectangleButton } from 'components/buttons'
import { ConferenceParticipant } from 'contexts/conference/types/ConferenceParticipant'
import { DismissIcon } from 'icons'
import { DialogClose, DialogWrapper } from './LeaveDialog'
import { getDisplayName } from 'utils/get-display-name'
import { ExpelDialogContent } from 'utils/constants'

const CustomDialogWrapper = styled(DialogWrapper)(() => ({
    '.MuiPaper-root': {
        maxWidth: '600px !important'
    }
}))

const SelectedStudent = styled('div')(() => ({
    padding: '16px 24px',
    display: 'flex',
    gap: '16px',
    borderRadius: '12px',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    alignItems: 'center',
    img: {
        width: '48px',
        height: '48px',
        borderRadius: '24px'
    },
    span: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        width: '48px',
        height: '48px',
        borderRadius: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '700',
        color: 'rgba(255,255,255)'
    },
    p: {
        fontWeight: '700'
    }
}))

type ExpelDialogProps = {
    isOpen: boolean
    onRemoveStudent?: () => void
    onClose?: () => void
    participant?: ConferenceParticipant
}

const ExpelModal: React.FC<ExpelDialogProps> = ({ isOpen, onClose, onRemoveStudent, participant }) => (
    <CustomDialogWrapper open={isOpen} maxWidth='sm' fullWidth>
        <DialogTitle>
            {ExpelDialogContent.title}
            <DialogClose aria-label='close' onClick={onClose}>
                <img src={DismissIcon} alt='Close Icon' />
            </DialogClose>
        </DialogTitle>
        <DialogContent>
            {ExpelDialogContent.dsescription}
            <SelectedStudent>
                {participant && (
                    <>
                        {participant.wseProfilePhotoUrl ? (
                            <img src={participant.wseProfilePhotoUrl} alt='Profile' />
                        ) : (
                            <span id='participant-initials'> {getDisplayName(participant, 'initials')}</span>
                        )}
                        <p>{`${participant.firstName} ${participant.lastName}`}</p>
                    </>
                )}
            </SelectedStudent>
        </DialogContent>
        <DialogActions data-testid='Remove-student'>
            <RectangleButton id='student-remove-btn' onClick={onRemoveStudent} text={ExpelDialogContent.buttonName} color='error' />
        </DialogActions>
    </CustomDialogWrapper>
)

export { ExpelModal }
