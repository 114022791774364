import { ClassroomId } from 'api/types'
import { apiRequest } from '../api-request'
import { mock } from './mocks/results'
import { ClassType, Guid, ResultTypeId, TimeStamp } from './types'

type PartialResult = {
    ratingSchemaPartItemId: Guid
    number: number | null
    result: number
}

type PartialResults = {
    studentId: Guid
    teacherId: Guid
    registrationId: Guid
    itemResultsViewModel: PartialResult[]
}

type Results = {
    id: Guid
    classType: ClassType
    teacherId: Guid
    studentId: Guid
    result: string | null
    contentItemResultTypeId: ResultTypeId
    score: number | null
    dateStarted: TimeStamp
    dateCompleted: TimeStamp
    created: TimeStamp
    lastUpdated: TimeStamp
}

type ClassResults = {
    partialResults: PartialResults[]
    results: Results[]
}

const getClassResults = async (auth: string, activityId: ClassroomId): Promise<ClassResults> => {
    if (!process.env.REACT_APP_USE_ASSESSMENT_MOCKS) {
        return await apiRequest<ClassResults>({
            baseUrl: process.env.REACT_APP_BS_API_URL,
            path: `/classes/${process.env.REACT_APP_ACTIVITY_ID ?? activityId}/classResults`,
            method: 'GET',
            auth: process.env.REACT_APP_QUALWORLD_AUTH ?? auth
        })

    } else {
        return new Promise(resolve => setTimeout(() => resolve(mock), 300))
    }
}

export type { ClassResults, Results }
export { getClassResults }
