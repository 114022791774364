import { styled, Theme, useTheme } from '@mui/material'
import { CSSProperties } from '@mui/styled-engine'

const Background = styled('div')(({ theme }) => ({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0
}))

enum BackgroundType { shadow, white }

const useBackgroundColor: (bgType: BackgroundType) => CSSProperties['backgroundColor'] = bgType => {
    const theme = useTheme<Theme>()

    switch (bgType) {
        case BackgroundType.shadow:
            return 'rgba(0, 0, 0, 0.85)'

        case BackgroundType.white:
            return theme.palette.neutral.main
    }
}

type ModalProps = {
    background: BackgroundType
    children: React.ReactNode
}

const ModalWrapper: React.FC<ModalProps> = ({ background = BackgroundType.shadow, children }) => {
    const backgroundColor = useBackgroundColor(background)

    return (
        <Background sx={{ backgroundColor }}>
            {children}
        </Background>
    )
}

export { ModalWrapper, BackgroundType }
