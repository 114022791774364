import './Logo.css'
import { WallstreetEnglishLogo } from 'components/logo/WallstreetEnglishLogo'
import { styled } from '@mui/system'

const LogoWrapper = styled('div')({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const Logo: React.FC = () => {
    return (
        <div className='App'>
            <LogoWrapper>
                <WallstreetEnglishLogo />
            </LogoWrapper>
        </div>
    )
}

export { Logo }
