import { useState, useEffect, Dispatch, SetStateAction, useContext } from 'react'
import { ConferenceContext } from 'contexts/conference'
import { ConversationType } from 'chat'
import { ConversationsList, Conversation } from '.'
import { getDisplayName } from 'utils/get-display-name'
import { BookingContext } from 'contexts/booking'

type ChatComponentProps = {
    conversation?: ConversationType
    sidebarSubmenu: string | null
    setSidebarSubmenu: Dispatch<SetStateAction<string | null>>
}

const Chat: React.FC<ChatComponentProps> = ({ conversation, sidebarSubmenu, setSidebarSubmenu }) => {
    const conference = useContext(ConferenceContext)
    const booking = useContext(BookingContext)

    const [selectedConversation, setSelectedConversation] = useState<ConversationType | null>()

    const openConversation = (conversation: ConversationType): void => {
        setSelectedConversation(conversation)
        setSidebarSubmenu(getDisplayName(
            conversation,
            conversation.role === 'teacher' ? 'teacher-name' : 'student-name',
            booking.status === 'validated' && booking.user.role === 'teacher'
        ))
    }

    useEffect(() => {
        if (sidebarSubmenu === null && selectedConversation) {
            setSelectedConversation(null)
        }
    }, [sidebarSubmenu])
    useEffect(() => {
        if (conversation) {
            openConversation(conversation)
        }
    }, [conversation])

    return (
        <>
            {conference.status === 'session-joined' && (
                selectedConversation ? (
                    <Conversation chatClient={conference.chatClient} conversation={selectedConversation} />
                ) : (
                    <ConversationsList chatClient={conference.chatClient} openConversation={openConversation} />
                ))
            }
        </>
    )
}

export { Chat }
