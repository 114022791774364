import { useEffect, useState } from 'react'

type StatusType = {
    connectionAlive: boolean
}

export const useConnectionStatus = (): StatusType => {
    const [connectionAlive, setConnectionAlive] = useState<boolean>(true)

    useEffect(() => {
        const handleConnectionChange = (event: CustomEvent): void => {
            setConnectionAlive(event.detail.status)
        }

        window.addEventListener('connection-status', handleConnectionChange)

        return () => {
            window.removeEventListener('connection-status', handleConnectionChange)
        }
    }, [])

    return { connectionAlive }
}
