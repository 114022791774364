import { ClassDetails } from '../class-details'

export const mock: ClassDetails = {
    bookedStudents: [
        {
            student: {
                userId: 'a9418922-8a41-4efe-81aa-9a5f86eec87e',
                lastName: 'Leenu',
                firstName: 'Leenu',
                photoUris: {
                    small: 'https://assets-stg.wallstreetenglish.com/userphoto/0eac6edc-f079-4e94-bde0-0b7c0d52df46_small?202301040630500801',
                    medium: 'https://assets-stg.wallstreetenglish.com/userphoto/0eac6edc-f079-4e94-bde0-0b7c0d52df46_medium?202301040630500801',
                    big: 'https://assets-stg.wallstreetenglish.com/userphoto/0eac6edc-f079-4e94-bde0-0b7c0d52df46_big?202301040630500801'
                }
            },
            currentUnitNumber: '1'
        },
        {
            student: {
                userId: '17233be8-8762-4963-ab04-f37e9b723db5',
                lastName: 'Santhosh',
                firstName: 'Santhosh',
                photoUris: {}
            },
            currentUnitNumber: '1'
        }
    ],
    classId: 'be02491d-c939-4afb-9a25-62d9fd698b1e',
    teacherId: 'd14dcd07-d24a-4af9-b86c-d7e26d788f0e',
    teacherSsdsId: 'd14dcd07-d24a-4af9-b86c-d7e26d788f0e',
    isClosed: false
}
