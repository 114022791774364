import { Dispatch, RefObject, SetStateAction, useContext, useEffect, useState } from 'react'
import { SlideDeckNavigationController } from 'ispring'
import { ConferenceContext } from 'contexts/conference'
import { Tool, WhiteBoardAnnotationService } from './WhiteBoardAnnotationService'

type AnnotationsCanvasParams = {
    width: number
    height: number
}
type UseAnnotationsType = (
    wrapperRef: RefObject<HTMLDivElement>,
    annotationsCanvasRef: RefObject<HTMLCanvasElement>,
    navigationController?: SlideDeckNavigationController,
    textBoxRef?: RefObject<HTMLDivElement>,
    textAreaRef?: RefObject<HTMLTextAreaElement>,
) => {
    annotationsCanvasParams: AnnotationsCanvasParams
    activeTool: Tool | null
    setActiveTool: Dispatch<SetStateAction<Tool | null>>
    clearAll: () => void
}

const useWhiteBoardAnnotations: UseAnnotationsType = (wrapperRef, annotationsCanvasRef, navigationController, textBoxRef, textAreaRef) => {
    const conference = useContext(ConferenceContext)
    const [activeTool, setActiveTool] = useState<Tool | null>(null)
    const [whiteBoardAnnotationService, setWhiteBoardAnnotationService] = useState<WhiteBoardAnnotationService>()
    const [annotationsCanvasParams, setAnnotationsCanvasParams] = useState<AnnotationsCanvasParams>({ width: 0, height: 0 })
    const clearAll = (): void => whiteBoardAnnotationService?.clearAll()

    useEffect(() => {
        if (wrapperRef.current) {
            const onResize = (): void => {
                if (wrapperRef.current) {
                    const wrapperHeight = wrapperRef.current.clientHeight
                    const wrapperWidth = wrapperRef.current.clientWidth
                    const adjustedWidth = Math.round((wrapperHeight / 9) * 16)
                    const adjustedHeight = Math.round((wrapperWidth / 16) * 9)
                    const width = wrapperWidth >= adjustedWidth ? adjustedWidth : wrapperWidth
                    const height = wrapperHeight >= adjustedHeight ? adjustedHeight : wrapperHeight

                    setAnnotationsCanvasParams({ width, height })
                }
            }

            const resObs = new ResizeObserver(onResize)
            resObs.observe(wrapperRef.current)
        }
    }, [wrapperRef.current])

    useEffect(() => {
        if (annotationsCanvasRef.current && whiteBoardAnnotationService) {
            whiteBoardAnnotationService.renderCurrentSlideAnnotations()
        }
    }, [annotationsCanvasRef.current?.width, annotationsCanvasRef.current?.height, whiteBoardAnnotationService])

    useEffect(() => {
        if (annotationsCanvasRef.current && conference.status === 'session-joined') {
            const canvas = annotationsCanvasRef.current
            const whiteboardInitialState = conference.whiteBoardAnnotations || conference.classroomStateOnJoin.dcAppData.wbData.boards[0]
            const whiteBoardService = new WhiteBoardAnnotationService(canvas, conference.primus, whiteboardInitialState,
                textBoxRef?.current ?? null, textAreaRef?.current ?? null, conference.setWhiteBoardAnnotation)
            setWhiteBoardAnnotationService(whiteBoardService)

        }
    }, [annotationsCanvasRef.current])

    useEffect(() => {
        if (whiteBoardAnnotationService) {
            whiteBoardAnnotationService.registerPrimusEvents()
        }
    }, [whiteBoardAnnotationService])

    useEffect(() => {
        if (whiteBoardAnnotationService) {
            whiteBoardAnnotationService.registerMouseEvents()

            return () => whiteBoardAnnotationService.unregisterMouseEvents()
        }
    }, [whiteBoardAnnotationService])

    useEffect(() => {
        whiteBoardAnnotationService?.setActiveTool(activeTool)
    }, [activeTool, whiteBoardAnnotationService])

    return { annotationsCanvasParams, activeTool, setActiveTool, clearAll }
}

export { useWhiteBoardAnnotations }
