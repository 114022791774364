import { Category } from '../../api/unit-assessment/categories'

export const findUnit = (unitId: string, tree: Category[]): Category | undefined => {
    for (const item of tree) {
        if (item.type === 'Unit') {
            if (item.attributes.number === unitId) {
                return item
            }

        } else {
            const unit = findUnit(unitId, item.childCategories)
            if (unit) {
                return unit
            }
        }
    }
}
