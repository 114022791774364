import { NavButton } from './NavButton'
import { CueCardsIcon, CueCardsActiveIcon } from 'icons'

type CueCardsButtonProps = {
    id: string
    disabled?: boolean
    onClick?: () => void
}

const CueCardsButton: React.FC<CueCardsButtonProps> = ({ id, disabled, onClick }) => (
    <NavButton
        id={id}
        text='Cue Cards'
        path='/teacher/classroom/cue-cards'
        icon={CueCardsIcon}
        activeIcon={CueCardsActiveIcon}
        disabled={disabled}
        onClick={onClick}
    />
)

export { CueCardsButton }
