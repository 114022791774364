import { useContext, useState, useEffect } from 'react'
import { clamp } from 'lodash'
import { ConferenceContext } from 'contexts/conference'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import { CloseButton } from './Buttons'
import { SettingsModalStep } from './SettingsModalStep'
import { RoomsModalStep } from './RoomsModalStep'
import { Wrapper, InnerWrapper, CloseButtonWrapper } from 'components/CustomModal'

export type RoomAssignment = {
    wseProfilePhotoUrl?: string
    firstName: string
    lastName: string
    roomId?: number
    id: string
    indexId: number
}

const BreakoutRoomsModal: React.FC<{ breakouts: BreakoutRoomsState; close: () => void }> = ({ breakouts, close }) => {
    const conference = useContext(ConferenceContext)

    const [roomsCount, setRoomsCount] = useState<number>(2)
    const [roomA, setRoomA] = useState<RoomAssignment[]>([])
    const [roomB, setRoomB] = useState<RoomAssignment[]>([])
    const [roomC, setRoomC] = useState<RoomAssignment[]>([])
    const [roomD, setRoomD] = useState<RoomAssignment[]>([])
    const [borDuration, setBorDuration] = useState<number>(2)
    const [borMode, setBorMode] = useState<'speak' | 'cue-cards' | 'slides'>('speak')
    const [step, setStep] = useState<number>(1)
    const [roomsEmpty, setRoomsEmpty] = useState<boolean>(false)
    const [roomsFull, setRoomsFull] = useState<boolean>(false)

    const handleBorStart = (): void => {
        close()

        breakouts.start({
            mode: borMode,
            duration: borDuration,
            usersRooms: [
                ...roomA.map(s => ({ userId: s.id, roomId: 0, indexId: s.indexId })),
                ...roomB.map(s => ({ userId: s.id, roomId: 1, indexId: s.indexId })),
                ...roomC.map(s => ({ userId: s.id, roomId: 2, indexId: s.indexId })),
                ...roomD.map(s => ({ userId: s.id, roomId: 3, indexId: s.indexId }))
            ]
        })
    }

    const handleRoomCountChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const studentsCount = conference.participants.length - 1
        setRoomsCount(clamp(+e.target.value, 2, studentsCount < 4 ? studentsCount : 4))
    }
    const handleDurationChange = (increase: boolean): void => {
        setBorDuration((prev: number) => clamp(increase ? (prev === 2 ? 5 : prev + 5) : prev - 5, 2, 50))
    }
    const getRoom = (index: number): RoomAssignment[] => {
        if (index === 0) {
            return roomA
        } else if (index === 1) {
            return roomB
        } else if (index === 2) {
            return roomC
        } else {
            return roomD
        }
    }
    const setRooms = (index: number, students: RoomAssignment[]): void => {
        if (index === 0) {
            setRoomA(students.map((s, indexId) => ({ ...s, roomId: 0, indexId })))
        } else if (index === 1) {
            setRoomB(students.map((s, indexId) => ({ ...s, roomId: 1, indexId })))
        } else if (index === 2) {
            setRoomC(students.map((s, indexId) => ({ ...s, roomId: 2, indexId })))
        } else {
            setRoomD(students.map((s, indexId) => ({ ...s, roomId: 3, indexId })))
        }
    }

    useEffect(() => {
        const students = new Map<string, RoomAssignment>()
        for (const student of conference.participants.filter(p => p.role !== 'teacher')) {
            students.set(student.wseUserId, {
                wseProfilePhotoUrl: student.wseProfilePhotoUrl,
                firstName: student.firstName,
                lastName: student.lastName,
                id: student.wseUserId,
                indexId: 0
            })
        }

        const assignments: RoomAssignment[] = Array.from(students.values())
        const initialAssignments: RoomAssignment[] = [...assignments]
        let chunkSizeDecreased = false
        let studentsPushed = 0

        Array.from(Array(4)).forEach((_, i) => {
            const chunkSize = Math.ceil(assignments.length / roomsCount)
            let studentsToPush = chunkSize
            if (!chunkSizeDecreased && studentsPushed > chunkSize) {
                chunkSizeDecreased = true
                if (initialAssignments.length - studentsPushed > (roomsCount - (i + 1)) * (i + 1)) {
                    studentsToPush = chunkSize + 1
                }
            }
            studentsToPush += roomsCount === i + 1 ? 1 : 0
            studentsPushed += studentsToPush
            setRooms(i, assignments.splice(0, studentsToPush))
        })
    }, [conference.participants, roomsCount])

    useEffect(() => {
        setRoomsEmpty(!roomA.length || !roomB.length || (roomsCount > 2 && !roomC.length) || (roomsCount > 3 && !roomD.length))
        setRoomsFull(roomA.length > 4 || roomB.length > 4 || (roomsCount > 2 && roomC.length > 4) || (roomsCount > 3 && roomD.length > 4))
    }, [roomA, roomB, roomC, roomD, roomsCount])

    return (
        <Wrapper>
            <InnerWrapper id='bor-modal-inner-wrapper'>
                <CloseButtonWrapper><CloseButton id='bor-modal-close-button' onClick={close} /></CloseButtonWrapper>
                <h4>Breakout Rooms</h4>
                {step === 1 ? (
                    <SettingsModalStep
                        roomsCount={roomsCount}
                        handleRoomCountChange={handleRoomCountChange}
                        duration={borDuration}
                        handleDurationChange={handleDurationChange}
                        mode={borMode}
                        setMode={setBorMode}
                        setStep={setStep}
                    />
                ) : (
                    <RoomsModalStep
                        roomsCount={roomsCount}
                        getRoom={getRoom}
                        setRooms={setRooms}
                        setStep={setStep}
                        startBor={handleBorStart}
                        roomsEmpty={roomsEmpty}
                        roomsFull={roomsFull}
                    />
                )}
            </InnerWrapper>
        </Wrapper>
    )
}

export { BreakoutRoomsModal }
