import { apiRequest } from '../api-request'
import { ClassroomId, WseUserId } from 'api/types'
import { Results } from './results'
import { Guid } from './types'

type PartialResults = {
    ratingSchemaPartItemId: Guid
    number: number | null
    result: number
    registrationId: Guid
    name: string
}

type StudentClassResults = {
    partialResults: PartialResults[]
    results: Results[]
}

type ClassResultViewed = {
    activityId: string
    userId: string
}

const getStudentClassResults = async (auth: string, activityId: ClassroomId, userId: WseUserId): Promise<StudentClassResults> => {
    return await apiRequest<StudentClassResults>({
        baseUrl: process.env.REACT_APP_BS_API_URL,
        path: `/classes/${activityId}/student/${userId}/classResults`,
        method: 'GET',
        auth: process.env.REACT_APP_QUALWORLD_AUTH ?? auth
    })
}

const getClassResultsViewed = async (auth: string, activityId: ClassroomId, userId: WseUserId): Promise<void> => {
    const payload: ClassResultViewed = { activityId, userId }
    return await apiRequest<undefined>({
        baseUrl: process.env.REACT_APP_WSE_API_URL,
        path: '/updateClassResultsNotification',
        method: 'POST',
        auth: process.env.REACT_APP_QUALWORLD_AUTH ?? auth,
        payload
    })
}

export { getStudentClassResults, getClassResultsViewed }
export type { StudentClassResults }
