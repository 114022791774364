import { EraserFreeAnnotation, PencilAnnotation } from 'primus/types/events'

type CanvasCoordinates = {
    x: number
    y: number
}

type PencilConstructorParams = {
    ow?: number
    path?: CanvasCoordinates[]
    lineCap?: CanvasLineCap
    lineJoin?: CanvasLineJoin
    color?: string
    size?: number
}

type TextBoxConstructorParams = {
    ow?: number
    text: string[]
    width: string
    height: string
    x: number
    y: number
    color?: string
    size?: number
}

abstract class Annotation {
    abstract extendAnnotation (coords: CanvasCoordinates): void
    abstract serialize (): any
    abstract render (): void
}

class Pencil extends Annotation {
    private readonly path: CanvasCoordinates[]
    private readonly lineCap: CanvasLineCap
    private readonly lineJoin: CanvasLineJoin
    private readonly color: string
    private readonly size: number
    public readonly ow: number

    constructor (
        private readonly ctx: CanvasRenderingContext2D,
        private readonly canvas: HTMLCanvasElement,
        params: PencilConstructorParams
    ) {
        super()

        this.path = params?.path ?? []
        this.lineCap = params?.lineCap ?? 'round'
        this.lineJoin = params?.lineJoin ?? 'round'
        this.color = params?.color ?? 'black'
        this.size = params?.size ?? 1
        this.ow = params?.ow ?? this.canvas.width
    }

    public readonly render = (): void => {
        const scaleFactor = this.canvas.width / this.ow

        this.ctx.lineCap = this.lineCap
        this.ctx.lineJoin = this.lineJoin
        this.ctx.strokeStyle = this.color
        this.ctx.lineWidth = this.size * scaleFactor

        if (this.canvas.width !== this.ow) {
            this.ctx.save()

            this.ctx.scale(scaleFactor, scaleFactor)
        }

        this.ctx.beginPath()

        for (let index = 0; index < this.path.length; index++) {
            if (index > 0) {
                this.ctx.moveTo(this.path[index - 1].x, this.path[index - 1].y)
            } else {
                this.ctx.moveTo(this.path[index].x - 1, this.path[index].y)
            }
            this.ctx.lineTo(this.path[index].x, this.path[index].y)
        }

        this.ctx.stroke()

        if (this.canvas.width !== this.ow) {
            this.ctx.restore()
        }

        this.ctx.closePath()
    }

    public serialize = (): PencilAnnotation => {
        return {
            size: this.size,
            color: this.color,
            ow: this.ow,
            scale: null,
            rotate: null,
            transform: null,
            opacity: 1,
            tool: 'pencil',
            path: this.path
        }
    }

    public extendAnnotation = (coords: CanvasCoordinates): void => {
        this.addPoint(coords)
        this.render()
    }

    private readonly addPoint = (coords: CanvasCoordinates): void => {
        this.path.push(coords)

        this.render()
    }
}

class Eraser extends Annotation {
    public readonly ow: number
    private readonly path: CanvasCoordinates[]
    private readonly lineCap: CanvasLineCap
    private readonly lineJoin: CanvasLineJoin
    private readonly color: string
    private readonly size: number

    constructor (
        private readonly ctx: CanvasRenderingContext2D,
        private readonly canvas: HTMLCanvasElement,
        params: PencilConstructorParams
    ) {
        super()

        this.path = params?.path ?? []
        this.lineCap = params?.lineCap ?? 'round'
        this.lineJoin = params?.lineJoin ?? 'round'
        this.color = 'rgba(0,0,0,1)'
        this.size = params?.size ?? 50
        this.ow = params?.ow ?? this.canvas.width
    }

    public readonly render = (): void => {
        const scaleFactor = this.canvas.width / this.ow
        const initialCompositeOperation = this.ctx.globalCompositeOperation

        this.ctx.lineCap = this.lineCap
        this.ctx.lineJoin = this.lineJoin
        this.ctx.strokeStyle = this.color
        this.ctx.lineWidth = this.size * scaleFactor

        if (this.canvas.width !== this.ow) {
            this.ctx.save()

            this.ctx.scale(scaleFactor, scaleFactor)
        }

        this.ctx.beginPath()

        for (let index = 0; index < this.path.length; index++) {
            if (index > 0) {
                this.ctx.moveTo(this.path[index - 1].x, this.path[index - 1].y)
            } else {
                this.ctx.moveTo(this.path[index].x - 1, this.path[index].y)
            }

            this.ctx.globalCompositeOperation = 'destination-out'

            this.ctx.lineTo(this.path[index].x, this.path[index].y)
        }

        this.ctx.stroke()

        if (this.canvas.width !== this.ow) {
            this.ctx.restore()
        }

        this.ctx.globalCompositeOperation = initialCompositeOperation

        this.ctx.closePath()
    }

    public serialize = (): EraserFreeAnnotation => {
        return {
            tool: 'eraser-free',
            ow: this.ow,
            path: this.path
        }
    }

    public extendAnnotation = (coords: CanvasCoordinates): void => {
        this.addPoint(coords)
        this.render()
    }

    private readonly addPoint = (coords: CanvasCoordinates): void => {
        this.path.push(coords)

        this.render()
    }
}

class Text extends Annotation {
    public readonly ow: number
    private readonly text: string[]
    private readonly width: string
    private readonly height: string
    private readonly x: number
    private readonly y: number
    private readonly size: number
    private readonly color: string

    constructor (
        private readonly ctx: CanvasRenderingContext2D,
        private readonly canvas: HTMLCanvasElement,
        params: TextBoxConstructorParams
    ) {
        super()

        this.text = params.text
        this.size = params?.size ?? 20
        this.width = params.width
        this.height = params.height
        this.x = params.x
        this.y = params.y
        this.ow = params?.ow ?? this.canvas.width
        this.color = params?.color ?? 'black'
    }

    extendAnnotation (): void {}

    render (): void {
        const scaleFactor = this.canvas.width / this.ow
        const initialCompositeOperation = this.ctx.globalCompositeOperation

        this.ctx.strokeStyle = this.color
        // this.ctx.lineWidth = this.size * scaleFactor
        this.ctx.font = this.size + 'px sans-serif'
        this.ctx.fillStyle = this.color

        if (this.canvas.width !== this.ow) {
            this.ctx.save()

            this.ctx.scale(scaleFactor, scaleFactor)
        }

        this.ctx.beginPath()

        this.text.forEach((text, index) => this.ctx.fillText(text, this.x, this.y + (this.size * index)))

        this.ctx.stroke()

        if (this.canvas.width !== this.ow) {
            this.ctx.restore()
        }

        this.ctx.globalCompositeOperation = initialCompositeOperation

        this.ctx.closePath()
    }

    serialize (): any {
        return {
            size: this.size,
            color: this.color,
            scale: null,
            ow: this.ow,
            rotate: null,
            transform: null,
            tool: 'textbox',
            y: this.y,
            x: this.x,
            h: this.height,
            w: this.width,
            text: this.text
        }
    }
}

export { Annotation, Pencil, Eraser, Text }
