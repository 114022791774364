import { CommonIconButtonProps, StandardIconButton } from './StandardIconButton'
import { MinusActiveIcon, MinusIcon } from 'icons'

const MinusIconButton: React.FC<CommonIconButtonProps> = ({ id, active, disabled, size = 'medium', onClick }) => (
    <StandardIconButton
        id={id}
        color='secondary'
        icon={active ? MinusActiveIcon : MinusIcon}
        disabled={disabled}
        onClick={onClick}
        size={size}
    />
)

export { MinusIconButton }
