import { styled } from '@mui/material'

const ButtonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    '&:first-of-type': {
        marginLeft: 0
    },
    '&:last-of-type': {
        marginRight: 0
    }
}))
const ButtonLabelWrapper = styled('div')(({ theme }) => ({
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    lineHeight: '140%',
    whiteSpace: 'nowrap'
}))

export { ButtonWrapper, ButtonLabelWrapper }
