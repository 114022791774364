import { useContext } from 'react'
import { ConferenceContext } from 'contexts/conference'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import { MessageWrapper, StatusPanel } from '.'

const WaitingForOthers: React.FC<{ breakouts?: BreakoutRoomsState }> = ({ breakouts }) => {
    const conference = useContext(ConferenceContext)
    const sessionJoined = conference.status === 'session-joined'
    const currentRoom = sessionJoined ? breakouts?.getCurrentRoom() : null
    const roomUsersIds = sessionJoined && (currentRoom || currentRoom === 0) ? breakouts?.rooms[currentRoom].users : null
    const roomUsers = roomUsersIds
        ? conference.participants.filter(p =>
            // render teacher if in the room
            (currentRoom === breakouts?.teacherRoom && p.role === 'teacher') ||
            // render students in the room
            (roomUsersIds.includes(p.wseUserId)))
        : null
    const participants = roomUsers || conference.participants

    if (participants.length <= 1) {
        return (
            <StatusPanel>
                <MessageWrapper>Waiting for others to join</MessageWrapper>
            </StatusPanel>
        )
    } else return null
}

export { WaitingForOthers }
