import { apiRequest } from '../api-request'
import { mock } from './mocks/categories'
import { CategoryType, ContentType, Guid } from './types'

type ContentItem = {
    id: Guid
    type: ContentType
    sequence: number
    description: string
    duration: number
    url: string
    isSkippable: boolean
}

type Category = {
    id: Guid
    type: CategoryType
    sequence: number
    path: string
    attributes: Record<string, string>
    childCategories: Category[]
    contentItems: ContentItem[]
}

const getRootCategories = async (auth: string, maxDepth: number): Promise<Category[]> => {
    if (!process.env.REACT_APP_USE_ASSESSMENT_MOCKS) {
        return await apiRequest<Category[]>({
            baseUrl: process.env.REACT_APP_BS_API_URL,
            path: '/categories/roots',
            method: 'GET',
            auth: process.env.REACT_APP_QUALWORLD_AUTH ?? auth,
            query: {
                maxDepth: maxDepth.toString()
            }
        })

    } else {
        return mock
    }
}

export type { Category }
export { getRootCategories }
