import { Dispatch } from 'react'
import { getZoomSignature } from 'api/zoom-signature/zoom-signature'
import { ConferenceAction } from 'contexts/conference/types'
import { ZoomClient } from './ZoomClient'
import ZoomSDK from '@zoom/videosdk'
import { WSEEncodedUserInfo } from './types'

const ZoomRTCProviderId = '3'

type ZoomClientInitializerParams = {
    dispatch: Dispatch<ConferenceAction>
    selfView?: HTMLCanvasElement | null
    canvas: HTMLCanvasElement
    topic: string
    wseEncodedUserInfo: WSEEncodedUserInfo
    auth: string
}

const initializeZoomClient = async ({ dispatch, auth, canvas, selfView, topic, wseEncodedUserInfo }: ZoomClientInitializerParams): Promise<ZoomClient> => {
    const signature = await getZoomSignature(auth, wseEncodedUserInfo.id, topic, wseEncodedUserInfo.role)
    const videoClient = ZoomSDK.createClient()

    await videoClient.init('en-US', 'CDN')

    return new ZoomClient({
        dispatch,
        signature,
        canvas,
        selfView,
        topic,
        wseEncodedUserInfo,
        client: videoClient
    })
}

export type { ZoomSessionInfo } from './types'
export { ZoomRTCProviderId, initializeZoomClient }
export type { ZoomClientInitializerParams }
