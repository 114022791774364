import { ClassroomId, WseUserId, UserRole } from 'api/types'
import { Primus, PrimusStatus, DataPacket, connect } from './primus'
import {
    AddAnnotationEvent,
    ChangeScreenEvent,
    WhiteboardClearAllEvent,
    EndSessionEvent,
    ExpelEvent,
    HandRaiseUpdateEvent,
    MuteAllEvent,
    PrimusGocEventName,
    RemoveAnnotationEvent,
    SelfMuteCamEvent,
    SelfMuteMicEvent,
    UserAwayEvent,
    UserJoinedEvent,
    UserLogoutEvent,
    UserOnlineEvent,
    AddBoardEvent,
    CloseBoardEvent,
    OpenBoardEvent,
    WhiteboardImportFileEvent,
    PptActionEvent,
    WhiteboardUpdatePointerEvent,
    WhiteboardRemovePointerEvent,
    WhiteboardPrivilegeUpdateEvent,
    ChatPublicEvent,
    ChatPrivateEvent,
    ChatNotificationEvent,
    BreakoutStartEvent,
    BreakoutStopEvent,
    BreakoutAddUserEvent,
    BreakoutSwapUserEvent,
    BreakoutTeacherLinkEvent,
    BreakoutRemoveUserEvent,
    ClassResultsEvent,
    HardMuteEvent,
    CueCardsEvent
} from './types/events'
import Queue from 'services/Queue'

interface PrimusClientParams {
    classroomId: ClassroomId
    userId: WseUserId
    userRole: UserRole
}

type Message = {
    channel: string
    key: PrimusGocEventName
    event: PrimusGocEventName
    data: any
    publish: boolean
}

class PrimusClient {
    private readonly primus: Primus
    public lastMsgId: number = 0
    private readonly messageQueue: Queue<Message>
    private connectionAlive: boolean = true

    constructor (private readonly params: PrimusClientParams) {
        const URL = process.env.REACT_APP_PRIMUS_SERVER_URL ?? ''

        this.primus = connect(URL, {
            strategy: ['online', 'timeout', 'disconnect']
        })

        this.messageQueue = new Queue()

        this.primus.on('open', () => {
            console.log('[PRIMUS] Connection established')
            this.updateConnectionStatus('ONLINE')

            setTimeout(() => {
                this.syncMessageQueue()
            }, 1000)
        })

        this.primus.on('close', () => {
            console.log('[PRIMUS] Connection closed')
            this.updateConnectionStatus('OFFLINE')
            window.dispatchEvent(
                new CustomEvent('connection-status', {
                    detail: {
                        status: false
                    }
                })
            )
        })

        this.primus.on('disconnection', () => {
            console.log('[PRIMUS] Connection Disconnected')
            this.updateConnectionStatus('OFFLINE')
        })

        this.primus.on('reconnected', () => {
            console.log('[PRIMUS] Connection Reconnected')
            this.subscribeToActivityChannel(-1)
            this.updateConnectionStatus('ONLINE')
            window.dispatchEvent(
                new CustomEvent('connection-status', {
                    detail: {
                        status: true
                    }
                })
            )
        })

        this.primus.on('error', err => {
            console.log(`[PRIMUS] Connection Error ${err}`)
        })
    }

    on (eventName: 'user:online', hander: (ev: UserOnlineEvent) => void): void
    on (eventName: 'end-session', hander: (ev: EndSessionEvent) => void): void
    on (eventName: 'self:mute-cam', hander: (ev: SelfMuteCamEvent) => void): void
    on (eventName: 'self:mute-mic', hander: (ev: SelfMuteMicEvent) => void): void
    on (eventName: 'app:logout', hander: (ev: UserLogoutEvent) => void): void
    on (eventName: 'remote:mute-all-mic', hander: (ev: MuteAllEvent) => void): void
    on (eventName: 'user:expel', hander: (ev: ExpelEvent) => void): void
    on (eventName: 'change-screen', hander: (ev: ChangeScreenEvent) => void): void
    on (eventName: 'handraise:update', hander: (ev: HandRaiseUpdateEvent) => void): void
    on (eventName: 'user:away', hander: (ev: UserAwayEvent) => void): void
    on (eventName: 'user:joined', hander: (ev: UserJoinedEvent) => void): void
    on (eventName: 'wb:add-annotation', hander: (ev: AddAnnotationEvent) => void): void
    on (eventName: 'wb:remove-annotation', hander: (ev: RemoveAnnotationEvent) => void): void
    on (eventName: 'wb:clear-all', hander: (ev: WhiteboardClearAllEvent) => void): void
    on (eventName: 'wb:add-board', hander: (ev: AddBoardEvent) => void): void
    on (eventName: 'wb:close-board', hander: (ev: CloseBoardEvent) => void): void
    on (eventName: 'wb:open-board', hander: (ev: OpenBoardEvent) => void): void
    on (eventName: 'wb:import-file', hander: (ev: WhiteboardImportFileEvent) => void): void
    on (eventName: 'wb:ppt-action', hander: (ev: PptActionEvent) => void): void
    on (eventName: 'wb:update-pointer', hander: (ev: WhiteboardUpdatePointerEvent) => void): void
    on (eventName: 'wb:remove-pointer', hander: (ev: WhiteboardRemovePointerEvent) => void): void
    on (eventName: 'wb:privilege-update', hander: (ev: WhiteboardPrivilegeUpdateEvent) => void): void
    on (eventName: 'chat:public', hander: (ev: ChatPublicEvent) => void): void
    on (eventName: 'chat:private', hander: (ev: ChatPrivateEvent) => void): void
    on (eventName: 'chat:notification', hander: (ev: ChatNotificationEvent) => void): void
    on (eventName: 'breakout:start', hander: (ev: BreakoutStartEvent) => void): void
    on (eventName: 'breakout:stop', hander: (ev: BreakoutStopEvent) => void): void
    on (eventName: 'breakout:add-user', hander: (ev: BreakoutAddUserEvent) => void): void
    on (eventName: 'breakout:swap-user', hander: (ev: BreakoutSwapUserEvent) => void): void
    on (eventName: 'breakout:teacher-link', hander: (ev: BreakoutTeacherLinkEvent) => void): void
    on (eventName: 'breakout:remove-user', hander: (ev: BreakoutRemoveUserEvent) => void): void
    on (eventName: 'class-results', hander: (ev: ClassResultsEvent) => void): void
    on (eventName: 'remote:mute-mic', hander: (ev: HardMuteEvent) => void): void
    on (eventName: 'cueCard:add', hander: (ev: CueCardsEvent) => void): void
    on (eventName: PrimusGocEventName, handler: (ev: any) => void): void {
        this.primus.on(eventName, (ev: DataPacket) => {
            if (ev.id) {
                this.lastMsgId = ev.id
            } else {
                this.lastMsgId++
            }
            handler(ev.data)
        })
    }

    send (eventName: 'user:online', ev: UserOnlineEvent): void
    send (eventName: 'end-session', ev: EndSessionEvent): void
    send (eventName: 'self:mute-cam', ev: SelfMuteCamEvent): void
    send (eventName: 'self:mute-mic', ev: SelfMuteMicEvent): void
    send (eventName: 'app:logout', ev: UserLogoutEvent): void
    send (eventName: 'remote:mute-all-mic', ev: MuteAllEvent): void
    send (eventName: 'user:expel', ev: ExpelEvent): void
    send (eventName: 'change-screen', ev: ChangeScreenEvent): void
    send (eventName: 'handraise:update', ev: HandRaiseUpdateEvent): void
    send (eventName: 'user:away', ev: UserAwayEvent): void
    send (eventName: 'user:joined', ev: UserJoinedEvent): void
    send (eventName: 'wb:add-annotation', ev: AddAnnotationEvent): void
    send (eventName: 'wb:remove-annotation', ev: RemoveAnnotationEvent): void
    send (eventName: 'wb:clear-all', ev: WhiteboardClearAllEvent): void
    send (eventName: 'wb:add-board', ev: AddBoardEvent): void
    send (eventName: 'wb:close-board', ev: CloseBoardEvent): void
    send (eventName: 'wb:open-board', ev: OpenBoardEvent): void
    send (eventName: 'wb:import-file', ev: WhiteboardImportFileEvent): void
    send (eventName: 'wb:ppt-action', ev: PptActionEvent): void
    send (eventName: 'wb:update-pointer', ev: WhiteboardUpdatePointerEvent): void
    send (eventName: 'wb:remove-pointer', ev: WhiteboardRemovePointerEvent): void
    send (eventName: 'wb:privilege-update', ev: WhiteboardPrivilegeUpdateEvent): void
    send (eventName: 'chat:public', ev: ChatPublicEvent): void
    send (eventName: 'chat:private', ev: ChatPrivateEvent): void
    send (eventName: 'chat:notification', ev: ChatNotificationEvent): void
    send (eventName: 'breakout:start', ev: BreakoutStartEvent): void
    send (eventName: 'breakout:stop', ev: BreakoutStopEvent): void
    send (eventName: 'breakout:add-user', ev: BreakoutAddUserEvent): void
    send (eventName: 'breakout:swap-user', ev: BreakoutSwapUserEvent): void
    send (eventName: 'breakout:teacher-link', ev: BreakoutTeacherLinkEvent): void
    send (eventName: 'breakout:remove-user', ev: BreakoutRemoveUserEvent): void
    send (eventName: 'class-results', ev: ClassResultsEvent): void
    send (eventName: 'remote:mute-mic', ev: HardMuteEvent): void
    send (eventName: 'cueCard:add', ev: CueCardsEvent): void
    send (eventName: PrimusGocEventName, ev: any): void {
        if (this.connectionAlive) {
            this.primus.send(eventName, {
                channel: this.params.classroomId,
                data: ev,
                event: eventName,
                key: eventName,
                publish: true
            })
        } else {
            const message = {
                channel: this.params.classroomId,
                key: eventName,
                event: eventName,
                data: ev,
                publish: true
            }

            this.messageQueue.enqueue(message)
        }
        this.lastMsgId++
    }

    off (eventName: 'user:online', hander: (ev: UserOnlineEvent) => void): void
    off (eventName: 'end-session', hander: (ev: EndSessionEvent) => void): void
    off (eventName: 'self:mute-cam', hander: (ev: SelfMuteCamEvent) => void): void
    off (eventName: 'self:mute-mic', hander: (ev: SelfMuteMicEvent) => void): void
    off (eventName: 'app:logout', hander: (ev: UserLogoutEvent) => void): void
    off (eventName: 'remote:mute-all-mic', hander: (ev: MuteAllEvent) => void): void
    off (eventName: 'user:expel', hander: (ev: ExpelEvent) => void): void
    off (eventName: 'change-screen', hander: (ev: ChangeScreenEvent) => void): void
    off (eventName: 'handraise:update', hander: (ev: HandRaiseUpdateEvent) => void): void
    off (eventName: 'user:away', hander: (ev: UserAwayEvent) => void): void
    off (eventName: 'user:joined', hander: (ev: UserJoinedEvent) => void): void
    off (eventName: 'wb:add-annotation', hander: (ev: AddAnnotationEvent) => void): void
    off (eventName: 'wb:remove-annotation', hander: (ev: RemoveAnnotationEvent) => void): void
    off (eventName: 'wb:clear-all', hander: (ev: WhiteboardClearAllEvent) => void): void
    off (eventName: 'wb:add-board', hander: (ev: AddBoardEvent) => void): void
    off (eventName: 'wb:close-board', hander: (ev: CloseBoardEvent) => void): void
    off (eventName: 'wb:open-board', hander: (ev: OpenBoardEvent) => void): void
    off (eventName: 'wb:import-file', hander: (ev: WhiteboardImportFileEvent) => void): void
    off (eventName: 'wb:ppt-action', hander: (ev: PptActionEvent) => void): void
    off (eventName: 'wb:update-pointer', hander: (ev: WhiteboardUpdatePointerEvent) => void): void
    off (eventName: 'wb:remove-pointer', hander: (ev: WhiteboardRemovePointerEvent) => void): void
    off (eventName: 'wb:privilege-update', hander: (ev: WhiteboardPrivilegeUpdateEvent) => void): void
    off (eventName: 'chat:public', hander: (ev: ChatPublicEvent) => void): void
    off (eventName: 'chat:private', hander: (ev: ChatPrivateEvent) => void): void
    off (eventName: 'chat:notification', hander: (ev: ChatNotificationEvent) => void): void
    off (eventName: 'breakout:start', hander: (ev: BreakoutStartEvent) => void): void
    off (eventName: 'breakout:stop', hander: (ev: BreakoutStopEvent) => void): void
    off (eventName: 'breakout:add-user', hander: (ev: BreakoutAddUserEvent) => void): void
    off (eventName: 'breakout:swap-user', hander: (ev: BreakoutSwapUserEvent) => void): void
    off (eventName: 'breakout:teacher-link', hander: (ev: BreakoutTeacherLinkEvent) => void): void
    off (eventName: 'breakout:remove-user', hander: (ev: BreakoutRemoveUserEvent) => void): void
    off (eventName: 'class-results', hander: (ev: ClassResultsEvent) => void): void
    off (eventName: 'remote:mute-mic', hander: (ev: HardMuteEvent) => void): void
    off (eventName: 'cueCard:add', hander: (ev: CueCardsEvent) => void): void
    off (eventName: PrimusGocEventName, handler: (ev: DataPacket) => void): void {
        this.primus.off(eventName, handler)
    }

    end (): void {
        this.primus.end()
    }

    removeAllListeners (eventName: 'wb:add-annotation', hander: (ev: AddAnnotationEvent) => void): void
    removeAllListeners (eventName: 'wb:clear-all', hander: (ev: WhiteboardClearAllEvent) => void): void
    removeAllListeners (eventName: PrimusGocEventName, handler: (ev: DataPacket) => void): void {
        this.primus.removeAllListeners(eventName)
    }

    subscribeToActivityChannel (lastMsgId: number): void {
        this.lastMsgId = lastMsgId !== -1 ? lastMsgId : this.lastMsgId
        this.primus.send('subscribe', {
            channel: this.params.classroomId,
            userId: this.params.userId,
            userRole: this.params.userRole,
            lastMsgId: this.lastMsgId,
            deviceType: 'web'
        })
    }

    syncMessageQueue (): void {
        if (!this.messageQueue.isEmpty()) {
            if (this.messageQueue.getLength() > 1) {
                this.primus.send('batch-update', {
                    channel: this.params.classroomId,
                    key: 'batch-update',
                    event: 'batch-update',
                    data: this.messageQueue.queue
                })

                this.messageQueue.dequeueAll()
            } else {
                const item = this.messageQueue.dequeue()

                item && this.primus.send(item.event, item)
            }
        }
    };

    updateConnectionStatus (status: string): void {
        try {
            if (status !== 'ONLINE' && status !== 'OFFLINE') {
                return
            }

            this.connectionAlive = (status === 'ONLINE')
        } catch (error) {
            console.log('Error: ' + error)
        }
    }

    async resolveOnOpen (): Promise<PrimusClient> {
        return new Promise(resolve => {
            this.primus.on('open', () => {
                resolve(this)
            })
        })
    }

    get status (): PrimusStatus {
        return this.primus.readyState
    }
}

export { PrimusClient }
export type { PrimusClientParams }
