import { Dispatch, SetStateAction, useEffect } from 'react'
import { styled } from '@mui/material'
import { ActivityRating, StudentWithScores, Score, TaskRating, SavingCallbacks } from 'contexts/assessment'
import { RectangleButton } from 'components/buttons'
import { ActivityDetails } from './ActivityDetails'
import { getDisplayName, getSameOriginPhotoUrl } from 'utils/get-display-name'
import { useSaveEncounterResults, calculateFinalScore } from './SummaryPanel'

const Wrapper = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2)
}))
const SpacingWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    marginLeft: theme.spacing(-1),
    width: `calc(100% + ${theme.spacing(2)})`,
    padding: `${theme.spacing(0.25)} 0`
}))
const StudentsSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2)
}))
const StudentWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.neutral.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1)
}))
const StudentDetails = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    width: theme.spacing(9.5),
    p: {
        width: '100%',
        fontSize: theme.spacing(1.8),
        fontWeight: '700',
        lineHeight: 1.2,
        marginTop: theme.spacing(1),
        wordWrap: 'break-word'
    }
}))
const Avatar = styled('div')(({ theme }) => ({
    fontSize: 'var(--tile-fontsize)',
    fontWeight: 800,
    width: theme.spacing(7),
    aspectRatio: '1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.35)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.neutral.main
}))
const StudentGrades = styled('div')<{ lastActivity: boolean }>(({ theme, lastActivity }) => ({
    display: 'flex',
    width: `calc(100% - ${theme.spacing(9.5)})`,
    flexWrap: lastActivity ? 'wrap' : 'nowrap',
    gap: theme.spacing(1)
}))
const GradeWrapper = styled('div')<{ lastActivity: boolean }>(({ theme, lastActivity }) => ({
    flex: lastActivity ? 'none' : '1',
    width: lastActivity ? theme.spacing(12) : 'auto',
    marginRight: lastActivity ? theme.spacing(1) : 0,
    p: {
        fontSize: theme.spacing(1.6),
        lineHeight: 1.2,
        marginBottom: theme.spacing(0.5),
        span: {
            fontWeight: '700',
            marginRight: theme.spacing(0.5)
        }
    }
}))
const Grade = styled('div')<{ grade?: number; isDisabled?: boolean }>(({ theme, grade, isDisabled }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(10),
    minWidth: theme.spacing(10),
    backgroundColor: theme.palette.neutral.alternate,
    border: `solid 1px ${theme.palette.neutral.hover}`,
    borderRadius: theme.spacing(4),
    overflow: 'hidden',
    pointerEvents: 'none',
    opacity: isDisabled ? '0.5' : 'none',
    span: {
        flex: 1,
        height: '100%',
        fontSize: theme.spacing(1.6),
        lineHeight: 1.6,
        borderRadius: '50%',
        textAlign: 'center',
        color: theme.palette.secondary.main,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        pointerEvents: 'auto',
        '&:nth-of-type(1)': {
            backgroundColor: grade === 1 ? '#F9B3BD' : 'transparent'
        },
        '&:nth-of-type(2)': {
            backgroundColor: grade === 2 ? '#FFEADD' : 'transparent'
        },
        '&:nth-of-type(3)': {
            backgroundColor: grade === 3 ? '#A7D692' : 'transparent'
        },
        '&:nth-of-type(4)': {
            backgroundColor: grade === 4 ? '#6CBE3E' : 'transparent'
        }
    }
}))
const StudentComment = styled('textarea')<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
    display: 'flex',
    width: `calc(100% - ${theme.spacing(9.5)})`,
    border: `solid 1px ${theme.palette.neutral.hover}`,
    color: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: theme.spacing(1.8),
    lineHeight: 1.2,
    cursor: isDisabled ? 'not-allowed' : 'auto'
}))
const ButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    gap: theme.spacing(1)
}))

type ActivityPanelProps = {
    setCurrentTab: Dispatch<SetStateAction<'activity' | 'comments' | 'summary'>>
    currentTab: string
    activitiesRatings: ActivityRating[]
    students: StudentWithScores[]
    currentActivityIndex: number
    setCurrentActivityIndex: Dispatch<SetStateAction<number>>
    score?: Score
    setScore: Dispatch<SetStateAction<Score | undefined>>
    setSavingStatus: () => SavingCallbacks
    tasksRatings: TaskRating[]
    isClassClosed: boolean
}
const ActivityPanel: React.FC<ActivityPanelProps> = props => {
    const {
        setScore,
        students,
        setCurrentTab,
        currentTab,
        activitiesRatings,
        currentActivityIndex,
        setCurrentActivityIndex,
        setSavingStatus,
        tasksRatings,
        isClassClosed
    } = props
    const currentActivity = activitiesRatings[currentActivityIndex]
    const lastActivity = currentActivityIndex === activitiesRatings.length - 1
    const saveResults = useSaveEncounterResults(students, setSavingStatus)

    // Function to move to the next activity or tab
    const moveToNextActivityOrTab = (): void => {
        if (lastActivity) {
            if (currentTab === 'activity') {
                setCurrentTab('comments')
            } else {
                setCurrentTab('summary')
            }
        } else {
            setCurrentActivityIndex(prev => ++prev)
        }
    }
    /**
     * handleCommentChange.
     * @param {string} comment - Comments given by teacher.
     * @param {Object} student - The Current student's object.
     */
    const handleCommentChange = (comment: string, student: StudentWithScores): void => {
        student.comment = comment
        void saveResults(true, student)
    }

    /**
     * handleGradeChange.
     * @param {HTMLElement} target - Html element value.s
     * @param {Object} student - The Current student's object.
     * @param {Object} rating - The Score rating for the activities.
     */
    const handleGradeChange = (target: HTMLElement, student: StudentWithScores, rating: TaskRating): void => {
        const scoreValue = student.scores.get(rating.rating.id) === +target.innerHTML ? 0 : +target.innerHTML
        setScore({ value: scoreValue, rating: rating.rating, student })
        student.isScoreOrResultChanged = true
        student.isScoreAndResultAvailableInAPI = false
    }

    useEffect(() => {
        return () => {
            students.forEach(s => {
                s.finalScore = calculateFinalScore(tasksRatings.map(task =>
                    s.scores.get(task.rating.id)
                ))
                const currentStudentScores = [...s.scores.values()]
                s.isGraded = s.scores.size === tasksRatings.length && !currentStudentScores.includes(0)
            })
        }
    }, [])

    return (
        <Wrapper>
            <SpacingWrapper>
                <ActivityDetails currentActivity={currentActivity} />
                <StudentsSection>
                    {students.map(s => (
                        <StudentWrapper key={s.userId}>
                            <StudentDetails>
                                <Avatar sx={{ backgroundImage: s.photoUris.medium ? `url('${getSameOriginPhotoUrl(s.photoUris.medium)}')` : 'none' }}>
                                    {!s.photoUris.medium ? (
                                        <span id='participant-initials'>{getDisplayName(s, 'initials')}</span>
                                    ) : null}
                                </Avatar>
                                <p>{getDisplayName(s, 'student-name')}</p>
                            </StudentDetails>
                            {currentTab === 'comments' ? (
                                <StudentComment
                                    onBlur={e => handleCommentChange(e.target.value, s)}
                                    defaultValue={s.comment}
                                    disabled={s.isActivitiesCommentsDisabled || isClassClosed}
                                    isDisabled={s.isActivitiesCommentsDisabled || isClassClosed}
                                />
                            ) : (
                                <StudentGrades lastActivity={lastActivity}>
                                    {currentActivity.ratings.map(r => (
                                        <GradeWrapper key={r.rating.id} lastActivity={lastActivity}>
                                            {lastActivity ? (
                                                <p>
                                                    <span>{currentActivity.number}</span>
                                                    {r.description}
                                                </p>
                                            ) : null}
                                            <Grade
                                                onClick={e => s.isActivitiesCommentsDisabled || isClassClosed ? {}
                                                    : handleGradeChange(e.target as HTMLElement, s, r)}
                                                grade={s.scores.get(r.rating.id)}
                                                isDisabled={s.isActivitiesCommentsDisabled || isClassClosed}
                                            >
                                                <span>1</span>
                                                <span>2</span>
                                                <span>3</span>
                                                <span>4</span>
                                            </Grade>
                                        </GradeWrapper>
                                    ))}
                                </StudentGrades>
                            )}
                        </StudentWrapper>
                    ))}
                </StudentsSection>
            </SpacingWrapper>
            <ButtonsWrapper>
                <RectangleButton
                    onClick={() => setCurrentActivityIndex(prev => --prev)}
                    text='Previous Activity' color='error' variant='outlined'
                    disabled={currentActivityIndex === 0}
                />
                <RectangleButton
                    onClick={moveToNextActivityOrTab}
                    text='Next'
                    color='error'
                />
            </ButtonsWrapper>
        </Wrapper>
    )
}

export { ActivityPanel }
