type UserAgent = {
    isFullFeaturedMode: boolean
    isSupportedBrowser: boolean
    isAboveMinRequirement: boolean
    isMobileSafari: boolean
}

export const useFeatureCheck = (): UserAgent => {
    // check if userAgent is the desktop version of Chrome
    const isDesktopChrome = /^Mozilla\/5.0 \((?:Windows|Macintosh|X11).* Chrome\//.test(navigator.userAgent)
    const isDesktopEdge = /^Mozilla\/5.0 \((?:Windows|Macintosh|X11).* Edg\//.test(navigator.userAgent)
    const iOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)
    const webkit = navigator.userAgent.match(/WebKit/i)
    const isMobileSafari = iOS && webkit && !navigator.userAgent.match(/CriOS/i) || false
    const isSupportedBrowser = (isDesktopChrome || isDesktopEdge)
    const minCoreCount = 2
    // check if the number of logical processors available to run threads on the user's computer is above minCoreCount
    const isAboveMinRequirement = navigator.hardwareConcurrency > minCoreCount

    return {
        isFullFeaturedMode: isSupportedBrowser && isAboveMinRequirement,
        isSupportedBrowser,
        isAboveMinRequirement,
        isMobileSafari
    }
}
