import { UnitRating, Rating } from 'api/unit-assessment'

export type TaskRating = {
    description: string
    number: string
    rating: Rating
}

export type ActivityRating = {
    ratings: TaskRating[]
    number: string
}

export type RatingsGroups = {
    activitiesRatings: ActivityRating[]
    tasksRatings: TaskRating[]
}

export const groupRatings = (unitRatings: UnitRating[]): RatingsGroups => {
    const tasks = new Map<string, TaskRating[]>()
    const tasksRatings: TaskRating[] = []
    const reTask = /^(\d+\.\d+) (.*)/

    for (const activity of unitRatings) {
        for (const rating of activity.ratingSchemaPartItems) {
            const match = rating.name.match(reTask)
            if (match) {
                const [, number, description] = match
                const taskRating = {
                    description,
                    number,
                    rating
                }
                tasksRatings.push(taskRating)

                if (tasks.has(number)) {
                    tasks.get(number)!.push(taskRating)

                } else {
                    tasks.set(number, [taskRating])
                }

            }
        }
    }

    return {
        activitiesRatings: Array.from(tasks.entries()).map(([number, ratings]) => ({ number, ratings })),
        tasksRatings
    }
}
