import { useEffect, useRef, useState } from 'react'

export type Timer = [remainingTime: number | undefined, toggle: () => void, isPaused: boolean]

export const getElapsedTime = (startTime: number, extraTime: number, pausedAt: number | undefined): number => {
    const now = Date.now()
    const pauseTime = pausedAt
        ? now - pausedAt
        : 0

    return now - startTime - extraTime - pauseTime
}

export const useTimer = (startTime?: number, duration?: number): Timer => {
    const [remainingTime, setTimeRemaining] = useState<number>()
    const intervalId = useRef<NodeJS.Timeout>()
    const extraTime = useRef<number>(0)
    const pausedAt = useRef<number>()

    useEffect(() => {
        pausedAt.current = void 0
        extraTime.current = 0
    }, [startTime])

    useEffect(() => {
        if (startTime !== void 0 && isFinite(startTime) && duration !== void 0 && isFinite(duration)) {
            const elapsedTime = getElapsedTime(startTime, extraTime.current, pausedAt.current)

            if (duration > elapsedTime / 60000) {
                setTimeRemaining(duration * 60 - elapsedTime / 1000)

                intervalId.current = setInterval(() => {
                    const elapsedTime = getElapsedTime(startTime, extraTime.current, pausedAt.current)

                    if (duration > elapsedTime / 60000) {
                        setTimeRemaining(duration * 60 - elapsedTime / 1000)

                    } else {
                        clearInterval(intervalId.current)
                        setTimeRemaining(0)
                    }
                }, 100)

            } else {
                setTimeRemaining(0)
            }

        } else {
            pausedAt.current = void 0
            setTimeRemaining(void 0)
            extraTime.current = 0
        }

        return () => {
            clearInterval(intervalId.current)
        }
    }, [startTime, duration])

    const toggle = (): void => {
        if (remainingTime !== void 0 && remainingTime > 0) {
            if (pausedAt.current === void 0) {
                pausedAt.current = Date.now()

            } else {
                extraTime.current += Date.now() - pausedAt.current
                pausedAt.current = void 0
            }
        }
    }

    return [remainingTime, toggle, pausedAt.current !== void 0]
}
