import { PrimusClient } from 'primus'
import { WBAnnotation, WBBoardMount } from 'api/classroom/ClassroomState'
import { Annotation, Pencil, Eraser, Text } from './Annotation'

type CanvasCoordinates = {
    x: number
    y: number
}
type WbInitialState = {
    annotation: Annotation
}
type Board = {
    index: 0
    type: string
    mount: WBBoardMount | null
    label: string
    annotations: WBAnnotation[]
}
type WhiteBoardAnnotation = (whiteBoardAnnotation: Board) => void
type Tool = 'pencil' | 'text' | 'eraser'

class WhiteBoardAnnotationService {
    private annotation?: Annotation
    private readonly ctx: CanvasRenderingContext2D
    private readonly annotations: WbInitialState[] = []
    private activeTool: Tool | null = null
    private activeSlide: number = 0
    private textBoxActive: boolean = false
    private readonly setWhiteBoardAnnotation: WhiteBoardAnnotation

    constructor (
        private readonly canvas: HTMLCanvasElement,
        private readonly primus: PrimusClient,
        boardInitialState: Board | null,
        private readonly textBox: HTMLDivElement | null,
        private readonly textArea: HTMLTextAreaElement | null,
        setWhiteBoardAnnotation: WhiteBoardAnnotation
    ) {
        this.setWhiteBoardAnnotation = setWhiteBoardAnnotation
        this.ctx = canvas.getContext('2d')!

        if (boardInitialState) {
            boardInitialState.annotations
                .filter(a => !!a)
                .forEach(a => {
                    let annotation: Annotation

                    switch (a.tool) {
                        case 'pencil':
                            annotation = new Pencil(this.ctx, this.canvas, { path: a.path, ow: a.ow })
                            this.annotations.push({ annotation })
                            break
                        case 'eraser-free':
                            annotation = new Eraser(this.ctx, this.canvas, { path: a.path, ow: a.ow })
                            this.annotations.push({ annotation })
                            break
                        case 'textbox':
                            annotation = new Text(this.ctx, this.canvas, {
                                text: a.text,
                                ow: a.ow,
                                height: a.h,
                                width: a.w,
                                x: a.x,
                                y: a.y
                            })
                            this.annotations.push({ annotation })
                            break
                    }
                })
        }

        if (textBox && textArea) {
            this.handleTextBoxEvents()
        }
    }

    public registerMouseEvents = (): void => {
        this.canvas.addEventListener('mousedown', this.mouseDown)
    }

    private readonly handleAddAnnotation = (eventData: any): void => {
        let annotation: Annotation

        switch (eventData.annotation.tool) {
            case 'pencil':
                annotation = new Pencil(this.ctx, this.canvas, { path: eventData.annotation.path, ow: eventData.annotation.ow })
                break
            case 'eraser-free':
                annotation = new Eraser(this.ctx, this.canvas, { path: eventData.annotation.path, ow: eventData.annotation.ow })
                break
            case 'textbox':
                annotation = new Text(this.ctx, this.canvas, {
                    ow: eventData.annotation.ow,
                    text: eventData.annotation.text,
                    x: eventData.annotation.x,
                    y: eventData.annotation.y,
                    width: eventData.annotation.width,
                    height: eventData.annotation.height
                })
                break
            default:
                throw new Error('Unknown annotation type')
        }

        this.annotations.push({ annotation })
        this.setWhiteBoardAnnotations()
        this.renderCurrentSlideAnnotations()
    }

    private readonly handleClearAll = (): void => {
        this.annotations.length = 0
        this.clearCanvas()
        this.setWhiteBoardAnnotations()
    }

    public unregisterMouseEvents = (): void => {
        this.canvas.removeEventListener('mousedown', this.mouseDown)
        this.canvas.removeEventListener('mousemove', this.mouseMove)
        window.removeEventListener('mouseup', this.mouseUp)
        this.primus.removeAllListeners('wb:add-annotation', this.handleAddAnnotation)
        this.primus.removeAllListeners('wb:clear-all', this.handleClearAll)
    }

    public registerPrimusEvents = (): void => {
        this.primus.on('wb:add-annotation', this.handleAddAnnotation)
        this.primus.on('wb:clear-all', this.handleClearAll)
    }

    public setActiveTool = (tool: Tool | null): void => {
        this.activeTool = tool
    }

    public setWhiteBoardAnnotations = (): any => {
        const annotates = this.annotations.map(data => {
            return data.annotation.serialize()
        })

        const mountObj: Board = {
            annotations: annotates,
            index: 0,
            type: 'BLANK',
            mount: null,
            label: 'Whiteboard'
        }
        this.setWhiteBoardAnnotation(mountObj)
    }

    public clearAll = (): void => {
        this.annotations.length = 0
        this.setWhiteBoardAnnotations()
        this.primus.send('wb:clear-all', { board: 0, type: 'BLANK' })
        this.clearCanvas()
    }

    private readonly mouseDown = (e: MouseEvent): void => {
        if (e.button === 2) {
            e.preventDefault()
            return
        }

        const coords = this.getMouseCoordinates(e)

        if (this.activeTool === 'pencil') {
            this.annotation = new Pencil(this.ctx, this.canvas, {})
            this.annotation.extendAnnotation(coords)
            this.canvas.removeEventListener('mousedown', this.mouseDown)
            this.canvas.addEventListener('mousemove', this.mouseMove)
            window.addEventListener('mouseup', this.mouseUp)
        }

        if (this.activeTool === 'eraser') {
            this.annotation = new Eraser(this.ctx, this.canvas, {})
            this.annotation.extendAnnotation(coords)
            this.canvas.removeEventListener('mousedown', this.mouseDown)
            this.canvas.addEventListener('mousemove', this.mouseMove)
            window.addEventListener('mouseup', this.mouseUp)
        }

        if (this.activeTool === 'text') {
            if (this.textBox && this.textArea) {
                if (this.textBoxActive) {
                    const value = this.textArea.value

                    if (value.length > 0) {
                        const annotation = new Text(this.ctx, this.canvas, {
                            text: this.textArea.value.split('\n'),
                            width: this.textBox.style.width,
                            height: this.textBox.style.height,
                            x: this.textBox.offsetLeft,
                            y: this.textBox.offsetTop + 20
                        })
                        this.annotations.push({ annotation })
                        this.setWhiteBoardAnnotations()

                        this.primus.send('wb:add-annotation', { board: 0, annotation: annotation.serialize(), type: 'BLANK' })
                        annotation.render()
                    }

                    this.textBox.style.display = 'none'
                    this.textArea.value = ''
                    this.textBoxActive = false
                } else {
                    this.textBox.style.left = Math.abs(coords.x) + 'px'
                    this.textBox.style.top = Math.abs(coords.y) + 'px'
                    this.textBox.style.display = 'block'
                    this.textBoxActive = true
                }
            }
        }
    }

    private readonly mouseMove = (e: MouseEvent): void => {
        if (e.button === 2) {
            e.preventDefault()
            return
        }

        const coords = this.getMouseCoordinates(e)
        this.annotation?.extendAnnotation(coords)
    }

    private readonly mouseUp = (e: MouseEvent): void => {
        if (e.button === 2) {
            e.preventDefault()
            return
        }

        const coords = this.getMouseCoordinates(e)
        this.annotation?.extendAnnotation(coords)
        this.canvas.addEventListener('mousedown', this.mouseDown)
        this.canvas.removeEventListener('mousemove', this.mouseMove)
        window.removeEventListener('mouseup', this.mouseUp)

        this.annotations.push({ annotation: this.annotation! })
        const eventData = this.annotation!.serialize()
        this.setWhiteBoardAnnotations()

        this.primus.send('wb:add-annotation', { board: 0, annotation: eventData, type: 'BLANK' })
    }

    private readonly handleTextBoxEvents = (): void => {
        window.addEventListener('keydown', e => {
            if (this.textBox && this.textArea) {
                switch (e.key) {
                    case 'Escape':
                        this.textBox.style.display = 'none'
                        this.textArea.value = ''
                }
            }
        })
    }

    public setCurrentSlide = (slideIndex: number): void => {
        this.activeSlide = slideIndex
        this.renderCurrentSlideAnnotations()
    }

    public readonly renderCurrentSlideAnnotations = (): void => {
        this.clearCanvas()
        this.annotations.forEach(a => a.annotation.render())
    }

    private readonly clearCanvas = (): void => {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    }

    private readonly getMouseCoordinates = (e: MouseEvent): CanvasCoordinates => {
        const x = e.offsetX
        const y = e.offsetY
        return { x, y }
    }
}

export { WhiteBoardAnnotationService }
export type { Tool }
