import { createTheme } from '@mui/material/styles'
import { MuiButton } from './button'
import { MuiIconButton } from './icon-button'

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 320,
            sm: 480,
            md: 768,
            lg: 1080,
            xl: 1200
        }
    },
    palette: {
        primary: {
            /**
             * Active video, student speaking
             */
            main: 'rgba(100, 207, 233)', // Brand / C

            /**
             * Active button
             */
            darker: 'rgba(0, 51, 89, 1)'
        },
        secondary: {
            /**
             * Inactive button text
             * Student full name background
             */
            main: 'rgb(89, 89, 89)',

            /**
             * Student initials circle background
             * Waiting for students to join
             */
            darker: 'rgb(166, 166, 166)'
        },
        neutral: {
            /**
             * Active button text
             * Page background
             */
            main: 'rgba(255, 255, 255)', // White

            /**
             * Video stream background
             * Inactive button background
             */
            darker: 'rgba(241, 241, 241)',
            dark: 'rgba(0, 0, 0)', // Black
            hover: 'rgb(220, 220, 220)',

            /**
             * Waiting for students background
             */
            alternate: 'rgb(249, 249, 249)'
        },
        error: {
            /**
             * Leave call button
             */
            main: 'rgba(241, 44, 62)', // Brand / B
            hover: '#CF2635'
        }
    },
    shape: {
        borderRadius: 24
    },
    typography: {
        fontFamily: 'Noto Sans',
        button: {
            textTransform: 'none'
        }
    },
    components: {
        MuiButton,
        MuiIconButton
    }
})

type Theme = typeof theme
export type { Theme }
export { theme }
