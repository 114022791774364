import { Dispatch, SetStateAction } from 'react'
import { styled, ButtonGroup, TextField } from '@mui/material'
import { SpeakButton, SlidesButton, StepButton } from './Buttons'
import { Timer } from 'components/timer'

const Section = styled('div')(({ theme }) => ({
    margin: `${theme.spacing(3)} 0`,
    paddingBottom: theme.spacing(3),
    borderBottom: `solid 1px ${theme.palette.neutral.hover}`,
    h5: {
        fontWeight: '600',
        marginBottom: theme.spacing(1)
    },
    p: {
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(2)
    },
    '&:last-of-type': {
        border: 'none',
        paddingBottom: 0
    }
}))
const StyledTextField = styled(TextField)(({ theme }) => ({
    '> div': {
        borderRadius: 0,
        input: {
            padding: theme.spacing(1)
        }
    }
}))
const StepButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    button: {
        marginLeft: theme.spacing(1)
    }
}))

type SettingsStepProps = {
    roomsCount: number
    handleRoomCountChange: Dispatch<React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>
    duration: number
    handleDurationChange: Dispatch<boolean>
    mode: string
    setMode: Dispatch<SetStateAction<'speak' | 'cue-cards' | 'slides'>>
    setStep: Dispatch<SetStateAction<number>>
}

const SettingsModalStep: React.FC<SettingsStepProps> = ({
    roomsCount,
    handleRoomCountChange,
    duration,
    handleDurationChange,
    mode,
    setMode,
    setStep
}) => (
    <>
        <Section>
            <h5>Rooms</h5>
            <StyledTextField id='bor-modal-rooms-count-input' type='number' value={roomsCount} onChange={e => handleRoomCountChange(e)} />
        </Section>
        <Section>
            <h5>Duration</h5>
            <p>Remember to click &apos;Return to Class&apos; when the time has run out.</p>
            <Timer id='bor-modal-timer' duration={duration} min={2} max={50} onDurationChange={(_, delta) => handleDurationChange(delta > 0)} />
        </Section>
        <Section>
            <h5>Starting Mode</h5>
            <p>You can change teaching mode at any point throughout the Breakout Rooms duration</p>
            <ButtonGroup>
                <SpeakButton id='bor-modal-speak-button' active={mode === 'speak'} onClick={() => setMode('speak')} />
                <SlidesButton id='bor-modal-slides-button' active={mode === 'slides'} onClick={() => setMode('slides')} />
            </ButtonGroup>
        </Section>
        <StepButtonsWrapper>
            <StepButton
                id='bor-modal-next-button'
                text='Next'
                onClick={() => setStep(prev => prev + 1)}
            />
        </StepButtonsWrapper>
    </>
)

export { SettingsModalStep }
