import { styled } from '@mui/system'

const MessageWrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    width: '100%',
    fontWeight: 800,
    fontSize: 25,
    padding: theme.spacing(1),
    color: theme.palette.secondary.darker
}))

export { MessageWrapper }
