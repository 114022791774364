import { ExceptionType, GOCException } from 'exceptions'
import { apiRequest } from '../api-request'
import { AuthInfo } from './AuthInfo'

const qsLogin = async (): Promise<string> => {
    const response = await apiRequest<AuthInfo>({
        path: '/auth/qslogin-token',
        method: 'GET'
    })

    if (response.success) {
        return response.token

    } else {
        throw new GOCException(ExceptionType.BookingCheckError, response.message)
    }
}

export type { AuthInfo }
export { qsLogin }
