import { styled } from '@mui/material'

const InnerWrapper = styled('div')<{ height: number }>(({ theme, height }) => ({
    display: 'flex',
    height: `calc(100vh - ${height}px)`,
    minHeight: theme.spacing(50),
    [theme.breakpoints.down('md')]: {
        minHeight: theme.spacing(40)
    }
}))

export { InnerWrapper }
