import { styled, IconButton, IconButtonProps } from '@mui/material'
import { CloseIcon } from 'icons'

const Wrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    bottom: theme.spacing(11),
    textAlign: 'left',
    paddingRight: 'var(--page-side-margin)',
    border: `solid ${theme.spacing(2)} ${theme.palette.neutral.dark}`,
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(2.25),
    fontWeight: 600,
    width: 'max-content',
    maxWidth: theme.spacing(30),
    color: theme.palette.neutral.main,
    backgroundColor: theme.palette.neutral.dark,
    borderRadius: theme.spacing(1.5),
    whiteSpace: 'pre-wrap',
    span: {
        color: theme.palette.secondary.darker
    },
    p: {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '3',
        marginTop: theme.spacing(1)
    }
}))

type CloseButtonProps = {
    id?: string
    icon?: string
    onClick?: IconButtonProps['onClick']
}
const CloseButton: React.FC<CloseButtonProps> = ({ id, onClick }) => (
    <IconButton
        id={id}
        onClick={onClick}
        sx={theme => ({
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
            height: theme.spacing(3),
            width: theme.spacing(3),
            padding: 0,
            img: {
                width: theme.spacing(3.75),
                height: theme.spacing(3.75),
                maxWidth: 'none'
            }
        })}
    >
        <img src={CloseIcon} />
    </IconButton>
)

type MessageProps = {
    lastMessage: {
        from: string
        text: string
    }
    onMessageClick: () => void
    onCloseClick: (e: React.MouseEvent<HTMLElement>) => void
}
const MessagePopup: React.FC<MessageProps> = ({ lastMessage, onMessageClick, onCloseClick }) => (
    <Wrapper id='message-popup' onClick={onMessageClick}>
        <span>{lastMessage.from}</span>
        <p>{lastMessage.text}</p>
        <CloseButton id='message-popup-close-button' onClick={onCloseClick} />
    </Wrapper>
)

export { MessagePopup }
