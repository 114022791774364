import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ConferenceContext } from 'contexts/conference'
import { StandardButton } from 'components/buttons'

const NavButton: React.FC<{
    id?: string
    path: string
    text: string
    icon: string
    activeIcon: string
    disabled?: boolean
    onClick?: () => void
}> = ({ id, path, text, icon, activeIcon, disabled, onClick }) => {
    const conference = useContext(ConferenceContext)
    const location = useLocation()
    const navigate = useNavigate()
    const active = location.pathname === path && conference.status === 'session-joined'

    const navigateToPage = (): void => {
        navigate(path)
    }

    return (
        <StandardButton
            id={id}
            color={active ? 'primary' : 'secondary'}
            iconSrc={active ? activeIcon : icon}
            onClick={onClick ?? navigateToPage}
            text={text}
            disabled={disabled}
        />
    )
}

export { NavButton }
