import { useEffect, RefObject } from 'react'
import { ASPECT_RATIO } from 'zoom/config'
import { useLayoutUpdate } from 'components/useLayoutUpdate'

type GridProps = {
    width: number
    height: number
    tileCount: number
}
type TileDimensions = {
    height: number
    width: number
}

const useGridLayout = (wrapperRef: RefObject<HTMLDivElement>, tileCount: number): void => {
    const getTileDimensions = (gridProps: GridProps): TileDimensions | null => {
        const { width: wrapperWidth, height: wrapperHeight, tileCount } = gridProps
        const wrapperRatio = wrapperWidth / wrapperHeight
        const initialTileHeight = 100
        const initialTileWidth = initialTileHeight * ASPECT_RATIO

        let grid: { rowCount: number; colCount: number; ratio: number } | undefined
        for (let rowCount = 1; rowCount <= tileCount; rowCount++) {
            const colCount = Math.ceil(tileCount / rowCount)
            const ratio = (initialTileWidth * colCount) / (initialTileHeight * rowCount)
            if (!grid || Math.abs(ratio - wrapperRatio) < Math.abs(grid.ratio - wrapperRatio)) {
                grid = { rowCount, colCount, ratio }
            }
        }
        const tile: TileDimensions = { height: 0, width: 0 }
        if (grid) {
            if (grid.ratio < wrapperRatio) {
                const tileHeight = Math.max(Math.round((wrapperHeight - grid.rowCount) / grid.rowCount), (110 / ASPECT_RATIO))
                tile.height = tileHeight
                tile.width = Math.round(tileHeight * ASPECT_RATIO)
            } else {
                const tileWidth = Math.max(Math.round((wrapperWidth - grid.colCount) / grid.colCount), 110)
                tile.width = tileWidth
                tile.height = Math.round(tileWidth / ASPECT_RATIO)
            }
        }
        return tile
    }
    const updateWrapper = (wrapper: HTMLDivElement): void => {
        const { width, height } = wrapper.getBoundingClientRect()
        const tileDimensions = getTileDimensions({ width, height, tileCount })
        const mapBetween = (input: number, minOutput: number, maxOutput: number, minRange: number, maxRange: number): number => {
            const unclampedOutput = Math.round(((maxOutput - minOutput) * (input - minRange)) / (maxRange - minRange) + minOutput)
            return Math.min(Math.max(unclampedOutput, minOutput), maxOutput)
        }

        if (tileDimensions) {
            wrapper.style.setProperty('--tile-width', tileDimensions.width + 'px')
            wrapper.style.setProperty('--tile-height', tileDimensions.height + 'px')
            wrapper.style.setProperty('--tile-fontsize', mapBetween(tileDimensions.height, 16, 64, 100, 500) + 'px')
        }
    }

    const { triggerLayoutUpdate, addLayoutUpdateInProgressListener, removeLayoutUpdateInProgressListener } = useLayoutUpdate()
    useEffect(() => {
        const wrapper = wrapperRef.current
        if (wrapper && tileCount > 0) {
            const resObs = new ResizeObserver(triggerLayoutUpdate)
            resObs.observe(wrapper)
            updateWrapper(wrapper)

            const update = (): void => {
                updateWrapper(wrapper)
            }
            addLayoutUpdateInProgressListener(update)
            return () => removeLayoutUpdateInProgressListener(update)
        }
    }, [wrapperRef.current, tileCount])
}

export { useGridLayout }
