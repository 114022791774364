import { useEffect, useContext, useState } from 'react'
import { styled } from '@mui/material'
import { ZoomLayout } from 'components/participants'
import { CueCards } from './CueCards'
import { WaitingForOthers } from 'components/status-panels'
import { ConferenceContext } from 'contexts/conference'
import { BookingContext } from 'contexts/booking'
import { EyeIcon } from 'icons'

type CueCard = {
    contentId: string
    imageURL: string
}

type Student = {
    id: string
    cueCard: CueCard | null
}

type StudentsCueCardData = Student[] | false

const Wrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}))
const ParticipantsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '30%',
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    '> div': {
        width: '100%'
    }
}))
const BottomLeftSpanView = styled('span')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    borderRadius: theme.spacing(0.5),
    gap: theme.spacing(0.75),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.neutral.main,
    alignItems: 'center',
    fontSize: theme.spacing(1.2),
    lineHeight: theme.spacing(1.2),
    '> img': {
        width: theme.spacing(2),
        height: theme.spacing(2)
    }
}))

const BottomMessageWrapper = styled('div')(() => ({
    position: 'absolute',
    left: '28.5%',
    width: '100%',
    marginTop: '2px'
}))

const CueCardView: React.FC = () => {
    const conference = useContext(ConferenceContext)
    const booking = useContext(BookingContext)
    const cueCard = conference.status === 'session-joined' && conference.cueCard
    const [cueCardImage, setCueCardImage] = useState<string>()

    useEffect(() => {
        if (conference.status === 'session-joined' && booking.status === 'validated' && cueCard !== null) {
            const studentCueCardImage = getCueCardImage(booking.user.id, cueCard)
            setCueCardImage(studentCueCardImage)
        }
    })

    // function to get the cuecard Image url for the corresponding student
    const getCueCardImage = (participantId: string, cueCardData: StudentsCueCardData): string => {
        const cueCardImage = cueCardData ? cueCardData.find((data: { id: string }) => data.id === participantId) : null
        return cueCardImage?.cueCard?.imageURL ?? ''
    }

    return (
        <>
            <Wrapper>
                {cueCardImage
                    ? (
                        <>
                            <ParticipantsWrapper data-testid='student-cueCard-participants'>
                                <WaitingForOthers />
                                <ZoomLayout />
                            </ParticipantsWrapper>
                            <CueCards cueCardImage={cueCardImage} />
                        </>
                    ) : (
                        <>
                            <WaitingForOthers />
                            <ZoomLayout />
                        </>
                    )}
            </Wrapper>
            {cueCardImage &&
                <BottomMessageWrapper>
                    <BottomLeftSpanView>
                        <img src={EyeIcon} alt='Showing Eye Icon' />
                        Only visible to you
                    </BottomLeftSpanView>
                </BottomMessageWrapper>
            }
        </>
    )
}

export { CueCardView }
