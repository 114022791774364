import { ClassroomId } from 'api/types'
import { ExceptionType, GOCException } from 'exceptions'
import { apiRequest } from '../api-request'
import { ClassroomState } from './ClassroomState'

const getClassroomState = async (auth: string, classroomId: ClassroomId): Promise<ClassroomState> => {
    const response = await apiRequest<{ data: ClassroomState | null; msg: string }>({
        path: `/activity/${classroomId}?timestamp=${Date.now()}`,
        method: 'GET',
        auth
    })

    if (response.data) {
        return response.data
    } else {
        throw new GOCException(ExceptionType.ClassroomStateError, response.msg)
    }
}

export type { ClassroomState }
export { getClassroomState }
