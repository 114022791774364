import { styled } from '@mui/material'
import { getStudentClassResults } from 'api/unit-assessment'
import { BookingContext } from 'contexts/booking'
import { ConferenceContext } from 'contexts/conference'
import { useContext, useEffect, useState } from 'react'
import { StudentClassResults } from 'api/unit-assessment/class-results'
import { classResultDefaultValues } from './SummaryPanel'

const PanelContainer = styled('div')(({ theme }) => ({
    background: 'white',
    borderRadius: '8px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
}))

const Header = styled('div')(({ theme }) => ({
    fontSize: '25px',
    color: '#000',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '15px'

}))

const StyledLine = styled('div')(({ theme }) => ({
    display: 'block',
    height: '1px',
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    padding: '0'
}))

const ContentConatiner = styled('div')(({ theme }) => ({
    padding: '16px',
    height: 'calc(100% - 110px)',
    overflowY: 'scroll',
    overflowX: 'hidden'
}))

const Footer = styled('div')(({ theme }) => ({
    backgroundColor: '#003359',
    textAlign: 'center',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    position: 'absolute',
    bottom: '0',
    width: '100%'
}))

const FooterContent = styled('div')(({ theme }) => ({
    color: '#ffffff',
    display: 'flex',
    padding: '15px',
    fontSize: '18px',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    'span ': {
        fontSize: '20px'
    }
}))

const ActivityPanel = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '30px 2fr auto',
    fontSize: '16px',
    marginBottom: '5px'
}))

const ActivityNumber = styled('div')(({ theme }) => ({
    fontWeight: 'bold'
}))

const ActivityContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexBasis: 'fit-content'
}))

const ActivityRating = styled('div')<{ result: number }>(({ theme, result }) => ({
    'div ': {
        display: 'flex',
        border: 'solid 1px white',
        borderRadius: '24px',
        background: result === 1 ? '#f9b3bd' : result === 2 ? '#ffeadd' : result === 3 ? '#a7d692' : '#6cbe3e',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    border: 'solid 1px rgba(0, 0, 0, 0.14)',
    width: '90px',
    height: '32px',
    borderRadius: '24px',
    alignItems: 'center'
}))

const ClassResults: React.FC<{ type: string | null }> = ({ type }) => {

    const conference = useContext(ConferenceContext)
    const booking = useContext(BookingContext)
    const [classResultsResponse, setClassResultsResponse] = useState<StudentClassResults | null>(null)
    const [classResult, setClassResult] = useState<string | undefined>('-')
    const isClassResultsViewed = conference.status === 'session-joined' && conference.isClassResultsViewed

    useEffect(() => {
        if (conference.status === 'session-joined' && booking.status === 'validated' && type === 'class results' && isClassResultsViewed !== null) {
            conference.setClassResults(true)
            void getStudentClassResults(booking.auth, booking.classroomId, booking.user.id).then(data => {
                setClassResultsResponse(data)
                const classResultValue = classResultDefaultValues.find(classResultType => {
                    return classResultType.id === data?.results[0].contentItemResultTypeId
                })?.label
                setClassResult(classResultValue)
            })
        }
    }, [conference.status, type, isClassResultsViewed])

    return (
        <PanelContainer>
            <Header>Activities</Header>
            <StyledLine />
            <ContentConatiner>
                {classResultsResponse?.partialResults.map((s, index) => (
                    <ActivityPanel key={index}>
                        <ActivityNumber>{s.name.split(' ')[0]}</ActivityNumber>
                        <ActivityContent>
                            {s.name.split(' ').slice(1).join(' ')}
                        </ActivityContent>
                        <ActivityRating result={s.result}>
                            <div>{s.result}</div>
                        </ActivityRating>
                    </ActivityPanel>
                ))}
            </ContentConatiner>
            <Footer>
                <FooterContent>
                    <div>Score:
                        <span>
                            {(classResultsResponse?.results[0].score !== null && classResultsResponse?.results[0].score !== undefined)
                                ? ` ${classResultsResponse?.results[0].score}%` : ' -'}
                        </span>
                    </div>
                    <div>Class result: <span>{classResult}</span></div>
                </FooterContent>
            </Footer>
        </PanelContainer>
    )
}
export { ClassResults }
