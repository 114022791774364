import { CommonButtonProps, StandardButton } from './StandardButton'
import { EndIcon } from 'icons'

const EndCallButton: React.FC<Omit<CommonButtonProps, 'active'> & { text: string }> = ({ id, disabled, onClick, text }) => (
    <StandardButton
        id={id}
        color='error'
        iconSrc={EndIcon}
        disabled={disabled}
        onClick={onClick}
        text={text}
    />
)

export { EndCallButton }
