import { styled } from '@mui/material'

const Wrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    zIndex: 100,
    inset: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)'
}))
const InnerWrapper = styled('div')(({ theme }) => ({
    width: '90%',
    maxWidth: theme.spacing(70),
    maxHeight: '90%',
    overflow: 'auto',
    position: 'relative',
    backgroundColor: theme.palette.neutral.main,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
    h4: {
        fontWeight: '800',
        fontSize: theme.spacing(4.5),
        color: theme.palette.primary.darker,
        marginBottom: theme.spacing(4)
    }
}))
const CloseButtonWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3)
}))

export { Wrapper, InnerWrapper, CloseButtonWrapper }
