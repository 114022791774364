import { useContext, useEffect } from 'react'
import { ConferenceContext } from 'contexts/conference'

const useTabClose = (): void => {
    const conference = useContext(ConferenceContext)

    useEffect(() => {
        const onBeforeUnload = (): void => {
            if (conference.status === 'session-joined') {
                void conference.rtcSession.leave()
            }
        }
        window.addEventListener('beforeunload', onBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload)
        }
    })
}

export { useTabClose }
