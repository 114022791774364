import { styled } from '@mui/material'
import { BookingContext } from 'contexts/booking'
import { ConferenceContext } from 'contexts/conference'
import { FC, useContext } from 'react'
import { getDisplayName, getSameOriginPhotoUrl } from 'utils/get-display-name'

const Picture = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: theme.spacing(3),
    fontWeight: 800,
    height: '50%',
    maxHeight: theme.spacing(20),
    aspectRatio: '1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.35)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.neutral.main
}))

export const Avatar: FC<{ showVideo: boolean }> = ({ showVideo }) => {
    const conference = useContext(ConferenceContext)
    const booking = useContext(BookingContext)

    const showProfilePicture = booking.status === 'validated' && (conference.status !== 'session-joined' || conference.isVideoMuted) && booking.user.photoUri
    const username = booking.status === 'validated' ? getDisplayName(booking.user, 'initials') : ''

    if (showVideo) {
        return null

    } else if (showProfilePicture) {
        return (
            <Picture id='selfie-avatar' sx={{ backgroundImage: `url('${getSameOriginPhotoUrl(booking.user.photoUri)}')` }} />
        )

    } else {
        return (
            <Picture
                id='selfie-avatar'
                sx={{ backgroundImage: 'none' }}>
                <span id='participant-initials'>{username ?? ''}</span>
            </Picture>
        )
    }
}
