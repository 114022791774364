import { useContext, useEffect, useState } from 'react'
import { BookingContext } from 'contexts/booking'
import { ClassroomId, WseUserId } from 'api/types'

const appUrl = location.href.replace(/#.*/, '').replace(/\/$/, '')
const callbackUrl = encodeURIComponent(process.env.REACT_APP_CORE_COURSE_WEB_URL!)

const getAutologinHashUrl = (userId: WseUserId, classroomId: ClassroomId, auth: string): string => (
    `${appUrl}#/autologin/${classroomId}/${userId}/${auth}/${callbackUrl}`
)

const getAutologinQueryUrl = (userId: WseUserId, classroomId: ClassroomId, auth: string): string => (
    `${appUrl}/autologin?activityId=${classroomId}&userId=${userId}&Authorization=${auth}&callbackUrl=${callbackUrl}`
)

type QuickSlotLink = {
    clipboardItem: ClipboardItem
    user: WseUserId
    name: string
    url: string
}

const getAllLinksClipboardItem = (links: QuickSlotLink[]): ClipboardItem => {
    return new ClipboardItem({
        'text/html': new Blob(
            links.map(
                link => `<a href="${link.url}">${link.name}</a> `
            ),
            { type: 'text/html' }
        ),
        'text/plain': new Blob(
            links.map(
                link => `${link.name} ${link.url}\n`
            ),
            { type: 'text/plain' }
        )
    })
}

const getLinkClipboardItem = (url: string, name: string): ClipboardItem => {
    return new ClipboardItem({
        'text/html': new Blob([`<a href="${url}">${name}</a>`], { type: 'text/html' }),
        'text/plain': new Blob([`${name} ${url}`], { type: 'text/plain' })
    })
}

const useQuickslot = (): [QuickSlotLink[], ClipboardItem?] => {
    const [quickslots, setQuickslots] = useState<[QuickSlotLink[], ClipboardItem?]>([[], void 0])
    const booking = useContext(BookingContext)

    useEffect(() => {
        if (booking.status === 'validated') {
            const links = ['UTEACHER', 'USTUDENT1', 'USTUDENT2', 'USTUDENT3', 'USTUDENT4', 'USTUDENT5', 'USTUDENT6', 'USTUDENT7', 'USTUDENT8'].map(userId => {
                const name = userId.substring(1, 2) + userId.substring(2).toLowerCase()
                const url = location.hostname === '.wallstreetenglish.com'
                    ? getAutologinQueryUrl(userId, booking.classroomId, booking.auth)
                    : getAutologinHashUrl(userId, booking.classroomId, booking.auth)

                return {
                    clipboardItem: getLinkClipboardItem(url, name),
                    user: userId,
                    name,
                    url
                }
            })

            setQuickslots([links, getAllLinksClipboardItem(links)])

        } else {
            setQuickslots([[], void 0])
        }
    }, [booking.status])

    return quickslots
}

export type { QuickSlotLink }
export { useQuickslot }
