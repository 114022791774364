import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useNavigateBackDisabled = (): void => {
    const location = useLocation()

    useEffect(() => {
        window.history.pushState(null, '', window.location.hash)
        window.history.replaceState(null, '', window.location.hash)
    }, [location.pathname])

    useEffect(() => {
        const handlePopstate = (): void => {
            window.history.scrollRestoration = 'manual'
            window.history.pushState(null, '', window.location.hash)
            window.history.replaceState(null, '', window.location.hash)
        }
        window.addEventListener('popstate', handlePopstate)

        return () => {
            window.removeEventListener('popstate', handlePopstate)
        }
    }, [])
}

export { useNavigateBackDisabled }
