import { Dispatch, SetStateAction, useContext } from 'react'
import { styled, Button, Menu, MenuItem } from '@mui/material'
import PopupState, { bindMenu, bindTrigger, InjectedProps } from 'material-ui-popup-state'
import { BookingContext } from 'contexts/booking'
import { ButtonLabelWrapper } from './buttons'
import { SidebarProps } from './sidebar'
import { ChevronDownIcon, ClassResultIcon } from 'icons'
import { ConferenceContext } from 'contexts/conference'
import { getClassResultsViewed } from 'api/unit-assessment/class-results'

const StyledButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        height: theme.spacing(5),
        padding: theme.spacing(1)
    }
}))
type SettingsProps = {
    currentSidebar?: SidebarProps
    setCurrentSidebar: Dispatch<SetStateAction<SidebarProps>>
    isStudent?: boolean
}

const NotifyDot = styled('span')(() => ({
    width: '15px',
    height: '15px',
    backgroundColor: '#f12c3e',
    border: '3px solid #ffffff',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    borderRadius: '50%',
    position: 'absolute',
    top: '-5px',
    right: '5px'
}))

const SettingsMenu: React.FC<SettingsProps> = ({ currentSidebar, setCurrentSidebar, isStudent }) => {
    const booking = useContext(BookingContext)
    const username = booking.status === 'validated' ? booking.user.firstName : ''
    const conference = useContext(ConferenceContext)
    const sessionJoined = conference.status === 'session-joined'
    const isClassResultsViewed = sessionJoined && !conference.isClassResultsViewed
    const noClassResults = sessionJoined && conference.isClassResultsViewed === null

    const toggleSidebar = (currentState: SidebarProps, popupState: InjectedProps): void => {
        popupState.close()
        setCurrentSidebar(currentState)
        if (conference.status === 'session-joined' && booking.status === 'validated' && conference.isClassResultsViewed !== null) {
            void getClassResultsViewed(booking.auth, booking.classroomId, booking.user.id).catch(() => console.log('error'))
        }
    }

    return (
        <PopupState variant='popover' popupId='demo-popup-menu'>
            {popupState => (
                <>
                    <StyledButton id='account-menu' variant='contained' color='secondary' {...bindTrigger(popupState)} disabled={!sessionJoined}>
                        <ButtonLabelWrapper>{username}</ButtonLabelWrapper>
                        {isStudent && !noClassResults && (
                            <>
                                <img src={ChevronDownIcon} />
                                {isClassResultsViewed && <NotifyDot />}
                            </>
                        )}
                    </StyledButton>
                    <Menu {...bindMenu(popupState)} sx={{ display: (isStudent && !noClassResults && popupState.isOpen) ? 'block' : 'none' }}>
                        <MenuItem onClick={() => toggleSidebar({ type: 'class results' }, popupState)}>
                            <img src={ClassResultIcon} />
                            Class Result
                        </MenuItem>
                    </Menu>
                </>
            )}
        </PopupState>
    )
}

export { SettingsMenu }
