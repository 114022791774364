import { createContext } from 'react'
import { ConferenceContextState } from './types'

const initialState: ConferenceContextState = {
    status: 'waiting-for-classroom-info',
    speakers: new Set(),
    participants: [],
    canvasSize: null,
    setCanvas: () => void 0,
    setSlideAnnotation: () => void 0,
    setWhiteBoardAnnotation: () => void 0,
    setClassResults: () => void 0,
    timeLeft: 0,
    participantHardMuteStatus: [],
    setCueCardInfo: () => void 0,
    setCueCardForStudent: () => void 0,
    studentsAwayStatus: [],
    setStudentAwayStatus: () => void 0,
    viewRetryErrorSnackbar: false,
    setRetryError: () => void 0
}

const ConferenceContext = createContext<ConferenceContextState>(initialState)
export { ConferenceContext, initialState }
