import { ClassResults } from 'api/unit-assessment/results'
import { Guid } from 'api/unit-assessment/types'
import { StudentWithScores } from '.'

export const restoreRatings = (results: ClassResults, students: StudentWithScores[]): void => {
    const studentsById = new Map<Guid, StudentWithScores>()
    for (const student of students) {
        studentsById.set(student.userId, student)
    }

    for (const rating of results.partialResults) {
        const student = studentsById.get(rating.studentId)
        if (student) {
            for (const score of rating.itemResultsViewModel) {
                student.scores.set(score.ratingSchemaPartItemId, score.result)
            }
        }
    }

    for (const rating of results.results) {
        const student = studentsById.get(rating.studentId)
        if (student) {
            student.finalScore = rating.score ?? void 0
            student.comment = rating.result ?? ''
            student.contentItemResultType = rating.contentItemResultTypeId ?? undefined
            student.isScoreAndResultAvailableInAPI = rating.score !== null && rating.contentItemResultTypeId !== null
            student.isNoShowTechResultsSelected = rating.contentItemResultTypeId !== null && rating.contentItemResultTypeId > 2
            student.isActivitiesCommentsDisabled = student.isNoShowTechResultsSelected && [...student.scores.values()].length === 0
        }
    }
}
