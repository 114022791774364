import { ClassroomId } from 'api/types'
import { apiRequest } from '../api-request'
import { mock } from './mocks/class'
import { Guid, UrlString } from './types'

export interface Student {
    firstName: string
    lastName: string
    userId: Guid
    photoUris: {
        small?: UrlString
        medium?: UrlString
        big?: UrlString
    }
}

export interface ClassBooking {
    student: Student
    currentUnitNumber: string
}

export interface ClassDetails {
    bookedStudents: ClassBooking[]
    classId: Guid
    teacherId: Guid
    teacherSsdsId: Guid
    isClosed: boolean
}

const getClassDetails = async (auth: string, activityId: ClassroomId): Promise<ClassDetails> => {
    if (!process.env.REACT_APP_USE_ASSESSMENT_MOCKS) {
        return await apiRequest<ClassDetails>({
            baseUrl: process.env.REACT_APP_BS_API_URL,
            path: `/classes/${process.env.REACT_APP_ACTIVITY_ID ?? activityId}/details`,
            method: 'GET',
            auth: process.env.REACT_APP_QUALWORLD_AUTH ?? auth
        })

    } else {
        return mock
    }
}

export { getClassDetails }
