import { IPlayer, IPlaybackController } from 'ispring'
import { ISlides } from './IPlayer'

type StepIndexEventHandler = (slideIndex: number, stepIndex: number) => void
type RemoveHandlerFn = () => void
type ActiveSlide = {
    index: number
    step: number
}

class SlideDeckNavigationController {
    private readonly pbController: IPlaybackController
    private readonly slides: ISlides

    public activeSlide: ActiveSlide = { index: 0, step: 0 }

    constructor (player: IPlayer) {
        this.pbController = player.view().playbackController()
        this.slides = player.presentation().slides()
    }

    getCurrentSlideIndex = (): number => this.pbController.currentSlideIndex()

    gotoPreviousStep = (): void => {
        if (this.activeSlide.index > 0 || this.activeSlide.step > 0) {
            if (this.activeSlide.step === 0) {
                const prevSlide = this.slides.getSlide(this.activeSlide.index - 1)
                const prevSlideStepsCount = prevSlide.animationSteps().count()

                this.updateBoardState(this.activeSlide.index - 1, prevSlideStepsCount - 1)

            } else {
                this.updateBoardState(this.activeSlide.index, this.activeSlide.step - 1)
            }

            this.pbController.gotoPreviousStep(true)
        }
    }

    gotoNextStep = (): void => {
        const currentSlideStepsCount = this.pbController.currentSlide().animationSteps().count()
        const lastSlideIndex = this.pbController.lastSlideIndex()

        if (this.activeSlide.index < lastSlideIndex || this.activeSlide.step < currentSlideStepsCount - 2) {
            if (this.activeSlide.step < currentSlideStepsCount - 2) {
                this.updateBoardState(this.activeSlide.index, this.activeSlide.step + 1)

            } else {
                this.updateBoardState(this.activeSlide.index + 1, 0)
            }

            this.pbController.gotoNextStep(true)
        }
    }

    gotoTimestamp = (slideIndex: number, stepIndex: number): void => {
        this.pbController.gotoTimestamp(slideIndex, stepIndex, 0, true)
        this.updateBoardState(slideIndex, stepIndex)
    }

    getSlideNotes = (slideIndex: number): string => {
        const activeSlide = this.slides.getSlide(slideIndex)
        return activeSlide.slideNotes().html()
    }

    addStepEventHandler = (handler: StepIndexEventHandler): RemoveHandlerFn => {
        const eventDispatcher = this.pbController.stepChangeEvent()

        const cb = (stepIndex: number): void => {
            const slideIndex = this.getCurrentSlideIndex()
            if (stepIndex >= 0) {
                this.updateBoardState(slideIndex, stepIndex)
                handler(slideIndex, stepIndex)

            } else if (slideIndex > 0) { // stepIndex is negative and slideIndex is not zero
                const slide = this.slides.getSlide(slideIndex - 1)
                const stepsCount = slide.animationSteps().count()
                this.updateBoardState(slideIndex - 1, stepsCount + stepIndex)
                handler(slideIndex - 1, stepsCount + stepIndex)

            } else {
                // slide index 0 and negative step index
            }
        }

        eventDispatcher.addHandler(cb)
        return () => eventDispatcher.removeHandler(cb)
    }

    private readonly updateBoardState = (slideIndex: number, stepIndex: number): void => {
        this.activeSlide.index = slideIndex
        this.activeSlide.step = stepIndex
    }
}

export { SlideDeckNavigationController }
export type { ActiveSlide }
