import { styled, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { RectangleButton } from 'components/buttons'
import URLImage from './safari-url-image.png'
import SettingsPopup from './safari-settings-popup.png'
import CameraPermissionPopup from './safari-camera-permission-popup.png'
import MicPermissionPopup from './safari-mic-permission-popup.png'
import RefreshImage from './safari-refresh-image.png'

const DialogWrapper = styled(Dialog)(({ theme }) => ({
    '& .MuiBackdrop-root': {
        background: 'rgba(0, 0, 0, 0.35)'
    },
    '& .MuiPaper-root': {
        borderRadius: 12,
        boxShadow: '0px 1px 3px rgba(0, 0, 0,0.02), 0px 7px 20px rgba(0, 0, 0, 0.06)',
        width: '822px'
    },
    '& .MuiDialogTitle-root': {
        margin: 0,
        padding: theme.spacing(5),
        fontWeight: 800,
        fontSize: 36,
        lineHeight: '106%',
        color: theme.palette.primary.darker,
        letterSpacing: '-0.01em',
        paddingBottom: '32px'
    },
    '& .MuiDialogContent-root': {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingBottom: 0,
        fontSize: 18,
        lineHeight: '130%',
        letterSpacing: '0.02em',
        color: theme.palette.neutral.dark,
        display: 'flex',
        flexDirection: 'column'
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4)
    }
}))

const Message = styled('p')(({ theme }) => ({
    marginBottom: '12px'
}))

const ImageMessage = styled('img')(({ theme }) => ({
    marginBottom: '24px'
}))

type IOSPermissionMessageDialogProps = {
    type: 'microphone' | 'camera'
    isOpen: boolean
    toggleIOSPermissionMessageDialog?: () => void
}

const IOSPermissionMessageDialog: React.FC<IOSPermissionMessageDialogProps> = ({ type, isOpen, toggleIOSPermissionMessageDialog }) => (
    <DialogWrapper open={isOpen} maxWidth='sm' fullWidth>
        <DialogTitle>
            {`Allow access to your ${type}`}
        </DialogTitle>
        <DialogContent>
            <p style={{ marginBottom: '20px' }}>
                {`Your browser needs access to your ${type} so that Teachers and other Students can hear you.`}
            </p>
            <Message>1. Tap <span style={{ fontWeight: 'bold' }}>AA</span>. at the bottom address bar</Message>
            <ImageMessage src={URLImage} />
            <Message>2. Tap <span style={{ fontWeight: 'bold' }}>Website Settings</span></Message>
            <ImageMessage src={SettingsPopup} />
            { type === 'microphone'
                ? <Message>
                    3. Tap <span style={{ fontWeight: 'bold' }}>Microphone</span> and choose <span style={{ fontWeight: 'bold' }}>Allow.</span>
                </Message>
                : <Message>
                3. Tap <span style={{ fontWeight: 'bold' }}>Camera</span> and choose <span style={{ fontWeight: 'bold' }}>Allow.</span>
                </Message>
            }
            <ImageMessage src={type === 'microphone' ? MicPermissionPopup : CameraPermissionPopup} />
            <Message>4. Tap <span style={{ fontWeight: 'bold' }}>Refresh</span> button to enter the class.</Message>
            <ImageMessage src={RefreshImage} />
        </DialogContent>
        <DialogActions>
            <RectangleButton
                onClick={toggleIOSPermissionMessageDialog}
                text='OK'
                color='error'
            />
        </DialogActions>
    </DialogWrapper>
)

export { IOSPermissionMessageDialog }
