import { styled, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material'
import { RectangleButton } from 'components/buttons'
import { DismissIcon } from 'icons'
import { useLeaveLesson } from 'hooks/useLeaveLesson'

const LeaveLessonButton: React.FC<{
    id?: string
    path: string
    isTeacher: boolean
}> = ({ id, path, isTeacher }) => {
    const { onLeaveHandler } = useLeaveLesson()

    const onClickHandler = (): void => {
        onLeaveHandler(isTeacher, path)
    }

    return (
        <RectangleButton
            id={id}
            onClick={onClickHandler}
            text={isTeacher ? 'End Class' : 'Leave Lesson'}
            color='error'
        />
    )
}

const DialogClose = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 32,
    top: 32,
    padding: 0,
    color: '#0A0B11',
    height: theme.spacing(3),
    width: theme.spacing(3),
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'transparent'
    }
}))

const DialogWrapper = styled(Dialog)(({ theme }) => ({
    '& .MuiBackdrop-root': {
        background: 'rgba(0, 0, 0, 0.35)'
    },
    '& .MuiPaper-root': {
        borderRadius: 12,
        boxShadow: '0px 1px 3px rgba(0, 0, 0,0.02), 0px 7px 20px rgba(0, 0, 0, 0.06)'
    },
    '& .MuiDialogTitle-root': {
        margin: 0,
        padding: theme.spacing(5),
        fontWeight: 800,
        fontSize: 36,
        lineHeight: '106%',
        color: theme.palette.primary.darker,
        letterSpacing: '-0.01em'
    },
    '& .MuiDialogContent-root': {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingBottom: 0,
        fontSize: 18,
        lineHeight: '130%',
        letterSpacing: '0.02em',
        color: theme.palette.neutral.dark,
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4)
    }
}))

type LeaveDialogProps = {
    isOpen: boolean
    toggleLeaveDialog?: () => void
    isTeacher?: boolean
}

const LeaveDialog: React.FC<LeaveDialogProps> = ({ isOpen, toggleLeaveDialog, isTeacher = false }) => (
    <DialogWrapper open={isOpen} maxWidth='sm' fullWidth>
        <DialogTitle>
            {isTeacher ? 'End class for all' : 'Leave Lesson'}
            <DialogClose aria-label='close' onClick={toggleLeaveDialog}>
                <img src={DismissIcon} />
            </DialogClose>
        </DialogTitle>
        <DialogContent>
            {isTeacher ? 'Are you sure you want to end the class? You and the students will NOT be able to rejoin the class.'
                : 'Are you sure you want to leave the lesson?'}
        </DialogContent>
        <DialogActions>
            <LeaveLessonButton path='/thanks' isTeacher={isTeacher} />
        </DialogActions>
    </DialogWrapper>
)

export { LeaveDialog, LeaveLessonButton, DialogClose, DialogWrapper }
