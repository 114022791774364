import { useContext } from 'react'
import { ConferenceContext } from 'contexts/conference'
import { useNavigate } from 'react-router-dom'

interface LeaveHandlerParams {
    (isTeacher: boolean, path: string): void
}

const useLeaveLesson = (): { onLeaveHandler: LeaveHandlerParams } => {
    const conference = useContext(ConferenceContext)
    const navigate = useNavigate()

    const onLeaveHandler: LeaveHandlerParams = (isTeacher, path) => {
        if (conference.status === 'session-joined') {
            if (isTeacher) {
                void conference.rtcSession.end()
                conference.primus.send('end-session', {})
            } else {
                void conference.rtcSession.leave()
            }
        }
        navigate(path)
    }

    return { onLeaveHandler }
}

export { useLeaveLesson }
