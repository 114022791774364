import { styled, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { RectangleButton } from 'components/buttons'
import MicAccess from './permission-window-popup.svg'

const DialogWrapper = styled(Dialog)(({ theme }) => ({
    '& .MuiBackdrop-root': {
        background: 'rgba(0, 0, 0, 0.35)'
    },
    '& .MuiPaper-root': {
        borderRadius: 12,
        boxShadow: '0px 1px 3px rgba(0, 0, 0,0.02), 0px 7px 20px rgba(0, 0, 0, 0.06)',
        width: '822px'
    },
    '& .MuiDialogTitle-root': {
        margin: 0,
        padding: theme.spacing(5),
        fontWeight: 800,
        fontSize: 36,
        lineHeight: '106%',
        color: theme.palette.primary.darker,
        letterSpacing: '-0.01em',
        paddingBottom: '32px'
    },
    '& .MuiDialogContent-root': {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingBottom: 0,
        fontSize: 18,
        lineHeight: '130%',
        letterSpacing: '0.02em',
        color: theme.palette.neutral.dark,
        display: 'flex',
        flexDirection: 'column',
        ustifyContent: 'center',
        alignItems: 'center'
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4)
    }
}))

type MessageDialogProps = {
    type: 'microphone' | 'camera'
    isOpen: boolean
    toggleMessageDialog?: () => void
}

const MessageDialog: React.FC<MessageDialogProps> = ({ type, isOpen, toggleMessageDialog }) => (
    <DialogWrapper open={isOpen} maxWidth='md' fullWidth>
        <DialogTitle>
            {`Allow access to your ${type}`}
        </DialogTitle>
        <DialogContent>
            <div style={{ marginBottom: '32px' }}>
                {`Please click the lock icon in the 
                navigation bar to give access to your ${(type === 'microphone' ? 'audio' : 'video')} and refresh the page.`}
            </div>
            <img src={MicAccess} />
        </DialogContent>
        <DialogActions>
            <RectangleButton
                onClick={toggleMessageDialog}
                text='OK'
                color='error'
            />
        </DialogActions>
    </DialogWrapper>
)

export { MessageDialog }
