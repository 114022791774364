import { useContext, useEffect } from 'react'
import { ConferenceContext } from 'contexts/conference'
import { WaitingForOthers } from 'components/status-panels'
import { ZoomLayout, BorZoomLayout } from 'components/participants'
import { BreakoutRoomsState } from 'pages/useBreakouts'

const SpeakView: React.FC<{ breakouts?: BreakoutRoomsState }> = ({ breakouts }) => {
    const conference = useContext(ConferenceContext)

    useEffect(() => {
        if (conference.status === 'session-joined') {
            conference.primus.send('change-screen', { screen: 'avchat' })
        }
    }, [])

    return (
        <>
            <WaitingForOthers breakouts={breakouts} />
            {breakouts?.isActive ? (
                <BorZoomLayout breakouts={breakouts} />
            ) : (
                <ZoomLayout breakouts={breakouts} />
            )}
        </>
    )
}

export { SpeakView }
