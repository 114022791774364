import { useState, Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/material'
import { Chat } from 'components/chat'
import { Assessment } from 'components/assessment'
import { Notes } from 'components/notes/notes'
import { CloseButton, BackButton } from './Buttons'
import { ConversationType } from 'chat'
import { ClassResults } from 'components/assessment/ClassResults'

type SidebarTypes = 'chat' | 'notes' | 'assessment' | 'class results' | null
type SidebarProps = {
    type: SidebarTypes
    conversation?: ConversationType
}
type SidebarComponentProps = {
    currentSidebar: SidebarProps
    setCurrentSidebar: Dispatch<SetStateAction<SidebarProps>>
}

const Wrapper = styled('div')<{ isVisible: boolean }>(({ theme, isVisible }) => ({
    display: isVisible ? 'flex' : 'none',
    flexDirection: 'column',
    width: theme.spacing(50),
    backgroundColor: theme.palette.neutral.alternate,
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
    padding: theme.spacing(3),
    marginLeft: theme.spacing(2),
    height: 'calc(100vh - 172px)',
    minHeight: theme.spacing(50),
    [theme.breakpoints.down('md')]: {
        height: '100%',
        minHeight: theme.spacing(40)
    },
    '@media (max-height: 684px)': {
        [theme.breakpoints.up('md')]: {
            height: '514px'
        }
    }
}))
const TopBar = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 'var(--page-v-margin)'
}))
const TitleWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
}))
const Title = styled('h3')<{ smallFontSize: boolean }>(({ theme, smallFontSize }) => ({
    fontWeight: 800,
    fontSize: smallFontSize ? theme.spacing(3.125) : theme.spacing(4.5),
    textTransform: 'capitalize',
    color: theme.palette.primary.darker
}))
const ButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    gap: theme.spacing(1)
}))
const SidebarContents = styled('div')(({ theme }) => ({
    height: `calc(100% - ${theme.spacing(8)})`
}))

const Sidebar: React.FC<SidebarComponentProps> = ({ currentSidebar, setCurrentSidebar }) => {
    const [submenu, setSubmenu] = useState<string | null>(null)

    return (
        <Wrapper id='sidebar-wrapper' isVisible={!!currentSidebar.type}>
            <TopBar>
                <TitleWrapper>
                    {submenu ? (
                        <BackButton id='go-back-button' onClick={() => {
                            setSubmenu(null)
                            currentSidebar.conversation = undefined
                        }} />
                    ) : null}
                    <Title smallFontSize={!!submenu}>{submenu ?? currentSidebar.type}</Title>
                </TitleWrapper>
                <ButtonsWrapper>
                    <CloseButton id='sidebar-close-button' onClick={() => setCurrentSidebar({ type: null })} />
                </ButtonsWrapper>
            </TopBar>
            <SidebarContents>
                {currentSidebar.type === 'chat' ? (
                    <Chat
                        conversation={currentSidebar.conversation}
                        sidebarSubmenu={submenu}
                        setSidebarSubmenu={setSubmenu} />
                ) : currentSidebar.type === 'notes' ? (
                    <Notes />
                ) : currentSidebar.type === 'assessment' ? (<Assessment />) : <ClassResults type={currentSidebar.type} /> }
            </SidebarContents>
        </Wrapper>
    )
}

export type { SidebarTypes, SidebarProps, SidebarComponentProps }
export { Sidebar }
