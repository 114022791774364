import { useRef } from 'react'
import { styled } from '@mui/material'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import { useGridLayout } from './useGridLayout'
import { useFilterParticipants } from './useFilterParticipants'
import { ParticipantTile } from '../'

const Wrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center'
}))
const TileWrapper = styled('div')(({ theme }) => ({
    width: 'var(--tile-width)',
    height: 'var(--tile-height)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px'
}))

type ZoomLayoutType = {
    breakouts?: BreakoutRoomsState
    isCueCards?: boolean
    cueCardZoomModal?: (data: string) => void
    showCueCardSharingModal?: () => void
}

const ZoomLayout: React.FC<ZoomLayoutType> = ({ breakouts, isCueCards = false, cueCardZoomModal, showCueCardSharingModal }) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const participants = useFilterParticipants(breakouts)
    useGridLayout(wrapperRef, participants.length)

    if (participants.length > 0) {
        return (
            <Wrapper id='participants-grid' ref={wrapperRef}>
                {participants.map(participant => (
                    <TileWrapper key={participant.clientUserId}>
                        <ParticipantTile
                            id={'participant-tile'}
                            participant={participant}
                            isCueCards={isCueCards}
                            cueCardZoomModal={cueCardZoomModal}
                            showCueCardSharingModal={showCueCardSharingModal}
                        />
                    </TileWrapper>
                ))}
            </Wrapper>
        )
    } else return null
}

export { ZoomLayout }
