import { useContext, useEffect, useRef, RefObject } from 'react'
import { styled } from '@mui/material'
import { ConferenceContext } from 'contexts/conference'
import { WaitingForOthers } from 'components/status-panels'
import { ZoomLayout, BorZoomLayout } from 'components/participants'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import { TextButton, PenButton, EraserButton, ClearAllButton } from './components/presentation/Buttons'
import { useWhiteBoardAnnotations } from 'annotation-tool/useWhiteBoardAnnotations'
import { ASPECT_RATIO } from 'zoom/config'

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
}))
const ParticipantsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '30%',
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    '> div': {
        width: '100%'
    }
}))
const WhiteboardContainer = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '70%',
    position: 'relative'
}))
const IframeWrapper = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    height: `calc((100vw - 2 * var(--page-side-margin) - 2px) / ${ASPECT_RATIO})`,
    maxHeight: '100%',
    aspectRatio: `${ASPECT_RATIO}`,
    margin: 'auto',
    position: 'relative',
    borderRadius: theme.spacing(1.5),
    '&:before': {
        content: '""',
        outline: `solid ${theme.spacing(10)} ${theme.palette.neutral.main}`,
        borderRadius: theme.spacing(1.5),
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: `calc((100vw - 2 * var(--page-side-margin) - 2px) / ${ASPECT_RATIO})`,
        maxHeight: '100%',
        aspectRatio: `${ASPECT_RATIO}`
    },
    '&:after': {
        content: '""',
        outline: `solid ${theme.spacing(10)} ${theme.palette.neutral.main} `,
        borderRadius: theme.spacing(1.5),
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: `calc((100vw - 2 * var(--page-side-margin) - 2px) / ${ASPECT_RATIO})`,
        maxHeight: '100%',
        aspectRatio: `${ASPECT_RATIO}`,
        border: '1px solid rgba(0, 0, 0, 0.14)'
    }
}))
const AnnotationsControls = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    zIndex: 3,
    justifyContent: 'space-between',
    gap: theme.spacing(1.5),
    top: theme.spacing(2)
}))
const TextBox = styled('div')(() => ({
    display: 'none',
    position: 'absolute',
    zIndex: 2,
    webkitBackfaceVisibility: 'hidden',
    transition: 'left 1s ease, top 1s ease',
    border: '2px dashed',
    '> textarea': {
        width: '100%',
        height: '100%',
        border: 'none',
        fontSize: '20px',
        lineHeight: '1em',
        resize: 'none',
        boxShadow: 'none',
        display: 'block',
        whiteSpace: 'pre',
        '&:focus': {
            outline: 0
        },
        '&:active': {
            outline: 0
        }
    }
}))
const AnnotationsCanvas = styled('canvas')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 0,
    bottom: 0,
    zIndex: 2,
    borderRadius: theme.spacing(1.5),
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)'
}))

const WhiteboardAnnotation: React.FC = () => {
    const wrapperRef = useRef<HTMLDivElement>(null)
    const textBoxRef = useRef<HTMLDivElement>(null)
    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const annotationsCanvasRef = useRef<HTMLCanvasElement>(null)
    const { annotationsCanvasParams, activeTool, setActiveTool, clearAll } =
        useWhiteBoardAnnotations(wrapperRef, annotationsCanvasRef, undefined, textBoxRef, textAreaRef)

    return (
        <WhiteboardContainer id='teacher-whiteboard-container'>
            <IframeWrapper ref={wrapperRef}>
                <AnnotationsCanvas
                    ref={annotationsCanvasRef}
                    height={annotationsCanvasParams.height}
                    width={annotationsCanvasParams.width}
                />
                <TextBox ref={textBoxRef}>
                    <textarea id='wb-text-area' ref={textAreaRef} />
                </TextBox>
            </IframeWrapper>
            <AnnotationsControls>
                <TextButton
                    id='text-button'
                    onClick={() => setActiveTool(current => current === 'text' ? null : 'text')}
                    active={activeTool === 'text'}
                />
                <PenButton
                    id='pen-button'
                    onClick={() => setActiveTool(current => current === 'pencil' ? null : 'pencil')}
                    active={activeTool === 'pencil'}
                />
                <EraserButton
                    id='eraser-button'
                    onClick={() => setActiveTool(current => current === 'eraser' ? null : 'eraser')}
                    active={activeTool === 'eraser'}
                />
                <ClearAllButton id='clear-all-button' onClick={() => clearAll()} />
            </AnnotationsControls>
        </WhiteboardContainer>
    )
}

const WhiteboardView: React.FC<{
    breakouts?: BreakoutRoomsState
    wrapperRef?: RefObject<HTMLDivElement>
}> = ({ breakouts, wrapperRef }) => {
    const conference = useContext(ConferenceContext)

    useEffect(() => {
        if (conference.status === 'session-joined') {
            conference.primus.send('change-screen', { screen: 'whiteboard' })
        }
    }, [])

    return (
        <Wrapper>
            <ParticipantsWrapper ref={wrapperRef} id='teacher-whiteboard-participants'>
                <WaitingForOthers breakouts={breakouts} />
                {breakouts?.isActive ? (
                    <BorZoomLayout breakouts={breakouts} />
                ) : (
                    <ZoomLayout breakouts={breakouts} />
                )}
            </ParticipantsWrapper>
            <WhiteboardAnnotation />
        </Wrapper>
    )
}

export { WhiteboardView }
