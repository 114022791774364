import { useEffect, useRef, useState } from 'react'
import { Divider, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material'
import { Wrapper, CloseButtonWrapper, InnerWrapper } from 'components/CustomModal'
import { BlueSpeakerIcon, BlueTickMark, DismissIcon, RedMuteIcon, SpeakerIndicatorStaticIcon, TestMicSoundIcon, VideoCamOffIcon } from 'icons'
import audioUrl from 'sounds/bell-sound.mp3'
import dropDownIcon from 'pages/teacher/classroom/components/dropDownIcon.svg'
import { useMediaDevices } from './useMediaDevices'
import { MediaDevice } from '@zoom/videosdk'

type SettingDialogProps = {
    close?: () => void
    onCameraSelect?: (deviceId: string) => void
    selectedCam?: string
}
const InnerContainer = styled(InnerWrapper)(() => ({
    maxHeight: '800px !important',
    overFlow: 'hidden'
}))
const SettingContainer = styled('div')(() => ({
    maxHeight: '600px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
}))
const FormDevice = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
}))
const Device = styled('p')(() => ({
    fontWeight: 600,
    fontSize: '16px'
}))
const TestAudio = styled('button')<{ isClicked: boolean }>(({ isClicked }) => ({
    display: 'flex',
    gap: '8px',
    width: 'fit-content',
    span: {
        fontWeight: 600,
        fontSize: '16px',
        color: isClicked ? '#36C1E2' : 'none'
    },
    margin: '20px 0px',
    cursor: isClicked ? 'pointer' : 'unset'
}))
const CustomSelect = styled(Select)(() => ({
    borderRadius: '0px',
    width: '100%',
    height: '48px',
    padding: '10px 12px',
    border: '2px solid rgba(0, 0, 0, 0.14)',
    margin: '0px',
    '& .MuiSelect-select': {
        padding: '0px !important'
    },
    '& .MuiSelect-icon': {
        fill: 'none !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderStyle: 'none !important'
    },
    '& .MuiOutlinedInput-input img': {
        display: 'none'
    }
}))
const DeviceInfo = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    span: {
        fontWeight: 400,
        fontSize: '14px',
        paddingLeft: '12px',
        color: '#DE1135'
    }
}))
const Preview = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    video: {
        display: 'block',
        borderRadius: '16px',
        width: '496px',
        height: '200px',
        maxWidth: '100%',
        objectFit: 'cover'
    }
}))
const CustomMenuItem = styled(MenuItem)(() => ({
    img: {
        position: 'absolute',
        right: 0,
        marginRight: '12px'
    }
}))
const CamNotFoundInfo = styled('div')(() => ({
    width: '100%',
    height: '200px',
    backgroundColor: '#EEEFF1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '16px',
    alignItems: 'center',
    borderRadius: '16px',
    span: {
        fontWeight: 700,
        fontSize: '32px',
        color: '#A0AEC0'
    }
}))

const BlueTick = styled('img')(() => ({
    position: 'absolute',
    right: 0,
    marginRight: '12px'
}))

const SpeakerIndicator = styled('img')(() => ({
    display: 'block',
    marginLeft: '8px',
    width: '24px'
}))

const SettingCloseButton = styled('img')(() => ({
    cursor: 'pointer'
}))
const Dropdown = styled('img')(() => ({
    pointerEvents: 'none',
    right: '12px',
    position: 'absolute'
}))

const AudioDevice = styled('div')(() => ({
    display: 'flex',
    gap: '8px'
}))

const SettingDialog: React.FC<SettingDialogProps> = ({ close, onCameraSelect, selectedCam }) => {
    const { devices: mediaDevices } = useMediaDevices()
    const [isPlaying, setIsPlaying] = useState(false)
    const [devices, setDevices] = useState<MediaDevice[]>([])
    const [audioDevices, setAudioDevices] = useState<MediaDevice[]>([])
    const [selectedCamera, setSelectedCamera] = useState<string>(selectedCam || '') // State to track selected camera
    const [selectedMicrophone, setSelectedMicrophone] = useState<string>('') // State to track selected microphone
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const [isCamActive, setIsCamActive] = useState<boolean>(false)
    const [isMicActive, setIsMicActive] = useState<boolean>(false)
    const [isClicked, setIsClicked] = useState<boolean>(false)

    const previewStream = async (deviceId: string): Promise<void> => {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId },
            audio: false
        })
        if (videoRef.current) {
            videoRef.current.srcObject = stream
            setIsCamActive(true)
            setSelectedCamera(deviceId)
        }
    }
    const fetchMediaDevices = (): void => {
        const filteredVideoDevices = mediaDevices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput' && device.deviceId !== '')
        const filteredAudioDevices = mediaDevices.filter((device: MediaDeviceInfo) => device.kind === 'audioinput' && device.deviceId !== '')
        setDevices(filteredVideoDevices)
        setAudioDevices(filteredAudioDevices)
        if (filteredVideoDevices.length > 0) {
            setIsCamActive(true)
            setSelectedCamera(selectedCamera)
            void previewStream(selectedCamera)
        }
        if (filteredAudioDevices.length > 0) {
            setSelectedMicrophone(filteredAudioDevices[0].deviceId)
            setIsMicActive(true)
        }
    }
    useEffect(() => {
        if (mediaDevices) {
            fetchMediaDevices()
        }
    }, [mediaDevices])

    const handleDeviceChange = (): void => {
        navigator.mediaDevices.enumerateDevices()
            .then(mediaDevices => {
                const filteredVideoDevices = mediaDevices.filter(device => device.kind === 'videoinput' && device.deviceId !== '')
                const isSelectedCameraAvailable = filteredVideoDevices.some(device => device.deviceId === selectedCamera)
                setDevices(filteredVideoDevices)
                if (!isSelectedCameraAvailable && filteredVideoDevices.length) {
                    setIsCamActive(true)
                    setSelectedCamera(filteredVideoDevices[0].deviceId)
                    void previewStream(filteredVideoDevices[0].deviceId)
                } else if (!filteredVideoDevices.length) {
                    setIsCamActive(false)
                }
                handleCameraSelection(filteredVideoDevices[0].deviceId)
            })
            .catch(error => console.error(error))
    }
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)

    const initializeCamera = (deviceId: string): void => {
        void previewStream(deviceId)
        //  Stop streams of other cameras
        const allVideoStreams = document.querySelectorAll('video')
        allVideoStreams.forEach(video => {
            if (video.srcObject) {
                const tracks = (video.srcObject as MediaStream).getTracks()
                tracks.forEach(track => {
                    track.stop()
                })
                video.srcObject = null
            }
        })
    }

    const initializeMicrophone = (deviceId: string): void => {
        setIsMicActive(true)
        setSelectedMicrophone(deviceId)
    }

    const handleCameraSelection = (deviceId: string): void => {
        onCameraSelect?.(deviceId)
    }

    const playTestSound = (): void => {
        setIsPlaying(true)
        setIsClicked(true)
        const audioElement = new Audio(audioUrl)
        void audioElement.play()
    }
    const handleCameraChange = (event: SelectChangeEvent<unknown>): void => {
        const deviceId = event.target.value as string
        setSelectedCamera(deviceId)
        handleCameraSelection(deviceId)
        initializeCamera(deviceId)
    }

    const handleMicrophoneChange = (event: SelectChangeEvent<unknown>): void => {
        const deviceId = event.target.value as string
        setSelectedMicrophone(deviceId)
        initializeMicrophone(deviceId)
    }

    const handleSoundTestEnd = (): void => {
        setIsPlaying(false)
        setIsClicked(false)
    }

    return (
        <Wrapper>
            <InnerContainer id='settngs-modal-inner-wrapper'>
                <CloseButtonWrapper>
                    <SettingCloseButton
                        id='settings-modal-close-button'
                        src={DismissIcon}
                        alt='settings-CloseButton'
                        onClick={close}
                    />
                </CloseButtonWrapper>
                <h4>Settings</h4>
                <SettingContainer>
                    <FormDevice>
                        <DeviceInfo>
                            <Device data-testid='Camera-Device'>Video</Device>
                            <CustomSelect
                                disabled={!isCamActive}
                                label='Camera'
                                value={selectedCamera}
                                onChange={handleCameraChange}
                                IconComponent={() => (<Dropdown src={dropDownIcon} alt='DropdownOnCamSelect' />)}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            borderRadius: '0px 0px 8px 8px',
                                            border: '1px solid rgba(0, 0, 0, 0.14)',
                                            padding: '8px 8px 8px 8px',
                                            boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.06), 0px 3px 1px 0px rgba(0, 0, 0, 0.02)',
                                            maxHeight: '170px'
                                        }
                                    }
                                }}

                            >
                                {devices.map(device => (
                                    <CustomMenuItem key={device.deviceId} value={device.deviceId}>
                                        <p>{device.label}</p>
                                        {(selectedCamera === device.deviceId) && isCamActive && (
                                            <img src={BlueTickMark} alt='Tick' />
                                        )}
                                    </CustomMenuItem>
                                ))}
                            </CustomSelect>
                            {!isCamActive || !selectedCamera ? (
                                <span>No video detected</span>
                            ) : null}
                        </DeviceInfo>
                        <Preview>
                            {isCamActive && selectedCamera ? (
                                <video
                                    id='preview-camera-video'
                                    ref={videoRef}
                                    autoPlay
                                    playsInline
                                    muted
                                />
                            ) : (
                                <CamNotFoundInfo>
                                    <img
                                        src={VideoCamOffIcon}
                                        alt='Video Not Detected'
                                    />
                                    <span>No video detected</span>
                                </CamNotFoundInfo>
                            )}
                            <Divider sx= {{ backgroundColor: '1px solid grey' }} />
                        </Preview>
                        <DeviceInfo>
                            <Device>Microphone</Device>
                            <AudioDevice>
                                <CustomSelect
                                    disabled={!isMicActive}
                                    label='microphone'
                                    value={selectedMicrophone}
                                    onChange={handleMicrophoneChange}
                                    IconComponent={() => (<Dropdown src={dropDownIcon} alt='DropdownstaticIcon' />)}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                borderRadius: '0px 0px 8px 8px',
                                                border: '1px solid rgba(0, 0, 0, 0.14)',
                                                padding: '8px 8px 8px 8px',
                                                boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.06), 0px 3px 1px 0px rgba(0, 0, 0, 0.02)',
                                                maxHeight: '170px'
                                            }
                                        }
                                    }}
                                >
                                    {audioDevices.map(device => (
                                        <CustomMenuItem key={device.deviceId} value={device.deviceId}>
                                            <p> {device.label} </p>
                                            {(selectedMicrophone === device.deviceId) && isMicActive && (
                                                <BlueTick src={BlueTickMark} alt='Tick' />
                                            )}
                                        </CustomMenuItem>
                                    ))}
                                </CustomSelect>
                                {isMicActive && selectedMicrophone ? (
                                    <SpeakerIndicator src={SpeakerIndicatorStaticIcon} alt='Speaker-indicator' />
                                ) : (<SpeakerIndicator src={RedMuteIcon} alt='red-mute-indicator' />)}
                            </AudioDevice>
                            {!isMicActive || !selectedMicrophone ? (
                                <span>No microphone detected</span>
                            ) : null }
                        </DeviceInfo>
                    </FormDevice>
                    <TestAudio
                        onClick={isMicActive && selectedMicrophone ? playTestSound : undefined}
                        isClicked={isClicked && isMicActive }
                    >
                        {isClicked && isMicActive && selectedMicrophone ? (
                            <img src={BlueSpeakerIcon} alt='blueSpeaker' />
                        ) : (
                            <img src={TestMicSoundIcon} alt='Test-Mic-Sound-Icon' />
                        )}
                        <span>Sound Test</span>
                    </TestAudio>
                </SettingContainer>
            </InnerContainer>
            {isPlaying && (
                <audio
                    autoPlay
                    src={audioUrl}
                    onEnded={handleSoundTestEnd}
                />
            )}
        </Wrapper>
    )
}

export { SettingDialog }
