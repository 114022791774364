import { useContext, useEffect } from 'react'
import { ConferenceContext } from 'contexts/conference'
import { ConferenceParticipant } from 'contexts/conference/types'

const useRenderVideo = (participant: ConferenceParticipant | undefined, div: HTMLDivElement | null): void => {
    const conference = useContext(ConferenceContext)

    useEffect(() => {
        if (conference.status === 'session-joined' && div && participant) {
            const viewclip = document.documentElement.getBoundingClientRect()
            const bounds = div.getBoundingClientRect()

            void conference.rtcSession.setVideoFrame(participant, {
                left: bounds.x - viewclip.left,
                top: bounds.y - viewclip.top,
                height: bounds.height,
                width: bounds.width
            })
        }
    }, [conference.participants, conference.canvasSize, conference.speakers])
}

export { useRenderVideo }
