import { useContext, useEffect } from 'react'
import { ConferenceContext } from 'contexts/conference'
import { ConferenceParticipant } from 'contexts/conference/types'

const useRenderVideo = (participant: ConferenceParticipant, div: HTMLDivElement | null, stopVideoRender: boolean): void => {
    const conference = useContext(ConferenceContext)

    useEffect(() => {
        if (conference.status === 'session-joined' && div && !stopVideoRender) {
            const viewClip = document.getElementById('zoom-canvas')?.getBoundingClientRect()
            const leftClip = viewClip ? viewClip.left : 0
            const topClip = viewClip ? viewClip.top : 0
            const bounds = div.getBoundingClientRect()

            void conference.rtcSession.setVideoFrame(participant, {
                left: bounds.x + 2 - leftClip,
                top: bounds.y + 3 - topClip,
                height: bounds.height - 6,
                width: bounds.width - 4
            })
        }
    }, [conference.participants, conference.canvasSize, conference.speakers])
}

export { useRenderVideo }
