// Temporary script to make clarity work in GOC
const microsoftClarity = `!function(c, l, a, r, i, t, y) {
    a[c]=a[c]||function(){(a[c].q=a[c].q||[]).push(arguments)};
    function sync() {
        image = (new Image);
        image.crossOrigin=1;
        image.src = "https://c.clarity.ms/c.gif"
    }
    "complete" == document.readyState ? sync() : window.addEventListener("load", sync);
    if (a[c].v || a[c].t)
        return a[c]("event", c, "dup." + i.projectId);
    a[c].t = !0,
    (t = l.createElement(r)).async = !0,
    t.crossOrigin=1,
    t.src = "https://www.clarity.ms/s/0.7.8/clarity.js",
    (y = l.getElementsByTagName(r)[0]).parentNode.insertBefore(t, y),
    a[c]("start", i),
    a[c].q.unshift(a[c].q.pop()),
    a[c]("set", "C_IS", 0)
}("clarity", document, window, "script", {
    "projectId": "${process.env.REACT_APP_MICROSOFT_CLARITY_TRACING_ID}",
    "upload": "https://q.clarity.ms/collect",
    "expire": 365,
    "cookies": ["_uetmsclkid", "_uetvid"],
    "track": true,
    "lean": false,
    "content": true,
    "dob": 1240
});
`

export default microsoftClarity
