import { IPlayer } from 'ispring'

type StepIndexEventHandler = (slideIndex: number, stepIndex: number) => void

const addStepEventHandler = (player: IPlayer, handler: StepIndexEventHandler): () => void => {
    const pbController = player.view().playbackController()
    const slides = player.presentation().slides()

    const cb = (stepIndex: number, ...rest: any[]): void => {
        const slideIndex = pbController.currentSlideIndex()
        if (stepIndex >= 0) {
            handler(slideIndex, stepIndex)

        } else if (slideIndex > 0) { // stepIndex is negative and slideIndex is not zero
            const slide = slides.getSlide(slideIndex - 1)
            const stepsCount = slide.animationSteps().count()
            handler(slideIndex - 1, stepsCount + stepIndex)

        } else {
            // slide index 0 and negative step index
        }
    }

    const eventDispatcher = pbController.stepChangeEvent()
    eventDispatcher.addHandler(cb, void 0, 1)

    return () => eventDispatcher.removeHandler(cb)
}

export { addStepEventHandler }
