import { Dispatch, MutableRefObject, Reducer, RefObject, SetStateAction } from 'react'
import { getClassroomState } from 'api/classroom/state'
import { getPrivateChat } from 'api/chat/get-private-chat'
import { User } from 'api/types'
import { ChatClient } from 'chat'
import { initializeZoomClient } from 'zoom'
import { ConferenceContextState, ConferenceAction, RtcSession } from './types'
import { rollbarService } from 'services/rollbar'

type ConferenceReducerParams = {
    setConferenceCanvas: Dispatch<SetStateAction<HTMLCanvasElement | null>>
    rtcSession: MutableRefObject<RtcSession | undefined>
    classroomId: RefObject<string | undefined>
    auth: RefObject<string | undefined>
    currentUser: RefObject<User | undefined>
}

interface ConferenceReducer {
    (arg0: ConferenceReducerParams): Reducer<ConferenceContextState, ConferenceAction>
}

const reducer: ConferenceReducer = ({ auth, classroomId, rtcSession, currentUser, setConferenceCanvas }) => {
    return (state, action) => {
        switch (action.method) {

            case 'speakers-list':
                return { ...state, speakers: action.list }

            case 'last-speaker':
                if (state.status === 'session-joined') {
                    return { ...state, lastSpeakerId: action.speakerId }

                } else {
                    return state
                }

            case 'canvas-update':
                if (state.status === 'waiting-for-classroom-info') {
                    setConferenceCanvas(action.canvas)
                    return state

                } else {
                    return { ...state, canvasSize: action.canvasSize }
                }

            case 'session-joined':
                if (state.status === 'ready-to-join') {
                    rtcSession.current = action.rtcSession
                    const studentsAwayStatus = currentUser.current?.role === 'student'
                        ? [] : action.classroomState.dcAppData.students
                            .map(student => ({
                                id: student.id,
                                isAway: student.isAway
                            }))
                    const studentCueCards = action.classroomState.dcAppData.students
                        .filter(studentCueCard => (
                            currentUser.current?.role === 'teacher' || studentCueCard.id === currentUser.current?.id)
                        )
                        .map(student => ({
                            id: student.id,
                            cueCard: student.cueCard
                        }))
                    return {
                        ...state,

                        status: 'session-joined',
                        classroomStateOnJoin: action.classroomState,
                        rtcSession: action.rtcSession,
                        primus: action.primus,
                        chatClient: action.chatClient,
                        lastSpeakerId: void 0,

                        leave: () => {
                            void action.rtcSession.leave()
                        },
                        end: () => {
                            void action.rtcSession.end()
                        },

                        isAudioMuted: action.isAudioMuted,
                        isVideoMuted: action.isVideoMuted,
                        allowAudio: action.allowAudio,
                        allowVideo: action.allowVideo,
                        slideAnnotations: action.classroomState.dcAppData.wbData.boards[0].mount,
                        whiteBoardAnnotations: action.classroomState.dcAppData.wbData.boards[0],
                        isClassResultsViewed: action.classroomState.dcAppData.students.find(student => {
                            return student.id === currentUser.current?.id
                        })?.isClassResultsViewed ?? null,
                        isRenderSelfViewWithVideoElement: action.isRenderSelfViewWithVideoElement,
                        cueCard: studentCueCards,
                        cueCardInfo: action.classroomState.dcAppData.wbData.cueCardInfo,
                        studentsAwayStatus
                    }

                } else {
                    return state
                }

            case 'disconnected':
                if (state.status === 'session-joined') {
                    return { ...state, status: 'session-disconnected' }

                } else {
                    return state
                }

            case 'ready-to-join':
                if (state.status === 'initializing-client') {
                    return {
                        ...state,
                        status: 'ready-to-join',
                        participants: [],
                        joinSession: async options => {
                            const token = auth.current!
                            const user = currentUser.current!
                            const classId = classroomId.current!

                            const classroomState = await getClassroomState(token, classId)
                            const privateChatList = await getPrivateChat(token, classId, user.id)

                            const users = [...classroomState.dcAppData.students, classroomState.dcAppData.teacher]
                            const publicChatList = classroomState.dcAppData.chatData.publicChat
                            const notificationCount = classroomState.dcAppData.chatData.notificationCount

                            const chatClient = new ChatClient(user, users, action.primus, privateChatList, publicChatList, notificationCount)

                            action.setSessionTimer(classroomState.dcAppData.session.timeLeft)
                            action.primus.subscribeToActivityChannel(classroomState.dcAppData.lastMsgId)
                            rollbarService.updatePayload({
                                person: {
                                    id: currentUser.current?.id || '',
                                    role: currentUser.current?.role,
                                    classroomId: classId,
                                    firstName: currentUser.current?.firstName
                                },
                                custom: {
                                    classState: {
                                        activityType: classroomState.activityType,
                                        classTypeName: classroomState.classTypeName,
                                        centerType: classroomState.centerType,
                                        participants: classroomState.participants,
                                        levels: classroomState.Levels,
                                        unit: classroomState.unit?.id,
                                        duration: classroomState.duration
                                    },
                                    diagnoseStatus: sessionStorage.getItem('diagnoseStatus'),
                                    systemCore: navigator.hardwareConcurrency
                                }
                            })

                            return (await initializeZoomClient(action.clientParams)).joinSession(
                                action.primus,
                                chatClient,
                                classroomState,
                                options
                            )
                        }
                    }

                } else {
                    return state
                }

            case 'set-status':
                if (action.status === 'initializing-client') {
                    return { ...state, status: 'initializing-client', canvasSize: state.canvasSize! }

                } else {
                    return state
                }

            case 'set-participants':
                return { ...state, participants: action.participants }

            case 'mute-audio':
                return { ...state, isAudioMuted: action.muted }

            case 'mute-video':
                return { ...state, isVideoMuted: action.muted }

            case 'set-time-left':
                return { ...state, timeLeft: action.timeLeft }
            case 'update-time-left':
                return { ...state, timeLeft: state.timeLeft - 1 }
            case 'check-time-left':
                return { ...state, timeLeft: (state.timeLeft <= 0) ? state.timeLeft - 1 : state.timeLeft }
            case 'update-slide-annotations' :
                return { ...state, slideAnnotations: action.slideAnnotations }
            case 'update-whiteboard-annotations' :
                return { ...state, whiteBoardAnnotations: action.whiteBoardAnnotations }
            case 'update-class-result' :
                return { ...state, isClassResultsViewed: action.isViewed }
            case 'hard-mute-audio':
                return { ...state, isAudioMuted: action.muted ? action.muted : true, allowAudio: !action.muted }
            case 'update-hard-mute-status':
                return { ...state, participantHardMuteStatus: action.participantHardMuteStatus }
            case 'update-cue-card-data':
                return { ...state, cueCardInfo: action.cueCardInfo }
            case 'update-student-cue-card':
                return { ...state, cueCard: action.cueCard }
            case 'update-user-away':
                return {
                    ...state,
                    studentsAwayStatus: state.studentsAwayStatus.map(student =>
                        student.id === action.studentAwayStatus.userId ? { ...student, isAway: action.studentAwayStatus.isAway } : student
                    )
                }
            case 'set-retry-error':
                return { ...state, viewRetryErrorSnackbar: action.viewRetryErrorSnackbar }

            default:
                return state
        }
    }
}

export { reducer }
