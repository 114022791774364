import { RefObject, Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/material'
import { WaitingForOthers } from 'components/status-panels'
import { ZoomLayout } from 'components/participants'
import { SlidesPresentation } from './components'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import { PptActionEvent } from 'primus/types/events'

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}))
const ParticipantsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '30%',
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    '> div': {
        width: '100%'
    }
}))

const SlidesView: React.FC<{
    breakouts?: BreakoutRoomsState
    wrapperRef?: RefObject<HTMLDivElement>
    bufferedEvent?: PptActionEvent | null
    setBufferedEvent?: Dispatch<SetStateAction<PptActionEvent | null>>
}> = ({ breakouts, wrapperRef, bufferedEvent, setBufferedEvent }) => (
    <Wrapper>
        <ParticipantsWrapper ref={wrapperRef} id='student-slides-participants'>
            <WaitingForOthers breakouts={breakouts} />
            <ZoomLayout breakouts={breakouts} />
        </ParticipantsWrapper>
        <SlidesPresentation bufferedEvent={bufferedEvent} setBufferedEvent={setBufferedEvent} />
    </Wrapper>
)

export { SlidesView }
