import { ClassroomId, WseUserId } from 'api/types'
import { ExceptionType, GOCException } from 'exceptions'
import { apiRequest } from '../api-request'
import { BookingCheck } from './BookingCheck'

const checkBooking = async (auth: string, classroomId: ClassroomId, userId: WseUserId): Promise<BookingCheck> => {
    const response = await apiRequest<{ data: BookingCheck | null; msg: string | null }>({
        path: '/auth/check',
        method: 'GET',
        auth,
        query: {
            deviceType: 'web',
            activityId: classroomId,
            userId
        }
    })

    if (response.data) {
        return response.data

    } else {
        throw new GOCException(ExceptionType.BookingCheckError, response.msg ?? '')
    }
}

export type { BookingCheck }
export { checkBooking }
