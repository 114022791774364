import { Category } from '../categories'

export const mock: Category[] = [
    {
        id: '8e002e57-bc41-4860-b93c-f648b13f5036',
        type: 'Unit',
        sequence: 4,
        path: 'L20.U80',
        attributes: {
            name: 'Unit 80',
            number: '80'
        },
        childCategories: [
            {
                id: 'b86f926f-0de8-468f-a339-41f89f2419f6',
                type: 'Lesson',
                sequence: 1,
                path: 'L20.U80.L1',
                attributes: {
                    number: '1',
                    name: 'Lesson 1',
                    unitLessonNumber: '80.1'
                },
                childCategories: [],
                contentItems: [
                    {
                        id: 'c434234b-3404-47e2-a0db-d02e7cbfdf02',
                        type: 'Activity',
                        sequence: 4336,
                        description: 'End of Lesson',
                        duration: 0,
                        url: 'https://prodoscarblob.blob.core.windows.net/activities/c434234b-3404-47e2-a0db-d02e7cbfdf02_1620101100.json',
                        isSkippable: false
                    }
                ]
            },
            {
                id: '044cd688-38d8-4d41-9858-b57ac8cbd91b',
                type: 'Lesson',
                sequence: 2,
                path: 'L20.U80.L2',
                attributes: {
                    name: 'Lesson 2',
                    unitLessonNumber: '80.2',
                    number: '2'
                },
                childCategories: [],
                contentItems: [
                    {
                        id: '4863894e-6fef-4ca2-bb0f-d5607188d435',
                        type: 'Activity',
                        sequence: 4352,
                        description: 'End of Lesson',
                        duration: 0,
                        url: 'https://prodoscarblob.blob.core.windows.net/activities/4863894e-6fef-4ca2-bb0f-d5607188d435_1620101104.json',
                        isSkippable: false
                    }
                ]
            },
            {
                id: 'ba50a175-cc8a-47c1-b3d6-b3e95d7e7512',
                type: 'Lesson',
                sequence: 3,
                path: 'L20.U80.L3',
                attributes: {
                    unitLessonNumber: '80.3',
                    number: '3',
                    name: 'Lesson 3'
                },
                childCategories: [],
                contentItems: [
                    {
                        id: 'dff213ed-80b0-41f3-b3a9-212dcb715177',
                        type: 'Activity',
                        sequence: 4370,
                        description: 'End of Lesson',
                        duration: 0,
                        url: 'https://prodoscarblob.blob.core.windows.net/activities/dff213ed-80b0-41f3-b3a9-212dcb715177_1620101108.json',
                        isSkippable: false
                    }
                ]
            }
        ],
        contentItems: []
    }
]
