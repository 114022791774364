import { styled } from '@mui/system'

export const Panel = styled('div')(({ theme }) => ({
    display: 'inline-block',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.neutral.darker
}))
