import { useEffect, useState, useRef, useContext } from 'react'
import { styled } from '@mui/material'
import { ChatClient, ConversationType, MessageType } from 'chat'
import { SendIconButton } from 'components/icon-buttons'
import { getDisplayName } from 'utils/get-display-name'
import { BookingContext } from 'contexts/booking'

const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%'
}))
const ChatWrapper = styled('div')(({ theme }) => ({
    overflow: 'auto',
    marginBottom: theme.spacing(2)
}))
const ScrollHook = styled('div')(({ theme }) => ({
    visibility: 'hidden'
}))
const MessageItem = styled('div')<{ ownMessage: boolean }>(({ theme, ownMessage }) => ({
    boxSizing: 'border-box',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    margin: `${theme.spacing(2)} 0`,
    marginLeft: ownMessage ? 'auto' : 0,
    backgroundColor: ownMessage ? theme.palette.primary.darker : theme.palette.neutral.main,
    color: ownMessage ? theme.palette.neutral.main : theme.palette.neutral.dark,
    borderRadius: theme.spacing(3),
    borderBottomRightRadius: ownMessage ? 0 : theme.spacing(3),
    borderTopLeftRadius: ownMessage ? theme.spacing(3) : 0,
    fontSize: theme.spacing(2.25),
    wordBreak: 'break-word',
    minHeight: '2.25rem',
    width: 'fit-content',
    maxWidth: '80%',
    span: {
        color: ownMessage ? theme.palette.neutral.hover : theme.palette.primary.darker,
        fontWeight: 600,
        fontSize: theme.spacing(1.75)
    }
}))
const InputWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    backgroundColor: theme.palette.neutral.main,
    borderRadius: theme.spacing(8),
    input: {
        flex: 1,
        marginRight: theme.spacing(1),
        '&::placeholder': {
            color: theme.palette.secondary.darker
        }
    }
}))

const Conversation: React.FC<{ chatClient: ChatClient; conversation: ConversationType }> = ({ chatClient, conversation }) => {
    const booking = useContext(BookingContext)
    const scrollHookRef = useRef<HTMLDivElement | null>(null)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [chatInput, setChatInput] = useState<string>('')
    const [messages, setMessages] = useState(conversation.messages)

    const sendMessage = (): void => {
        if (chatInput) {
            if (conversation.chatId === 'EVERYONE') {
                chatClient.sendPublicMessage(chatInput)
            } else {
                chatClient.sendPrivateMessage(chatInput, conversation.chatId)
            }
            setChatInput('')
        }
    }

    useEffect(() => {
        scrollHookRef.current?.scrollIntoView({ behavior: 'smooth' })
        chatClient.readNotifications(conversation.chatId)
    }, [messages.length])
    useEffect(() => {
        inputRef.current?.focus()
        const handler = (m: MessageType, c: ConversationType): void => {
            if (conversation.chatId === c.chatId) {
                setMessages([...c.messages])
            }
        }
        chatClient.addChatMessageHandler(handler)
        return () => chatClient.removeChatMessageHandler(handler)
    }, [])

    useEffect(() => {
        setMessages([...conversation.messages])
    }, [conversation.chatId])

    return (
        <Wrapper>
            <ChatWrapper id='conversation-wrapper'>
                {messages.map((m, i) => (
                    <MessageItem key={`chat-${conversation.chatId}-${i}`} ownMessage={m.isOwnMessage}>
                        <span>{getDisplayName(m.sender, m.isTeacher ? 'teacher-name' : 'student-name')}</span>
                        <p>{m.text}</p>
                    </MessageItem>
                ))}
                <ScrollHook ref={scrollHookRef} />
            </ChatWrapper>
            <InputWrapper>
                <input type='text' ref={inputRef} id='conversation-input' value={chatInput}
                    placeholder={`Send message to ${getDisplayName(
                        conversation,
                        conversation.role === 'teacher' ? 'teacher-name' : 'student-name',
                        booking.status === 'validated' && booking.user.role === 'teacher'
                    )}`}
                    onChange={e => setChatInput(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' && sendMessage()}
                />
                <SendIconButton
                    id='conversation-send-button'
                    onClick={sendMessage}
                    disabled={!chatInput}
                    active={!!chatInput}
                    size='small'
                />
            </InputWrapper>
        </Wrapper>
    )
}

export { Conversation }
