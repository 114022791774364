import { ClassResults } from '../results'

export const mock: ClassResults = {
    partialResults: [
        {
            studentId: 'a9418922-8a41-4efe-81aa-9a5f86eec87e',
            teacherId: 'd14dcd07-d24a-4af9-b86c-d7e26d788f0e',
            registrationId: '9965c26d-4a32-476a-90d3-76e573008a29',
            itemResultsViewModel: [
                {
                    ratingSchemaPartItemId: 'cfe9afa5-6342-49f1-9b1d-8960719a4bf8',
                    result: 3,
                    number: null
                }
            ]
        }
    ],
    results: [
        {
            id: '82b58866-350d-44b2-ba92-ea22018a906e',
            classType: 0,
            teacherId: 'd14dcd07-d24a-4af9-b86c-d7e26d788f0e',
            studentId: 'a9418922-8a41-4efe-81aa-9a5f86eec87e',
            result: null,
            contentItemResultTypeId: null,
            score: null,
            dateStarted: '2023-01-19T07:33:52.28',
            dateCompleted: null,
            created: '2023-01-19T07:33:52.28',
            lastUpdated: '2023-01-19T07:33:52.283'
        }
    ]
}
