import { styled } from '@mui/material'
import { SlideDeckNavigationController } from 'ispring'
import { EyeIcon } from 'icons'
import { PreviousStepButton, NextStepButton, TextButton, PenButton, EraserButton, ClearAllButton } from './Buttons'
import { useRef } from 'react'
import { useAnnotations } from 'annotation-tool/useAnnotations'

const PresentationContainer = styled('div')(({ theme }) => ({
    flex: 1,
    marginLeft: theme.spacing(2)
}))
const IframeWrapper = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    margin: 'auto',
    position: 'relative',
    '&:before': {
        content: '""',
        outline: `solid ${theme.spacing(10)} ${theme.palette.neutral.main}`,
        borderRadius: theme.spacing(2),
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        aspectRatio: '16/9'
    },
    '&:after': {
        content: '""',
        outline: `solid ${theme.spacing(10)} ${theme.palette.neutral.main} `,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        aspectRatio: '16/9',
        border: '1px solid rgba(0, 0, 0, 0.16)'
    }
}))
const Iframe = styled('iframe')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '103%',
    height: '105%',
    border: 'none',
    pointerEvents: 'none',
    userSelect: 'none'
}))
const NavigationControls = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    zIndex: 3,
    justifyContent: 'space-between',
    gap: theme.spacing(1.5),
    right: theme.spacing(1),
    bottom: theme.spacing(2)
}))
const AnnotationsControls = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    zIndex: 3,
    justifyContent: 'space-between',
    gap: theme.spacing(1.5),
    top: theme.spacing(2)
}))
const BottomLeftSpan = styled('span')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    borderRadius: theme.spacing(0.5),
    gap: theme.spacing(0.75),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.neutral.main,
    alignItems: 'center',
    fontSize: theme.spacing(1.2),
    lineHeight: theme.spacing(1.2),
    bottom: theme.spacing(2),
    '> img': {
        width: theme.spacing(2),
        height: theme.spacing(2)
    }
}))

const TextBox = styled('div')(({ theme }) => ({
    display: 'none',
    position: 'absolute',
    zIndex: 2,
    webkitBackfaceVisibility: 'hidden',
    transition: 'left 1s ease, top 1s ease',
    border: '2px dashed',
    '> textarea': {
        width: '100%',
        height: '100%',
        border: 'none',
        fontSize: '20px',
        lineHeight: '1em',
        resize: 'none',
        boxShadow: 'none',
        display: 'block',
        whitespace: 'pre',
        wrap: 'soft',
        '&:focus': {
            outline: 0
        },
        '&:active': {
            outline: 0
        }
    }
}))

const AnnotationsCanvas = styled('canvas')(() => ({
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2
}))

const Presentation: React.FC<{
    presentationUrl: string
    navigationController?: SlideDeckNavigationController
}> = ({ presentationUrl, navigationController }) => {
    const wrapperRef = useRef<HTMLDivElement>(null)
    const textBoxRef = useRef<HTMLDivElement>(null)
    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const annotationsCanvasRef = useRef<HTMLCanvasElement>(null)
    const { annotationsCanvasParams, activeTool, setActiveTool, clearAll } =
        useAnnotations(wrapperRef, annotationsCanvasRef, navigationController, textBoxRef, textAreaRef)

    return (
        <PresentationContainer>
            <IframeWrapper ref={wrapperRef}>
                <Iframe id='presentation-iframe' src={presentationUrl} />
                <AnnotationsCanvas
                    ref={annotationsCanvasRef}
                    height={annotationsCanvasParams.height}
                    width={annotationsCanvasParams.width}
                />
                <TextBox ref={textBoxRef}>
                    <textarea id='slide-text-area' ref={textAreaRef} />
                </TextBox>
            </IframeWrapper>
            <AnnotationsControls>
                <TextButton
                    id='presentation-text-button'
                    onClick={() => setActiveTool(current => current === 'text' ? null : 'text')}
                    active={activeTool === 'text'}
                />
                <PenButton
                    id='presentation-pen-button'
                    onClick={() => setActiveTool(current => current === 'pencil' ? null : 'pencil')}
                    active={activeTool === 'pencil'}
                />
                <EraserButton
                    id='presentation-eraser-button'
                    onClick={() => setActiveTool(current => current === 'eraser' ? null : 'eraser')}
                    active={activeTool === 'eraser'}
                />
                <ClearAllButton id='presentation-clear-all-button' onClick={() => clearAll()} />
            </AnnotationsControls>
            <BottomLeftSpan>
                <img src={EyeIcon} />
                Shared with Students
            </BottomLeftSpan>
            <NavigationControls>
                <PreviousStepButton
                    id='presentation-prev-step-button'
                    onClick={() => navigationController?.gotoPreviousStep()}
                />
                <NextStepButton
                    id='presentation-next-step-button'
                    onClick={() => navigationController?.gotoNextStep()}
                />
            </NavigationControls>
        </PresentationContainer>
    )
}

export { Presentation }
