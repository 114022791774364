import { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { fetchSlideDecksInfo, SlideDeckInfo } from 'api/cms/fetch-ppts'
import { BookingContext } from 'contexts/booking'
import { ConferenceContext } from 'contexts/conference'
import { ActiveSlide, IPlayer } from 'ispring'
import { Close, ISpringContext, ISpringContextState, SetPresentationUrl } from './ISpringContext'
import { ExceptionType, GOCException } from 'exceptions'
import { getQuery, SnbStage } from './get-query'
import { addStepEventHandler } from './handlers/step-change'
import { getCurrentSlideInfo } from 'utils/get-current-ppt'

type CurrentSlide = {
    slideIndex: number
    stepIndex: number
}

const ISpring: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setState] = useState<ISpringContextState>({
        status: 'waiting-for-session-joined'
    })

    const [player, setPlayer] = useState<IPlayer>()
    const [currentSlide, setCurrentSlide] = useState<CurrentSlide>()
    const [stepChangeRemove, setStepChangeRemove] = useState<() => void>()
    const conference = useContext(ConferenceContext)
    const booking = useContext(BookingContext)
    const [storedPresentationUrl, setStoredPresentationUrl] = useState<string>()

    const slideDecks = useRef<SlideDeckInfo[]>([])

    const close: Close = (activeSlide: ActiveSlide): void => {
        if (stepChangeRemove) {
            stepChangeRemove()
        }

        setCurrentSlide(() => {
            setState({
                status: 'ready',
                decks: slideDecks.current,
                storedActiveSlide: activeSlide,
                setPresentationUrl,
                storedPresentationUrl
            })

            return void 0
        })
    }

    const setPresentationUrl: SetPresentationUrl = (url: string): void => {
        const currentDeck = slideDecks.current.find(
            deck => deck.sameOriginUrl === url || deck['Blob URL'] === url
        )

        if (currentDeck) {
            setState(state => {
                if (state.status === 'ready' || state.status === 'presentation-selected' || state.status === 'player-ready') {
                    setStoredPresentationUrl(currentDeck?.sameOriginUrl)
                    return {
                        status: 'presentation-selected',
                        storedActiveSlide: state.storedActiveSlide,
                        presentationUrl: currentDeck?.sameOriginUrl,
                        setPresentationUrl,
                        close
                    }

                } else {
                    throw new GOCException(ExceptionType.UnexpectedPresentationStatus)
                }
            })

        } else {
            throw new GOCException(ExceptionType.UnknownPresentationUrl)
        }
    }

    useEffect(() => {
        if (currentSlide && player && (state.status === 'ready' || state.status === 'presentation-selected')) {
            const iframeUrl = player.view().slidesContainer().ownerDocument.defaultView!.location.href
            if (slideDecks.current && iframeUrl) {
                const currentDeck = slideDecks.current.find(deck => deck.sameOriginUrl === iframeUrl)
                if (currentDeck) {
                    setTimeout(() => {
                        setState(state => ({
                            status: 'player-ready',

                            presentationUrl: currentDeck.sameOriginUrl,
                            decks: slideDecks.current,
                            currentDeck,
                            player,
                            setPresentationUrl,
                            storedActiveSlide: (state.status === 'ready' || state.status === 'presentation-selected')
                                ? state.storedActiveSlide
                                : { index: 0, step: 0 },
                            close
                        }))
                    }, 500) // the 0 0 slide is doubled with a short delay
                }
            }
        }
    }, [currentSlide, player])

    useEffect(() => {
        if (conference.status === 'session-joined' && booking.status === 'validated') {
            const [classType, unitId, stage] = getQuery(
                conference.classroomStateOnJoin.Levels as SnbStage[],
                conference.classroomStateOnJoin.activityType,
                conference.classroomStateOnJoin.unit
            )

            const presentationState = conference.classroomStateOnJoin.dcAppData.wbData.boards[0].mount
            const wbData = conference.classroomStateOnJoin.dcAppData.wbData
            const pptUrl = wbData.boards[wbData.activeBoard].mount?.url

            const storedActiveSlide = presentationState
                ? { index: presentationState.activeSlide, step: presentationState.stepIndex }
                : { index: 0, step: 0 }

            setState({ status: 'loading' })
            void fetchSlideDecksInfo(booking.auth, classType, unitId, stage).then(async decks => {
                slideDecks.current = decks
                const currentSlideInfo = getCurrentSlideInfo(conference, decks)
                if (currentSlideInfo?.['CueCard URL']) {
                    const cueCardResponseData = currentSlideInfo && await fetch(currentSlideInfo['CueCard URL']).then(async response => response.json())
                    conference.setCueCardInfo(cueCardResponseData)
                }

                if (decks.length === 0) {
                    setState({ status: 'no-slidedecks-assigned' })

                } else {
                    setState({
                        status: 'ready',
                        decks: slideDecks.current,
                        storedActiveSlide,

                        setPresentationUrl,
                        storedPresentationUrl: pptUrl
                    })
                }
            })
        }
    }, [conference.status])

    useEffect(() => {
        window.ispringPresentationConnector = {
            register: (player: IPlayer) => {
                setPlayer(player)
                setStepChangeRemove(addStepEventHandler(player, (slideIndex, stepIndex) => {
                    setCurrentSlide({ slideIndex, stepIndex })
                }))
            }
        }
    }, [])

    return (
        <ISpringContext.Provider value={state}>
            {children}
        </ISpringContext.Provider>
    )
}

export { ISpring }
