import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { UserState } from 'api/classroom/ClassroomState'
import { BookingContext } from 'contexts/booking'
import { Logo } from 'pages/logo/Logo'

const SessionWrapper: React.FC<{ children?: React.ReactNode; restrictTo?: UserState['role'] }> = ({ children, restrictTo }) => {
    const booking = useContext(BookingContext)

    switch (booking.status) {
        case 'checking':
            return <Logo />

        case 'invalid': case 'no-session':
            return <>Invalid Session</>

        case 'expired':
        case 'ended':
            return <Navigate to={'/thanks'} />
        case 'expelled':
            return <Navigate to={'/expel'} />
        case 'validated':
            if (restrictTo && booking.user.role !== restrictTo) {
                return <Navigate to={'/'} />

            } else {
                return <>{children}</>
            }

    }
}

export { SessionWrapper }
