import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BookingContext, BookingContextState } from 'contexts/booking'
import { SessionWrapper } from 'components/wrappers'
import { Logo } from '../logo/Logo'
import { ClassroomId, WseUserId } from 'api/types'
import { ConferenceContext } from 'contexts/conference'

type UrlParams = {
    classroomId: ClassroomId
    userId: WseUserId
    auth: string
    isTeens: string
    callbackUrl: string
}

const areUrlParamsCorrect = (params: Readonly<Partial<UrlParams>>): params is UrlParams => (
    !!(params.classroomId && params.userId && params.auth)
)

const isSameSession = (params: Readonly<Partial<UrlParams>>, booking: BookingContextState): boolean => {
    if (booking.status === 'validated') {
        return booking.auth === params.auth && booking.user.id === params.userId && booking.classroomId === params.classroomId
    }
    return false
}

const Autologin: React.FC = () => {
    const conference = useContext(ConferenceContext)
    const booking = useContext(BookingContext)
    const params = useParams<UrlParams>()
    const [autoLoginStatus, setAutoLoginStatus] = useState<'waiting' | 'checking' | 'done'>('waiting')
    const navigate = useNavigate()

    if (conference.status === 'ready-to-join' || conference.status === 'session-joined') {
        location.reload()
    }

    useEffect(() => {
        if (autoLoginStatus === 'waiting' && booking.status !== 'checking') {
            if (areUrlParamsCorrect(params)) {
                if (isSameSession(params, booking)) {
                    setAutoLoginStatus('done')

                } else {
                    sessionStorage.setItem('callbackUrl', params.callbackUrl)
                    booking.restoreBooking(params.auth, params.classroomId, params.userId, params.isTeens, params.callbackUrl)
                    setAutoLoginStatus('checking')
                }

            } else {
                setAutoLoginStatus('done')
                booking.clearSession()
            }

        } else if (autoLoginStatus === 'checking' && booking.status !== 'checking') {
            setAutoLoginStatus('done')
        }

    }, [booking.status, params.classroomId, params.auth, params.userId])

    useEffect(() => {
        if (autoLoginStatus === 'done' && booking.status === 'validated') {
            navigate('/diagnostics')
        }
    }, [autoLoginStatus])

    return autoLoginStatus === 'done' ? <SessionWrapper /> : <Logo />
}

export { Autologin }
