import { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material'
import { ConferenceContext } from 'contexts/conference'
import { ConversationType, MessageType } from 'chat'
import { MessagePopup } from 'components/chat'
import { Selfie } from 'components/participants'
import { SidebarComponentProps, SidebarProps } from 'components/sidebar'
import { ButtonWrapper, ChatButton } from 'components/buttons'
import { getDisplayName } from 'utils/get-display-name'

const Wrapper = styled('div')(({ theme }) => ({
    padding: `${theme.spacing(16)} var(--page-side-margin) var(--page-v-margin)`,
    boxSizing: 'content-box',
    display: 'flex',
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(20)} var(--page-side-margin) var(--page-v-margin)`,
        flexDirection: 'column'
    }
}))
const LeftWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    minWidth: theme.spacing(30),
    justifyContent: 'left',
    alignItems: 'flex-end',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    }
}))
const CenterWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
}))
const RightWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1
}))
const SelfieWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    borderRadius: theme.spacing(2.5),
    border: `solid ${theme.spacing(1)} white`,
    overflow: 'hidden',
    boxSizing: 'content-box'
}))

const BottomControls: React.FC<SidebarComponentProps> = ({ currentSidebar, setCurrentSidebar }) => {
    const conference = useContext(ConferenceContext)
    const sessionJoined = conference.status === 'session-joined'

    const toggleSidebar = (currentState: SidebarProps): void => {
        setCurrentSidebar(prevState => prevState.type === currentState.type ? { type: null } : currentState)
    }

    const [lastMessage, setLastMessage] = useState<{ from: string; text: string; conversation: ConversationType } | null>(null)
    const [notificationsCount, setNotificationsCount] = useState<number>(0)
    const onMessageClick = (): void => {
        setCurrentSidebar({ type: 'chat', conversation: lastMessage?.conversation })
        setLastMessage(null)
    }
    const onMessageCloseClick = (e: React.MouseEvent<HTMLElement>): void => {
        e.stopPropagation()
        setLastMessage(null)
    }
    const onChatClick = (): void => {
        toggleSidebar({ type: 'chat' })
        setLastMessage(null)
    }

    useEffect(() => {
        if (sessionJoined) {
            setNotificationsCount(conference.chatClient.getTotalNotifications())

            const notificationHandler = (): void => {
                setNotificationsCount(conference.chatClient.getTotalNotifications())
            }
            const messageHandler = (message: MessageType, conversation: ConversationType): void => {
                if (conversation.chatId !== 'EVERYONE' && !message.isOwnMessage) {
                    setLastMessage({
                        from: getDisplayName(conversation, 'teacher-name'),
                        text: message.text,
                        conversation
                    })
                }
            }

            conference.chatClient.addNotificationHandler(notificationHandler)
            conference.chatClient.addChatMessageHandler(messageHandler)

            return () => {
                conference.chatClient.removeNotificationHandler(notificationHandler)
                conference.chatClient.removeChatMessageHandler(messageHandler)
            }
        }
    }, [conference.status])

    return (
        <Wrapper>
            <LeftWrapper>
                <SelfieWrapper>
                    <Selfie sidebarOpen={!!currentSidebar.type} />
                </SelfieWrapper>
            </LeftWrapper>
            <CenterWrapper>
                <ButtonWrapper>
                    <ChatButton id='student-chat-button'
                        active={currentSidebar.type === 'chat'}
                        onClick={onChatClick}
                        disabled={!sessionJoined}
                        notifications={notificationsCount}
                    />
                    {lastMessage && sessionJoined ? (
                        <MessagePopup lastMessage={lastMessage} onMessageClick={onMessageClick} onCloseClick={e => onMessageCloseClick(e)} />
                    ) : null}
                </ButtonWrapper>
            </CenterWrapper>
            <RightWrapper />
        </Wrapper>
    )
}

export { BottomControls }
