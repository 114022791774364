import { UnitRating } from '../unit-ratings'
/* eslint-disable max-len */
export const mock: UnitRating[] = [
    {
        number: 2,
        ratingSchemaPartItems: [
            {
                id: 'cfe9afa5-6342-49f1-9b1d-8960719a4bf8',
                sequence: 1,
                name: '2.1 Can say their name',
                scoreType: 0,
                helpText: 'Note how well the Student can produce the target language and score 1-4 as below:\n1 Demonstrates no target language at all\n2 Only demonstrates target language with heavy prompting and modeling\n3 Demonstrates several examples of target language but with some errors\n4 Demonstrates target language comfortably with few and only minor errors'
            },
            {
                id: '78c3c045-ef6f-4a83-9a10-725e8a570d76',
                sequence: 2,
                name: '2.1 Can ask someone for their name',
                scoreType: 0,
                helpText: 'Note how well the Student can produce the target language and score 1-4 as below:\n1 Demonstrates no target language at all\n2 Only demonstrates target language with heavy prompting and modeling\n3 Demonstrates several examples of target language but with some errors\n4 Demonstrates target language comfortably with few and only minor errors'
            },
            {
                id: 'd3496460-e897-4150-8049-d44d7a073607',
                sequence: 3,
                name: '2.2 Can use some possessive adjectives',
                scoreType: 0,
                helpText: 'Note how well the Student can produce the target language and score 1-4 as below:\n1 Demonstrates no target language at all\n2 Only demonstrates target language with heavy prompting and modeling\n3 Demonstrates several examples of target language but with some errors\n4 Demonstrates target language comfortably with few and only minor errors'
            }
        ]
    },
    {
        number: 3,
        ratingSchemaPartItems: [
            {
                id: 'bbf82fe0-02ce-4d80-8588-cefbd01a7776',
                sequence: 1,
                name: '3.1 Can spell their name  and ask for the spelling of a word to be written down',
                scoreType: 0,
                helpText: 'Note how well the Student produces the target structure and score 1-4 as below:\n1 Does not demonstrate the target structure at all\n2 Only demonstrates the target structure after much prompting from the teacher\n3 Demonstrates target structure with hesitation and sometimes needs prompting\n4 Demonstrates target structure often and without prompting'
            },
            {
                id: 'a9be9f6e-b23d-4122-8004-4b7e65b5002b',
                sequence: 2,
                name: '3.2 Can use very basic polite stock phrases in a very limited way',
                scoreType: 0,
                helpText: 'Note how well the student produces the target structure and score 1-4 as below\n1 Does not demonstrate the target structure at all.\n2 Can only demonstrate the target structure after much prompting from the teacher.\n3 Demonstrates target structure with hesitation and sometimes needs prompting.\n4 Demonstrates target structure often and without prompting.'
            }
        ]
    },
    {
        number: 4,
        ratingSchemaPartItems: [
            {
                id: '3865ae46-85a3-44ea-91f0-d65cadb93171',
                sequence: 1,
                name: '4.0 Range',
                scoreType: 0,
                helpText: 'Give a general score 1-4 for range as below:\n1 Unable to produce any target language\n2 Produces some target language with much prompting and modeling\n3 Produces some target language without prompting or modeling\n4 Produces most target language with some prompting or modeling'
            },
            {
                id: '19f370da-9537-4535-b7a9-37c3a254d90d',
                sequence: 2,
                name: '4.0 Accuracy',
                scoreType: 0,
                helpText: 'Give a general score 1-4 for accuracy as below:\n1 Little/no control over structure; frequent mistakes\n2 Very limited control over structure\n3 Produces most structures with some prompting and modeling\n4 Controls target structures with some mistakes'
            },
            {
                id: '31b3c4b0-6f11-4fb5-915e-274d9df6352e',
                sequence: 3,
                name: '4.0 Quality of interaction',
                scoreType: 0,
                helpText: 'Give a general score 1-4 for quality of interaction as below:\n1 Unable to understand or ask any target questions\n2 Only understands or asks questions with much support and modelling\n3 Understands and answers basic questions but can only ask them with modeling\n4 Participates in structured exchanges, asking and answering target questions'
            },
            {
                id: 'eee20a55-df85-42f9-801f-bca9af2bafb4',
                sequence: 4,
                name: '4.0 Fluency and Spoken Production',
                scoreType: 0,
                helpText: 'Give a general score 1-4 for fluency and spoken production as below:\n1 Vocabulary too limited or pronunciation too weak to respond intelligibly\n2 Intelligibly says a few words but mostly unable to form coherent sentences\n3 Intelligibly says isolated words/phrases with pauses and some weak pronunciation \n4 Adequately says short/formulaic phrases; regular pronunciation errors'
            }
        ]
    }
]
