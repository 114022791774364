export const WiFiIcon: React.FC = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        fill='none'
    >
        <path
            fill='#77BF43'
            d='M18.284 10.708a8.353 8.353 0 0 1 1.504 2.085 1 1
             0 0 1-1.78.91 6.356 6.356 0 0 0-1.138-1.58 6.128 6.128 0 0 0-9.8 1.562
             1 1 0 0 1-1.784-.903 8.128 8.128 0 0 1 12.998-2.074zM16.235 13.7c.46.46.838
             1.024 1.102 1.624a1 1 0 0 1-1.832.803 3.359 3.359 0 0 0-.684-1.013 3.233
             3.233 0 0 0-4.572 0 3.257 3.257 0 0 0-.672 1 1 1 0 1 1-1.832-.802 5.25 5.25
             0 0 1 1.09-1.612 5.233 5.233 0 0 1 7.4 0zm4.684-5.401c.508.508.987 1.087
             1.404 1.691a1 1 0 0 1-1.646 1.136c-.35-.506-.752-.993-1.172-1.413-3.872-3.872-10.15-3.872-14.023
             0-.399.399-.797.886-1.16 1.41a1 1 0 0 1-1.644-1.14c.428-.618.901-1.195 1.39-1.684 4.653-4.654
             12.198-4.654 16.851 0zm-7.357 8.142a1.501 1.501 0 1 1-2.123 2.123 1.501 1.501 0 0 1 2.123-2.123z'
        />
    </svg>
)
