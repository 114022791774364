import { useState, useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/material'
import { ConferenceContext } from 'contexts/conference'
import { ASPECT_RATIO } from 'zoom/config'
import { ButtonWrapper } from 'components/buttons'
import { MuteIconButton, VideoIconButton } from 'components/icon-buttons'
import { useLayoutUpdate } from 'components/useLayoutUpdate'
import { useRenderVideo } from './useRenderVideo'
import { Avatar } from './Avatar'
import { Camera } from './Camera'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

const SelfieWrapper = styled('div')<{ showVideo: boolean }>(({ theme, showVideo }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    position: 'relative',
    width: theme.spacing(30),
    height: theme.spacing(30 / ASPECT_RATIO),
    overflow: 'hidden',
    backgroundColor: showVideo ? 'transparent' : theme.palette.neutral.darker,
    willChange: 'background-color',
    transition: 'background-color 0.2s ease-out'
}))

const SelfieControls = styled('div')(({ theme }) => ({
    width: '100%',
    height: theme.spacing(7),
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    display: 'flex'
}))

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement='top' disableHoverListener open={props.open} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#000000f2'
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#000000f2',
        borderRadius: '12px',
        marginBottom: '8px !important',
        padding: '8px 16px',
        fontSize: '14px'
    }
}))

const Selfie: React.FC<{ sidebarOpen: boolean }> = ({ sidebarOpen }) => {
    const location = useLocation()
    const conference = useContext(ConferenceContext)
    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const self = conference.participants[0]
    const [videoVisible, setVideoVisibility] = useState<boolean>(true)
    useRenderVideo(self, wrapperRef.current)
    const [open, setOpen] = useState<boolean>(false)
    const [isHardMuted, setIsHardMuted] = useState(false)
    const allowAudio = conference.status === 'session-joined' && conference.allowAudio

    const showSelfie = (): void => setVideoVisibility(true)
    const hideSelfie = (): void => setVideoVisibility(false)

    const {
        triggerLayoutUpdate,
        addLayoutUpdateStartListener,
        addLayoutUpdateEndListener,
        removeLayoutUpdateStartListener,
        removeLayoutUpdateEndListener
    } = useLayoutUpdate()

    useEffect(() => {
        const resObs = new ResizeObserver(triggerLayoutUpdate)
        resObs.observe(document.body)

        addLayoutUpdateStartListener(hideSelfie)
        addLayoutUpdateEndListener(showSelfie)
        return () => {
            removeLayoutUpdateStartListener(hideSelfie)
            removeLayoutUpdateEndListener(showSelfie)
        }
    }, [])

    const handleMuteClick = (): void => {
        if (conference.status === 'session-joined') {
            if (self.role === 'student' && !conference.allowAudio) {
                if (!isHardMuted) {
                    setOpen(true)
                    setIsHardMuted(true)
                    // Removed the tooltip after 8 seconds
                    setTimeout(() => {
                        setOpen(false)
                        setIsHardMuted(false)
                    }, 8000)
                }
            } else {
                void conference.rtcSession.setAudioMuted(!conference.isAudioMuted)
                open && setOpen(false)
            }
        }
    }

    useEffect(() => {
        if (conference.status === 'session-joined') {
            if (self.role === 'student' && !conference.allowAudio) {
                setOpen(true)
                // Removed the tooltip after 8 seconds
                setTimeout(() => {
                    setOpen(false)
                }, 8000)
            } else {
                open && setOpen(false)
            }
        }
    }, [allowAudio])

    useEffect(() => hideSelfie(), [sidebarOpen, location?.pathname])

    const showVideo = conference.status !== 'session-joined' || (videoVisible && self?.cameraStatus === 'on')
    return <>
        <Camera />
        <CustomTooltip title='The teacher muted you' open={open}>
            <SelfieWrapper ref={wrapperRef} id='selfie-wrapper' showVideo={showVideo}>
                {conference.status === 'session-joined' ? (
                    <>
                        <Avatar showVideo={showVideo} />
                        <SelfieControls>
                            <ButtonWrapper sx={{ marginRight: 'auto' }}>
                                <MuteIconButton
                                    onClick={handleMuteClick}
                                    active={conference.isAudioMuted}
                                    size='medium'
                                />
                            </ButtonWrapper>
                            <ButtonWrapper sx={{ marginLeft: 'auto' }}>
                                <VideoIconButton
                                    id='selfie-camera-toggle'
                                    onClick={() => void conference.rtcSession.setVideoMuted(!conference.isVideoMuted)}
                                    disabled={!conference.allowVideo}
                                    active={conference.isVideoMuted}
                                    size='medium'
                                />
                            </ButtonWrapper>
                        </SelfieControls>
                    </>
                ) : null}
            </SelfieWrapper>
        </CustomTooltip>
    </>
}

export { Selfie }
