import { useContext, useState } from 'react'
import { Form } from 'react-router-dom'
import { styled, FormControl, Link, List, ListItem } from '@mui/material'
import { useQuickslot } from './useQuickslot'
import { BookingContext } from 'contexts/booking'
import { StandardButton } from 'components/buttons'

const Wrapper = styled('div')(({ theme }) => ({
    padding: '0 var(--page-side-margin)'
}))
const FormWrapper = styled('div')(({ theme }) => ({
    padding: `var(--page-v-margin) ${theme.spacing(0)}`,
    textAlign: 'left'
}))
const LinksWrapper = styled('div')``
const LinkWrapper = styled('div')(({ theme }) => ({
    width: '8rem'
}))

const Quickslot: React.FC = () => {
    const booking = useContext(BookingContext)

    const [copiedLink, setCopiedLink] = useState<ClipboardItem>()
    const [links, allLinks] = useQuickslot()

    const allLinksCopied = allLinks && allLinks === copiedLink

    return (
        <Wrapper>
            <FormWrapper>
                <Form>
                    <FormControl sx={{ padding: '6px 0', marginRight: 2 }}>
                        <StandardButton
                            disabled={booking.status === 'checking'}
                            text='Generate classroom booking'
                            color='primary'
                            onClick={ev => booking.generateQuickslots(60)}
                        />
                    </FormControl>
                    <FormControl sx={{ padding: '6px 0' }}>
                        <StandardButton
                            disabled={ !allLinks }
                            color={ allLinksCopied ? 'primary' : 'secondary' }
                            text={ allLinksCopied ? 'All copied' : 'Copy all' }
                            onClick={() => {
                                allLinks && navigator.clipboard.write([allLinks]).then(() => {
                                    setCopiedLink(allLinks)
                                }).catch(console.error)
                            }}
                        />
                    </FormControl>
                </Form>
            </FormWrapper>

            <LinksWrapper>
                <div>
                    <span>
                        Activity Id: {booking.status === 'validated' ? booking.classroomId : 'n/a'}
                    </span>

                </div>
                <List>
                    {links.map(link => (
                        <ListItem key={link.user}>

                            <LinkWrapper>
                                <Link href={link.url}>{link.user}</Link>
                            </LinkWrapper>

                            <StandardButton
                                color={ link.clipboardItem === copiedLink ? 'primary' : 'secondary' }
                                text={ link.clipboardItem === copiedLink ? 'Copied' : 'Copy' }
                                onClick={() => {
                                    navigator.clipboard.write([link.clipboardItem]).then(() => {
                                        setCopiedLink(link.clipboardItem)
                                    }).catch(console.error)
                                }}
                            />

                        </ListItem>
                    ))}
                </List>
            </LinksWrapper>
        </Wrapper>
    )
}

export { Quickslot }
