import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { BookingContext } from 'contexts/booking'
import { Rating, saveRatingItem } from 'api/unit-assessment'
import { AssessmentContext, StudentWithScores } from 'contexts/assessment'
import { SavingCallbacks } from './useSavingStatus'

export type Score = {
    student: StudentWithScores
    rating: Rating
    value: number
}

export const useSaveScore = (saving: () => SavingCallbacks): [Score | undefined, Dispatch<SetStateAction<Score | undefined>>] => {
    const assessment = useContext(AssessmentContext)
    const booking = useContext(BookingContext)

    const [score, setScore] = useState<Score>()

    useEffect(() => {
        if (score && booking.status === 'validated' && assessment.status === 'encounter') {
            score.student.scores.set(score.rating.id, score.value)

            const [done, error] = saving()
            saveRatingItem(booking.auth, booking.classroomId, {
                classId: assessment.classDetails.classId,
                classUnitId: assessment.unitAssessment.unit.id,
                number: score.value,
                studentId: score.student.userId,
                referenceClassId: '',
                result: score.value === 0 ? null : score.value,
                teacherId: assessment.classDetails.teacherId,
                ratingSchemaPartItemId: score.rating.id

            })
                .then(done)
                .catch(e => {
                    score.student.scores.delete(score.rating.id)
                    score.student.finalScore = void 0
                    error(e)
                })

            setScore(void 0)
        }
    }, [score])

    return [score, setScore]
}
