import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { ActivityType, ONLINE_ENCOUNTER, SOCIAL_CLUB } from 'api/classroom/ClassroomState'
import { ClassDetails, getClassDetails, getClassResults } from 'api/unit-assessment'
import { Student } from 'api/unit-assessment/class-details'
import { Guid } from 'api/unit-assessment/types'
import { BookingContext } from 'contexts/booking'
import { ConferenceContext } from 'contexts/conference'
import { getUnitAssessment, UnitAssessment } from './get-unit-assessment'
import { restoreRatings } from './restore-ratings'

export { useSavingStatus } from './useSavingStatus'
export type { SavingCallbacks, SavingStatus } from './useSavingStatus'
export { useSaveScore } from './useSaveScore'
export type { Score } from './useSaveScore'
export type { ActivityRating, TaskRating } from './group-ratings'
export type { UnitAssessment }

export type StudentWithScores = Student & {
    scores: Map<Guid, number>
    finalScore?: number
    comment: string
    contentItemResultType?: number | undefined
    isGraded: boolean
    isScoreOrResultChanged: boolean
    isScoreAndResultAvailableInAPI: boolean
    isNoShowTechResultsSelected: boolean
    isActivitiesCommentsDisabled: boolean
}
export type AssessmentState = {
    status: 'waiting-for-session-join'
} | {
    status: 'class-details-loaded'
    activityType: ActivityType
    classDetails: ClassDetails
    students: StudentWithScores[]
} | {
    unitAssessment: UnitAssessment
    activityType: ActivityType
    classDetails: ClassDetails
    students: StudentWithScores[]
    status: 'encounter'
} | {
    activityType: ActivityType
    classDetails: ClassDetails
    students: StudentWithScores[]
    status: 'non-encounter'
} | {
    status: 'student'
}
export const AssessmentContext = createContext<AssessmentState>({
    status: 'waiting-for-session-join'
})

export const Assessment: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setState] = useState<AssessmentState>({
        status: 'waiting-for-session-join'
    })

    const conference = useContext(ConferenceContext)
    const booking = useContext(BookingContext)

    useEffect(() => {
        const isNseSession = sessionStorage.getItem('isTeensClass') !== '' && sessionStorage.getItem('isTeensClass') !== undefined
        if (conference.status === 'session-joined' && booking.status === 'validated' && isNseSession) {
            if (state.status === 'waiting-for-session-join') {
                if (booking.user.role === 'teacher') {
                    void getClassDetails(booking.auth, conference.classroomStateOnJoin.id).then(classDetails => {
                        const students: StudentWithScores[] = classDetails.bookedStudents.map(booking => ({
                            firstName: booking.student.firstName,
                            lastName: booking.student.lastName,
                            photoUris: booking.student.photoUris,
                            scores: new Map<string, number>(),
                            userId: booking.student.userId,
                            comment: '',
                            isGraded: false,
                            isScoreOrResultChanged: false,
                            isScoreAndResultAvailableInAPI: false,
                            isNoShowTechResultsSelected: false,
                            isActivitiesCommentsDisabled: false
                        }))

                        void getClassResults(booking.auth, booking.classroomId).then(ratings => {
                            restoreRatings(ratings, students)

                            setState({
                                activityType: conference.classroomStateOnJoin.activityType,
                                status: 'class-details-loaded',
                                classDetails,
                                students
                            })
                        })
                    })

                } else {
                    setState({ status: 'student' })
                }

            } else if (state.status === 'class-details-loaded') {
                const unit = conference.classroomStateOnJoin.unit
                if (state.activityType === ONLINE_ENCOUNTER) {
                    if (unit) {
                        void getUnitAssessment(booking.auth, process.env.REACT_APP_ASSESSMENT_UNIT_ID ?? unit.id).then(unitAssessment => {
                            setState(current => {
                                if (current.status === 'class-details-loaded') {
                                    return {
                                        classDetails: current.classDetails,
                                        activityType: current.activityType,
                                        students: current.students,
                                        status: 'encounter',
                                        unitAssessment
                                    }
                                } else {
                                    return current
                                }
                            })
                        })
                    }

                } else if (state.activityType === SOCIAL_CLUB) {
                    setState(current => {
                        if (current.status === 'class-details-loaded') {
                            return {
                                classDetails: current.classDetails,
                                activityType: current.activityType,
                                students: current.students,
                                status: 'non-encounter'
                            }
                        } else {
                            return current
                        }
                    })
                }
            }
        }
    }, [conference.status, booking.status, state.status])

    return <AssessmentContext.Provider value={state}>{children}</AssessmentContext.Provider>
}
