import { Dispatch, RefObject, SetStateAction, useContext, useEffect, useState } from 'react'
import { SlideDeckNavigationController } from 'ispring'
import { AnnotationService, Tool } from 'annotation-tool/AnnotationService'
import { ConferenceContext } from 'contexts/conference'

type AnnotationsCanvasParams = {
    width: number
    height: number
}
type UseAnnotationsType = (
    wrapperRef: RefObject<HTMLDivElement>,
    annotationsCanvasRef: RefObject<HTMLCanvasElement>,
    navigationController?: SlideDeckNavigationController,
    textBoxRef?: RefObject<HTMLDivElement>,
    textAreaRef?: RefObject<HTMLTextAreaElement>,
) => {
    annotationsCanvasParams: AnnotationsCanvasParams
    activeTool: Tool | null
    setActiveTool: Dispatch<SetStateAction<Tool | null>>
    clearAll: () => void
}

const useAnnotations: UseAnnotationsType = (wrapperRef, annotationsCanvasRef, navigationController, textBoxRef, textAreaRef) => {
    const conference = useContext(ConferenceContext)
    const [activeTool, setActiveTool] = useState<Tool | null>(null)
    const [annotationService, setAnnotationService] = useState<AnnotationService>()
    const [annotationsCanvasParams, setAnnotationsCanvasParams] = useState<AnnotationsCanvasParams>({ width: 0, height: 0 })
    const clearAll = (): void => annotationService?.clearAll()

    useEffect(() => {
        if (wrapperRef.current) {
            const onResize = (): void => {
                if (wrapperRef.current) {
                    const wrapperHeight = wrapperRef.current.clientHeight
                    const wrapperWidth = wrapperRef.current.clientWidth
                    const adjustedWidth = Math.round((wrapperHeight / 9) * 16)
                    const adjustedHeight = Math.round((wrapperWidth / 16) * 9)
                    const width = wrapperWidth >= adjustedWidth ? adjustedWidth : wrapperWidth
                    const height = wrapperHeight >= adjustedHeight ? adjustedHeight : wrapperHeight

                    setAnnotationsCanvasParams({ width, height })
                }
            }

            const resObs = new ResizeObserver(onResize)
            resObs.observe(wrapperRef.current)
        }
    }, [wrapperRef.current])

    useEffect(() => {
        if (annotationsCanvasRef.current && annotationService) {
            annotationService.renderCurrentSlideAnnotations()
        }
    }, [annotationsCanvasRef.current?.width, annotationsCanvasRef.current?.height, annotationService])

    useEffect(() => {
        if (annotationsCanvasRef.current && conference.status === 'session-joined') {
            const canvas = annotationsCanvasRef.current
            const boardInitialState = conference.slideAnnotations || conference.classroomStateOnJoin.dcAppData.wbData.boards[0].mount
            const service = new AnnotationService(canvas, conference.primus, boardInitialState,
                textBoxRef?.current ?? null, textAreaRef?.current ?? null, conference.setSlideAnnotation)

            setAnnotationService(service)
        }
    }, [annotationsCanvasRef.current])

    useEffect(() => {
        if (annotationService) {
            annotationService.registerPrimusEvents()
            const activeSlideChangeHandler = (slideIndex: number, _: number): void => annotationService.setCurrentSlide(slideIndex)
            if (navigationController) {
                annotationService.setCurrentSlide(navigationController.getCurrentSlideIndex())
                const removeStepEventHandler = navigationController.addStepEventHandler(activeSlideChangeHandler)

                return () => removeStepEventHandler()
            }
        }
    }, [navigationController, annotationService])

    useEffect(() => {
        if (annotationService) {
            annotationService.registerMouseEvents()

            return () => annotationService.unregisterMouseEvents()
        }
    }, [annotationService])

    useEffect(() => {
        annotationService?.setActiveTool(activeTool)
    }, [activeTool, annotationService])

    return { annotationsCanvasParams, activeTool, setActiveTool, clearAll }
}

export { useAnnotations }
