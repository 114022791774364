import { IconButton, IconButtonProps } from '@mui/material'
import { LineChevronRight, ClearAllIcon, EraserIcon, TextBoxIcon, PenIcon } from 'icons'

interface NavigationButtonProps {
    id: string
    onClick: IconButtonProps['onClick']
}
type PresentationButtonProps = {
    id?: string
    icon?: string
    flipIcon?: boolean
    onClick?: IconButtonProps['onClick']
    active?: boolean
}

const PresentationButton: React.FC<PresentationButtonProps> = ({ id, icon, flipIcon, onClick, active }) => (
    <IconButton
        id={id}
        onClick={onClick}
        sx={theme => ({
            backgroundColor: active ? theme.palette.neutral.hover : theme.palette.secondary.darker,
            height: theme.spacing(4),
            width: theme.spacing(4),
            transform: flipIcon ? 'scaleX(-1)' : 'none',
            willChange: 'background-color',
            transition: 'background-color 0.2s ease-out'
        })}
    >
        <img src={icon} />
    </IconButton>
)
const PreviousStepButton: React.FC<NavigationButtonProps> = ({ id, onClick }) => (
    <PresentationButton id={id} icon={LineChevronRight} flipIcon={true} onClick={onClick} />
)
const NextStepButton: React.FC<NavigationButtonProps> = ({ id, onClick }) => (
    <PresentationButton id={id} icon={LineChevronRight} onClick={onClick} />
)
const TextButton: React.FC<PresentationButtonProps> = ({ id, onClick, active }) => (
    <PresentationButton id={id} icon={TextBoxIcon} onClick={onClick} active={active} />
)
const PenButton: React.FC<PresentationButtonProps> = ({ id, onClick, active }) => (
    <PresentationButton id={id} icon={PenIcon} onClick={onClick} active={active} />
)
const EraserButton: React.FC<PresentationButtonProps> = ({ id, onClick, active }) => (
    <PresentationButton id={id} icon={EraserIcon} onClick={onClick} active={active} />
)
const ClearAllButton: React.FC<PresentationButtonProps> = ({ id, onClick, active }) => (
    <PresentationButton id={id} icon={ClearAllIcon} onClick={onClick} active={active} />
)

export { PreviousStepButton, NextStepButton, TextButton, PenButton, EraserButton, ClearAllButton }
