import { useRef } from 'react'
import { styled } from '@mui/material'
import { useCanvasResize } from './useCanvasResize'
import { useFeatureCheck } from 'hooks/useFeatureCheck'
import { ASPECT_RATIO } from 'zoom/config'

const ZoomWrapper = styled('div')<{
    isFullFeaturedMode: boolean
    top?: number
    height?: number
    isSpeakMode?: boolean
}>(({ theme, isFullFeaturedMode, top, height, isSpeakMode }) => ({
    position: 'absolute',
    zIndex: -1,
    top: !isFullFeaturedMode && top ? top : 0,
    left: !isFullFeaturedMode ? 'var(--page-side-margin)' : 0,
    width: !isFullFeaturedMode ? 'calc(100% - var(--page-side-margin) * 2)' : '100%',
    height: !isFullFeaturedMode && height ? (isSpeakMode ? `calc(100vh - ${height}px)` : `calc(${height}px - var(--page-side-margin))`) : '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: isSpeakMode ? theme.spacing(50) : 'auto',
    [theme.breakpoints.down('md')]: {
        minHeight: isSpeakMode ? theme.spacing(40) : 'auto'
    }
}))
const ZoomPlayground = styled('div')<{ isFullFeaturedMode: boolean }>(({ theme, isFullFeaturedMode }) => ({
    height: isFullFeaturedMode ? '100%' : `calc((100vw - 2 * var(--page-side-margin) - 2px) / ${ASPECT_RATIO})`,
    maxHeight: '100%',
    width: isFullFeaturedMode ? '100%' : 'auto',
    aspectRatio: `${ASPECT_RATIO}`,
    borderRadius: isFullFeaturedMode ? 0 : theme.spacing(2.5),
    overflow: 'hidden',
    canvas: {
        borderRadius: isFullFeaturedMode ? 0 : theme.spacing(2.5),
        overflow: 'hidden',
        willChange: 'opacity',
        transition: 'opacity 0.2s ease-out'
    }
}))

const ZoomArea: React.FC<{
    sidebarOpen: boolean
    top?: number
    height?: number
    isSpeakMode?: boolean
}> = ({ sidebarOpen, top, height, isSpeakMode }) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    useCanvasResize(wrapperRef, canvasRef, sidebarOpen)
    const { isFullFeaturedMode } = useFeatureCheck()

    return (
        <ZoomWrapper isFullFeaturedMode={isFullFeaturedMode} top={top} height={height} isSpeakMode={isSpeakMode}>
            <ZoomPlayground ref={wrapperRef} isFullFeaturedMode={isFullFeaturedMode}>
                <canvas id='zoom-canvas' ref={canvasRef} width='1' height='1' />
            </ZoomPlayground>
        </ZoomWrapper>
    )
}

export { ZoomArea }
