import { InnerWrapper } from 'components/CustomModal'
import { RectangleButton } from 'components/buttons'
import { BlueCancelIcon } from 'icons'
import { LogoContainer } from 'pages/logoContainer/LogoContainer'
import { useNavigateBackDisabled } from 'pages/useNavigateBackDisabled'
import { Title, Message } from 'pages/thankyouscreen/ThankYouScreen'
import { styled } from '@mui/material'
import { ExpelStudentScreenContent } from 'utils/constants'

const CustomInnerWrapper = styled(InnerWrapper)(() => ({
    textAlign: 'center',
    maxWidth: '920px'
}))
const ExpelMessageWrapper = styled('div')(() => ({
    marginTop: '24px',
    marginBottom: '72px',
    textAlign: 'center'
}))

const ExpelScreen: React.FC = () => {
    useNavigateBackDisabled()
    const coreCourseDashboardUrl = sessionStorage.getItem('callbackUrl')!

    const goBackToDashboard = (): void => {
        window.location.href = coreCourseDashboardUrl
    }

    return (
        <LogoContainer>
            <CustomInnerWrapper>
                <img src={BlueCancelIcon} />
                <ExpelMessageWrapper>
                    <Title id='expel-Title-Msg'>{ExpelStudentScreenContent.title}</Title>
                    <Message>
                        {ExpelStudentScreenContent.description}
                    </Message>
                </ExpelMessageWrapper>
                <RectangleButton
                    id='book-your-next-class-button'
                    color='error'
                    onClick={goBackToDashboard}
                    text={ExpelStudentScreenContent.buttonName}
                />
            </CustomInnerWrapper>
        </LogoContainer>
    )
}
export { ExpelScreen }
