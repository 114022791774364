import { styled } from '@mui/material'

const ContentWrapper = styled('div')<{ sidebarOpen: boolean }>(({ theme, sidebarOpen }) => ({
    marginLeft: 'var(--page-side-margin)',
    marginRight: sidebarOpen ? 0 : 'var(--page-side-margin)',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    flex: 2
}))

export { ContentWrapper }
