export type LayoutUpdateEventListener = () => void
export const updateTimeout = 200

const inProgressHandlers = new Set<LayoutUpdateEventListener>()
const startHandlers = new Set<LayoutUpdateEventListener>()
const endHandlers = new Set<LayoutUpdateEventListener>()

const removeLayoutUpdateInProgressListener: (f: LayoutUpdateEventListener) => void = f => inProgressHandlers.delete(f)
const addLayoutUpdateInProgressListener: (f: LayoutUpdateEventListener) => void = f => inProgressHandlers.add(f)
const removeLayoutUpdateStartListener: (f: LayoutUpdateEventListener) => void = f => startHandlers.delete(f)
const addLayoutUpdateStartListener: (f: LayoutUpdateEventListener) => void = f => startHandlers.add(f)
const removeLayoutUpdateEndListener: (f: LayoutUpdateEventListener) => void = f => endHandlers.delete(f)
const addLayoutUpdateEndListener: (f: LayoutUpdateEventListener) => void = f => endHandlers.add(f)

let timeoutId: NodeJS.Timeout
let isInProgress = false

const over = (): void => {
    isInProgress = false
    for (const handler of endHandlers.values()) {
        handler()
    }
}

const triggerLayoutUpdate = (): void => {
    for (const handler of inProgressHandlers.values()) {
        handler()
    }

    if (isInProgress) {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(over, updateTimeout)

    } else {
        for (const handler of startHandlers.values()) {
            handler()
        }

        isInProgress = true
        timeoutId = setTimeout(over, updateTimeout)
    }
}

export type UseLayoutUpdate = {
    removeLayoutUpdateInProgressListener: (f: LayoutUpdateEventListener) => void
    removeLayoutUpdateStartListener: (f: LayoutUpdateEventListener) => void
    removeLayoutUpdateEndListener: (f: LayoutUpdateEventListener) => void
    addLayoutUpdateInProgressListener: (f: LayoutUpdateEventListener) => void
    addLayoutUpdateStartListener: (f: LayoutUpdateEventListener) => void
    addLayoutUpdateEndListener: (f: LayoutUpdateEventListener) => void
    triggerLayoutUpdate: () => void
}

export const useLayoutUpdate = (): UseLayoutUpdate => {
    return {
        removeLayoutUpdateInProgressListener,
        removeLayoutUpdateStartListener,
        removeLayoutUpdateEndListener,
        addLayoutUpdateInProgressListener,
        addLayoutUpdateStartListener,
        addLayoutUpdateEndListener,
        triggerLayoutUpdate
    }
}
