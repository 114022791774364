import { ReactNode, useEffect, useReducer } from 'react'
import { createQuickslot } from 'api/classroom/quickslot'
import { checkBooking } from 'api/auth/check'
import { qsLogin } from 'api/auth/qslogin'
import { isGocException } from 'exceptions'
import { BookingContext, initialState } from './BookingContext'
import { reducer } from './BookingReducer'
import { ClassroomId, WseUserId } from 'api/types'

const Booking: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer(), {
        ...initialState,

        clearSession: () => {
            dispatch({ method: 'clear-session' })
        },

        restoreBooking: (auth: string, classroomId: ClassroomId, userId: WseUserId, isTeens: string = '', callbackUrl: string) => {
            dispatch({
                method: 'set-status',
                status: 'checking'
            })

            checkBooking(auth, classroomId, userId).then(check => {
                if (check.user.expelled) {
                    dispatch({ method: 'set-status', status: 'expelled' })

                } else {
                    dispatch({
                        method: 'set-classroom',
                        check,
                        classroomId,
                        userId,
                        auth,
                        isTeens,
                        callbackUrl
                    })
                }

            }).catch(e => {
                if (isGocException(e) && e.message === 'The session has expired') {
                    dispatch({ method: 'set-status', status: 'expired' })

                } else if (isGocException(e) && e.message === 'Activity already ended by teacher') {
                    dispatch({ method: 'set-status', status: 'ended' })

                } else {
                    dispatch({ method: 'set-status', status: 'invalid' })
                    console.error(e)
                }
            })
        },
        generateQuickslots: (sessionDuration = 60) => {
            dispatch({
                method: 'set-status',
                status: 'checking'
            })

            qsLogin()
                .then(async auth => {
                    const qs = await createQuickslot(auth, sessionDuration)

                    dispatch({
                        check: await checkBooking(auth, qs.id, 'UTEACHER'),
                        method: 'set-classroom',
                        userId: 'UTEACHER',
                        classroomId: qs.id,
                        auth,
                        isTeens: '',
                        callbackUrl: sessionStorage.getItem('callbackUrl') || process.env.REACT_APP_CORE_COURSE_WEB_URL!
                    })
                })
                .catch(e => {
                    dispatch({ method: 'set-status', status: 'invalid' })
                    console.error(e)
                })
        }
    })

    useEffect(() => {
        const classroomId = sessionStorage.getItem('classroomId')
        const userId = sessionStorage.getItem('userId')
        const auth = sessionStorage.getItem('auth')
        const isTeens = sessionStorage.getItem('isTeensClass') || ''
        const callbackUrl = sessionStorage.getItem('callbackUrl')

        if (classroomId && userId && auth && callbackUrl) {
            state.restoreBooking(auth, classroomId, userId, isTeens, callbackUrl)

        } else {
            dispatch({ method: 'set-status', status: 'no-session' })
        }
    }, [])

    return (
        <BookingContext.Provider value={state}>
            {children}
        </BookingContext.Provider>
    )
}

export { Booking }
