import { ClassroomId } from 'api/types'
import { apiRequest } from '../api-request'
import { Guid } from './types'

type RatingItem = {
    studentId: Guid
    referenceClassId: string
    classId: Guid
    teacherId: Guid
    ratingSchemaPartItemId: Guid
    result: number | null
    number: number
    classUnitId: Guid
}

const saveRatingItem = async (auth: string, activityId: ClassroomId, rating: RatingItem): Promise<void> => {
    if (!process.env.REACT_APP_USE_ASSESSMENT_MOCKS) {
        const payload: RatingItem = {
            ...rating,
            classId: process.env.REACT_APP_ACTIVITY_ID ?? rating.classId
        }
        return await apiRequest<undefined>({
            baseUrl: process.env.REACT_APP_BS_API_URL,
            path: `/classes/${process.env.REACT_APP_ACTIVITY_ID ?? activityId}/classPartialResults`,
            method: 'POST',
            auth: process.env.REACT_APP_QUALWORLD_AUTH ?? auth,
            payload
        })
    } else {
        return new Promise(resolve => setTimeout(resolve, 300))
    }
}

export type { RatingItem }
export { saveRatingItem }
