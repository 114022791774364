import { NavButton } from './NavButton'
import { SpeakIcon, SpeakActiveIcon } from 'icons'

const SpeakButton: React.FC<{ id: string; disabled?: boolean }> = ({ id, disabled }) => (
    <NavButton
        id={id}
        text='Speak'
        path='/teacher/classroom'
        icon={SpeakIcon}
        activeIcon={SpeakActiveIcon}
        disabled={disabled}
    />
)

export { SpeakButton }
