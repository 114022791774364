import { CommonIconButtonProps, StandardIconButton } from './StandardIconButton'
import { SettingsIcon, SettingsActiveIcon } from 'icons'

const SettingsIconButton: React.FC<CommonIconButtonProps> = ({ id, active, disabled, size = 'medium', onClick }) => (
    <StandardIconButton
        id={id}
        color={active ? 'primary' : 'secondary'}
        icon={active ? SettingsActiveIcon : SettingsIcon}
        disabled={disabled}
        onClick={onClick}
        size={size}
    />
)

export { SettingsIconButton }
