import { styled } from '@mui/material'

type IconProps = { src: string; size?: IconSize }
type IconSize = 'small' | 'medium' | 'large'

const SmallIcon = styled('img')(({ theme }) => ({
    height: theme.spacing(2),
    width: theme.spacing(2)
}))
const MediumIcon = styled('img')(({ theme }) => ({
    height: theme.spacing(3),
    width: theme.spacing(3)
}))
const LargeIcon = styled('img')(({ theme }) => ({
    height: theme.spacing(4),
    width: theme.spacing(4)
}))

const Icon: React.FC<IconProps> = ({ src, size = 'medium' }) => {
    switch (size) {
        case 'large':
            return <LargeIcon src={src} data-testid='large-icon' />

        case 'medium':
            return <MediumIcon src={src} data-testid='medium-icon' />

        case 'small':
            return <SmallIcon src={src} data-testid='small-icon' />
    }
}

export type { IconSize, IconProps }
export { Icon }
