import { SnackbarContent, styled } from '@mui/material'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import { RectangleButton } from 'components/buttons'

type CustomizedSnackBarProps = {
    open: boolean
    anchorOrigin: SnackbarOrigin
    messageTitle?: string
    message?: string
    isActionNeeded?: boolean
    Icon?: React.ComponentType
}

const IconContainer = styled('span')(() => ({
    width: '48px',
    height: '48px',
    minWidth: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: 'white',
    marginRight: '16px'
}))

const CustomizedSnackBar: React.FC<CustomizedSnackBarProps> = (
    { open, anchorOrigin, messageTitle, message, isActionNeeded, Icon }) => {

    return (
        <Snackbar
            anchorOrigin={anchorOrigin}
            open={open}
            sx={{
                pointerEvents: 'all',
                '.MuiSnackbarContent-message': {
                    paddingTop: '2px',
                    paddingBottom: '2px'
                }
            }}>
            <SnackbarContent sx={{
                width: '100%',
                fontSize: '14px',
                borderRadius: '16px',
                boxShadow: '0 30px 60px 0 var(--black-hover-overlay-8), 0 2px 5px 0 rgba(0, 0, 0, 0.02)',
                background: '#000',
                '.MuiSnackbarContent-action': {
                    paddingLeft: '12px',
                    marginRight: '0'
                }
            }}
            action={
                isActionNeeded && <RectangleButton
                    id='refresh-button'
                    text='Refresh'
                    color='error'
                    onClick={() => window.location.reload()}
                    height='40px'
                />
            }
            message={
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    { Icon ? <IconContainer><Icon /></IconContainer> : null }
                    <div style={{ fontSize: '14px' }}>
                        { messageTitle ? <span style={{ fontWeight: '800', marginRight: '4px' }}>{messageTitle}</span> : null }
                        { message ? <span>{message}</span> : null }
                    </div>
                </div>
            } />
        </Snackbar>
    )
}

export { CustomizedSnackBar }
