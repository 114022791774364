import { styled } from '@mui/material'
import { PresentationContainer } from './components/SlidesPresentation'

const CueCardContainer = styled('div')(() => ({
    width: '100%',
    height: '70%',
    position: 'relative'
}))

const CueCardImage = styled('img')(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    border: 'none',
    pointerEvents: 'none',
    userSelect: 'none'
}))

type CueCardProps = {
    cueCardImage?: string
}

const CueCards: React.FC<CueCardProps> = ({ cueCardImage }) => {
    return (
        <CueCardContainer id='student-cuecard-container'>
            <PresentationContainer sx={{
                '&:after': {
                    borderRadius: '12px'
                }
            }}>
                <CueCardImage src={cueCardImage} alt='Cue Card Image' />
            </PresentationContainer>
        </CueCardContainer>
    )
}

export { CueCards }
