import { Button, IconButton, ButtonProps } from '@mui/material'
import { CloseIcon, SpeakIcon, SpeakActiveIcon, CueCardsIcon, CueCardsActiveIcon, SlidesActiveIcon, SlidesIcon } from 'icons'
import { StandardButton } from 'components/buttons'

type BORButtonProps = {
    id?: string
    icon?: string
    activeIcon?: string
    active?: boolean
    disabled?: boolean
    text?: string
    onClick?: ButtonProps['onClick']
    variant?: 'contained' | 'outlined'
}

const CloseButton: React.FC<BORButtonProps> = ({ id, onClick }) => (
    <IconButton
        id={id}
        onClick={onClick}
        sx={theme => ({
            backgroundColor: 'transparent',
            height: theme.spacing(3),
            width: theme.spacing(3),
            marginLeft: theme.spacing(1),
            padding: 0,
            overflow: 'hidden',
            img: {
                width: theme.spacing(3.75),
                maxWidth: 'none'
            }
        })}
    >
        <img src={CloseIcon} />
    </IconButton>
)
const SpeakButton: React.FC<BORButtonProps> = ({ id, active, onClick }) => (
    <StandardButton
        id={id}
        color={active ? 'primary' : 'secondary'}
        iconSrc={active ? SpeakActiveIcon : SpeakIcon}
        onClick={onClick}
        text='Speak'
    />
)
const CueCardsButton: React.FC<BORButtonProps> = ({ id, active, onClick }) => (
    <StandardButton
        id={id}
        color={active ? 'primary' : 'secondary'}
        iconSrc={active ? CueCardsActiveIcon : CueCardsIcon}
        onClick={onClick}
        text='Cue Cards'
    />
)
const SlidesButton: React.FC<BORButtonProps> = ({ id, active, onClick }) => (
    <StandardButton
        id={id}
        color={active ? 'primary' : 'secondary'}
        iconSrc={active ? SlidesActiveIcon : SlidesIcon}
        onClick={onClick}
        text='Slides'
    />
)
const StepButton: React.FC<BORButtonProps> = ({ id, text, onClick, variant, disabled }) => (
    <Button
        id={id}
        onClick={onClick}
        variant={variant ?? 'contained'}
        disabled={disabled}
        disableElevation
        color='error'
        sx={theme => ({
            borderRadius: theme.spacing(0.5),
            borderWidth: '1px !important'
        })}
    >
        {text}
    </Button>
)

export { CloseButton, SpeakButton, CueCardsButton, StepButton, SlidesButton }
