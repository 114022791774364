export const WiFiWarningIcon: React.FC = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        fill='none'
    >
        <path
            fill='#E22038'
            d='M17.784 10.708c.119.119.235.243.347.37a2.517 2.517 0 0 0-2.306.56 6.129
            6.129 0 0 0-9.255 2.047 1 1 0 0 1-1.784-.903 8.128 8.128 0 0 1
            12.998-2.074zm-5.829 8.295 1.218-2.437a1.501 1.501 0 1 0-1.218 2.437zm2.073-4.149.905-1.812a5.234
            5.234 0 0 0-6.598.658 5.25 5.25 0 0 0-1.09 1.613 1 1 0 1 0 1.832.801c.167-.381.394-.722.672-1a3.233
            3.233 0 0 1 4.28-.26zM20.42 8.3c.508.508.987 1.087 1.404 1.691a1 1 0 0 1-1.646
            1.136c-.35-.506-.752-.993-1.172-1.413-3.872-3.872-10.15-3.872-14.023 0-.399.399-.797.886-1.16
            1.41a1 1 0 0 1-1.644-1.14c.428-.618.901-1.195 1.39-1.684 4.653-4.654 12.198-4.654 16.851
            0zm-4.262 4.531-3.997 7.998A1.5 1.5 0 0 0 13.503 23h7.994a1.5 1.5 0 0 0
            1.343-2.172l-3.997-7.998c-.553-1.107-2.133-1.107-2.686 0zM18 15.496v3.001a.5.5 0 0 1-1
            0v-3.001a.5.5 0 0 1 1 0zm-.5 5.503a.5.5 0 1 1 0-1.001.5.5 0 0 1 0 1z'
        />
    </svg>
)
