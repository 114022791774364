import { ExceptionType, GOCException } from 'exceptions'

type ApiRequestParams = {
    query?: ConstructorParameters<typeof URLSearchParams>[0]
    payload?: Parameters<JSON['stringify']>[0]
    method?: 'POST' | 'GET' | 'PUT'
    baseUrl?: string
    path: string
    auth?: string
}

const apiRequest = async <Response>({ path, method = 'GET', auth, query, payload, baseUrl }: ApiRequestParams): Promise<Response> => {
    const BASE_URL = baseUrl ?? process.env.REACT_APP_WSE_API_URL ?? '/api'
    const url = query
        ? `${BASE_URL}${path}?${new URLSearchParams(query).toString()}`
        : `${BASE_URL}${path}`

    const response = await fetch(url, {
        body: payload ? JSON.stringify(payload) : void 0,
        headers: {
            authorization: auth ?? '',
            'content-type': 'application/json'
        },
        method
    })

    if (response.ok) {
        const data = await response.text()

        return data.length > 0
            ? JSON.parse(data)
            : void 0 as Response

    } else {
        throw new GOCException(ExceptionType.HttpError, `${response.status} ${response.statusText}`)
    }
}

export { apiRequest }
