import { Theme } from '@mui/material/styles'
import { Components } from '@mui/material'

const MuiButton: Components<Theme>['MuiButton'] = {
    variants: [{
        props: { variant: 'danger' },
        style: ({ theme }) => ({
            backgroundColor: theme.palette.error.main,
            color: theme.palette.neutral.main,
            '&:hover': {
                backgroundColor: theme.palette.error.hover
            }
        })
    }],
    styleOverrides: {
        root: ({ theme }) => ({
            padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
            lineHeight: '140%',
            fontWeight: 400,
            borderWidth: 0,
            boxShadow: 'none',
            '&:hover': {
                borderWidth: 0,
                boxShadow: 'none'
            }
        }),
        containedPrimary: ({ theme }) => ({
            backgroundColor: theme.palette.primary.darker,
            color: theme.palette.neutral.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.darker
            }
        }),
        containedSecondary: ({ theme }) => ({
            backgroundColor: theme.palette.neutral.darker,
            color: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.neutral.hover
            }
        }),
        containedError: ({ theme }) => ({
            backgroundColor: theme.palette.error.main,
            color: theme.palette.neutral.main,
            '&:hover': {
                backgroundColor: theme.palette.error.hover
            }
        })
    }
}

export { MuiButton }
