import { ChatList, WseUserId, User, ChatMessage } from 'api/types'
import { ConferenceParticipant } from 'contexts/conference/types'
import { Message, Conversation } from './types'

const mapMessage = (currentUser: User, user: User, chatMessage: ChatMessage): Message => {
    const sender = chatMessage.sender.id === currentUser.id
        ? currentUser
        : user

    const message: Message = {
        sender: {
            firstName: sender.firstName,
            lastName: sender.lastName
        },
        isOwnMessage: sender.id === currentUser.id,
        isTeacher: sender.role === 'teacher',
        timestamp: chatMessage.sentOn,
        text: chatMessage.text
    }

    return message
}

const mapEntireClassConversationMessages = (currentUser: User, users: User[], chatMessages: ChatMessage[]): Message[] => {
    return chatMessages.map(chatMessage => {
        const userId = chatMessage.sender.id
        const user = users.find(u => u.id === userId)

        return mapMessage(currentUser, user!, chatMessage)
    })
}

const getChatVisibility = (chatId: WseUserId, participants: ConferenceParticipant[]): boolean => {
    return !!participants.find(p => p.wseUserId === chatId || chatId === 'EVERYONE')
}

const mapChatListToConversations = (currentUser: User, users: User[], chatList: ChatList): Conversation[] => {
    return users
        .filter(user => {
            const isNotCurrentUser = user.id !== currentUser.id
            const isNotStudentChat = user.role !== 'student'

            return currentUser.role === 'teacher'
                ? isNotCurrentUser
                : isNotCurrentUser && isNotStudentChat
        })
        .map((user: User): Conversation => ({
            chatId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            messages: chatList[user.id].map(chatMessage => mapMessage(currentUser, user, chatMessage))
        }))
}

export { getChatVisibility, mapMessage, mapChatListToConversations, mapEntireClassConversationMessages }
