class Queue<T> {
    public queue: T[]
    private readonly offset: number

    constructor () {
        this.queue = []
        this.offset = 0
    }

    getLength (): number {
        return this.queue.length - this.offset
    }

    isEmpty (): boolean {
        return this.queue.length === 0
    }

    enqueue (item: T): void {
        this.queue.push(item)
    }

    dequeue (): T | undefined {
        if (this.queue.length === 0) return undefined
        return this.queue.shift()
    }

    dequeueAll (): T[] {
        const items = this.queue.splice(0, this.queue.length)
        return items
    }
}

export default Queue
