import { Dispatch } from 'react'
import { ActiveSpeaker, Participant } from '@zoom/videosdk'
import { isEqual } from 'lodash'
import { ClassroomState, UserState } from 'api/classroom/ClassroomState'
import { Coords, RtcSession, ConferenceAction, ConferenceParticipant } from 'contexts/conference/types'
import { ZoomSDKClient, ZoomSDKStream, ZoomClientOptions } from './types'
import { PrimusClient } from 'primus'
import { ChatClient } from 'chat'
import { WseUserId, ParticipantHardMuteStatus } from 'api/types'

class ZoomSession implements RtcSession {
    private readonly userDetails = new Map<string, UserState>()
    private readonly coordinates = new Map<number, Coords>()
    private readonly currentUser: Participant

    async muteLocally (participant: ConferenceParticipant): Promise<void> {
        await this.stream.muteUserAudioLocally(+participant.clientUserId)
    }

    async unmuteLocally (participant: ConferenceParticipant): Promise<void> {
        await this.stream.unmuteUserAudioLocally(+participant.clientUserId)
    }

    async stopRenderVideo (participant: ConferenceParticipant): Promise<void> {
        if (this.coordinates.has(+participant.clientUserId)) {
            this.coordinates.delete(+participant.clientUserId)
            await this.stream.stopRenderVideo(this.canvas, +participant.clientUserId)
        }
    }

    async excludeLocally (participant: ConferenceParticipant): Promise<void> {
        await this.stopRenderVideo(participant)
        await this.muteLocally(participant)
    }

    async setVideoMuted (muted: boolean): Promise<void> {
        if (muted) {
            await this.stream.stopVideo()
        } else {
            const videoOptions = this.stream.isRenderSelfViewWithVideoElement()
                ? { videoElement: document.querySelector('#self-video-element') as HTMLVideoElement }
                : this.options.video
            await this.stream.startVideo(videoOptions)
        }

        this.dispatch({ method: 'mute-video', muted })
    }

    async setAudioMuted (muted: boolean, isFromHardMute: boolean = false): Promise<void> {
        if (isFromHardMute) {
            if (muted) {
                await this.stream.muteAudio()
            }
            this.dispatch({ method: 'hard-mute-audio', muted })
        } else {
            if (!muted) {
                await this.stream.unmuteAudio()
            } else {
                await this.stream.muteAudio()
            }
            this.dispatch({ method: 'mute-audio', muted })
        }
    }

    async setCanvasSize (width: number, height: number): Promise<void> {
        if (this.coordinates.size > 0) {
            await this.stream.updateVideoCanvasDimension(this.canvas, width, height)
        }
    }

    async setVideoFrame (participant: ConferenceParticipant, { top, left, width, height }: Coords): Promise<void> {
        const bottom = this.canvas.height - top - height

        if (participant.cameraStatus === 'on') {
            const prevCoords = this.coordinates.get(+participant.clientUserId)
            const canvasSize = { width: this.canvas.width, height: this.canvas.height }
            const currentCoords = { width, height, left, top, canvasSize: { width: canvasSize.width, height: canvasSize.height } }
            const isSelfViewCanvas = this.selfViewCanvas && participant.wseUserId === this.wseUserId

            if (prevCoords) {
                if (!isEqual(prevCoords, currentCoords) && !isSelfViewCanvas) {
                    this.coordinates.set(+participant.clientUserId, { top, left, width, height, canvasSize })
                    await this.stream.adjustRenderedVideoPosition(
                        this.canvas,
                        +participant.clientUserId,
                        width,
                        height,
                        left,
                        bottom
                    )
                }

            } else {
                this.coordinates.set(+participant.clientUserId, { top, left, width, height, canvasSize })
                if (this.selfViewCanvas && participant.wseUserId === this.wseUserId) {
                    await this.stream.renderVideo(
                        this.selfViewCanvas,
                        +participant.clientUserId,
                        width,
                        height,
                        0,
                        0,
                        1
                    )

                } else {
                    await this.stream.renderVideo(
                        this.canvas,
                        +participant.clientUserId,
                        width,
                        height,
                        left,
                        bottom,
                        this.options.videoQuality
                    )

                }
            }
        }
    }

    async leave (): Promise<void> {
        this.dispatch({ method: 'disconnected' })
        await this.client.leave(false)
    }

    async end (): Promise<void> {
        this.dispatch({ method: 'disconnected' })
        await this.stopCloudRecording()
        await this.client.leave(true)
    }

    async stopCloudRecording (): Promise<void> {
        const cloudRecording = this.client.getRecordingClient()
        await cloudRecording.stopCloudRecording()
    }

    private setLastActiveSpeaker (activeSpeakers: ActiveSpeaker[]): void {
        const speakers = activeSpeakers.map(speaker => this.userDetails.get(JSON.parse(speaker.displayName ?? '{}').id))
        const isTeacher = speakers.find(speaker => speaker?.role === 'teacher')
        if (isTeacher) {
            this.dispatch({ method: 'last-speaker', speakerId: isTeacher.id })

        } else {
            this.dispatch({ method: 'last-speaker', speakerId: speakers[0]?.id })
        }
    }

    async updateParticipantsHardMuteStatus (participantHardMuteStatus: ParticipantHardMuteStatus[], id: string, hardMuteStatus: boolean): Promise<void> {
        const updatedParticipantHardMuteStatus = participantHardMuteStatus.map(
            student => student.id === id ? { ...student, micHardMuted: hardMuteStatus } : student)
        this.dispatch({
            participantHardMuteStatus: updatedParticipantHardMuteStatus,
            method: 'update-hard-mute-status'
        })
    }

    async studentRemoveStatus (): Promise<void> {
        await this.leave()
    }

    public constructor (
        primus: PrimusClient,
        chatClient: ChatClient,
        private readonly wseUserId: WseUserId,
        private readonly client: ZoomSDKClient,
        classroomState: ClassroomState,
        private readonly stream: ZoomSDKStream,
        private readonly dispatch: Dispatch<ConferenceAction>,
        private readonly canvas: HTMLCanvasElement,
        private readonly selfViewCanvas: HTMLCanvasElement | null | undefined,
        private readonly options: ZoomClientOptions
    ) {
        this.userDetails.set(classroomState.teacher.id, classroomState.dcAppData.teacher)
        for (const student of classroomState.dcAppData.students) {
            this.userDetails.set(student.id, student)
        }
        this.updateparticipantHardMuteStatus(classroomState.dcAppData.students)

        this.updateParticipantsList()

        client.on('user-added', participants => {
            this.updateParticipantsList()
        })

        client.on('user-removed', participants => {
            for (const participant of participants) {
                void this.stream.stopRenderVideo(this.canvas, participant.userId)
                this.coordinates.delete(participant.userId)
            }

            void this.updateVideoRendering()
            this.updateParticipantsList()
        })

        client.on('user-updated', participants => {
            void this.updateVideoRendering()
            this.updateParticipantsList()
        })

        let speakerTimeout: NodeJS.Timeout
        client.on('active-speaker', activeSpeakers => {
            dispatch({ method: 'speakers-list', list: new Set(activeSpeakers.map(speaker => speaker.userId.toString())) })
            this.setLastActiveSpeaker(activeSpeakers)

            clearTimeout(speakerTimeout)
            speakerTimeout = setTimeout(() => {
                dispatch({ method: 'speakers-list', list: new Set() })
            }, 2000)
        })

        const wseUser = this.userDetails.get(wseUserId)
        this.currentUser = client.getCurrentUserInfo()

        if (!this.options.isAudioMuted) {
            void this.setAudioMuted(this.options.isAudioMuted)
        }

        if (!this.options.isVideoMuted && !this.stream.isRenderSelfViewWithVideoElement()) {
            void this.setVideoMuted(this.options.isVideoMuted)
        }

        const cloudRecording = this.client.getRecordingClient()
        const canStartRecording = cloudRecording.canStartRecording()
        const isGocTeacher = document.cookie.split('; ').find(row => row.startsWith('isGOCTeacher='))?.split('=')[1] === 'true'
        if (canStartRecording && wseUser?.role === 'teacher' && isGocTeacher) {
            void cloudRecording.startCloudRecording()
        }

        this.dispatch({
            method: 'session-joined',
            rtcSession: this,
            classroomState,
            primus,
            chatClient,
            isAudioMuted: options.isAudioMuted ?? true,
            isVideoMuted: options.isVideoMuted ?? true,
            allowVideo: !(options.isVideoDisabled ?? wseUser?.camHardMuted ?? true),
            allowAudio: !(wseUser?.micHardMuted ?? true),
            isRenderSelfViewWithVideoElement: this.stream.isRenderSelfViewWithVideoElement()
        })
    }

    private parseGOCUserInfo (participant: Participant): UserState | undefined {
        try {
            return this.userDetails.get(JSON.parse(participant.displayName).id)

        } catch (e) {
            return void 0
        }
    }

    private getGOCParticipant (zoomUser: Participant): ConferenceParticipant {
        const wseUser = this.parseGOCUserInfo(zoomUser)

        if (wseUser) {
            return {
                clientUserId: zoomUser.userId.toString(),
                cameraStatus: zoomUser.bVideoOn ? 'on' : 'off',
                audioStatus: zoomUser.audio ? 'on' : 'off',
                lastName: wseUser.lastName,
                firstName: wseUser.firstName,
                wseProfilePhotoUrl: wseUser.photoUri,
                wseUserId: wseUser.id,
                role: wseUser.role,
                micHardMuted: wseUser.micHardMuted,
                expelled: wseUser.expelled
            }

        } else {
            console.warn(`A non-goc client (${zoomUser.userId}) found in the zoom session.`)

            return {
                clientUserId: zoomUser.userId.toString(),
                cameraStatus: zoomUser.bVideoOn ? 'on' : 'off',
                audioStatus: zoomUser.audio ? 'on' : 'off',
                lastName: zoomUser.userId.toString(),
                firstName: zoomUser.displayName,
                role: 'student',
                wseUserId: '',
                micHardMuted: false,
                expelled: false
            }
        }
    }

    private updateParticipantsList (): void {
        this.dispatch({
            participants: this.client.getAllUser().map(zoomUser => this.getGOCParticipant(zoomUser)),
            method: 'set-participants'
        })
    }

    private updateparticipantHardMuteStatus (students: UserState[]): void {
        this.dispatch({
            participantHardMuteStatus: students.map(student => {
                return { id: student.id, micHardMuted: student.micHardMuted }
            }),
            method: 'update-hard-mute-status'
        })
    }

    private async updateVideoRendering (): Promise<void> {
        for (const zoomUser of this.client.getAllUser().filter(zoomUser => zoomUser.userId !== this.currentUser.userId)) {
            if (this.coordinates.has(zoomUser.userId) && !zoomUser.bVideoOn) {
                await this.stream.stopRenderVideo(this.canvas, zoomUser.userId)
                this.coordinates.delete(zoomUser.userId)
            }
        }
    }
}

export { ZoomSession }
