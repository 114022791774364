import { CommonButtonProps, StandardButton } from './StandardButton'
import { BreakoutIcon, BreakoutActiveIcon } from 'icons'

const BreakoutButton: React.FC<CommonButtonProps> = ({ id, active, disabled, onClick }) => (
    <StandardButton
        id={id}
        color={active ? 'primary' : 'secondary'}
        iconSrc={active ? BreakoutActiveIcon : BreakoutIcon}
        disabled={disabled}
        onClick={onClick}
        text='Breakout Rooms'
    />
)

export { BreakoutButton }
