import { apiRequest } from 'api/api-request'
import { ExceptionType, GOCException } from 'exceptions'

type SlideDeckInfo = {
    sameOriginUrl: string
    'Blob URL': string
    'CueCard URL'?: string
    Class: string
    'Class Number': string
    Created: string
    Lesson: []
    Stage: string
    Unit: []
    nid: string
    title: string
    type: string
    uuid: string
}

const getSameOriginUrl = (url: string): string => {
    const originalURL = new URL(url)

    originalURL.protocol = location.protocol
    originalURL.host = location.host

    return originalURL + ''
}

const fetchSlideDecksInfo = async (auth: string, classType: string, unit: string, stage: string): Promise<SlideDeckInfo[]> => {
    const response = await apiRequest<{ data: Array<Omit<SlideDeckInfo, 'sameOriginUrl'>> | null; msg: string | null }>({
        path: `/cms/fetchPPTs/${classType}/${unit}/${stage}`,
        method: 'GET',
        auth
    })

    if (response.data) {
        return response.data.map(slideDeckInfo => ({
            sameOriginUrl: process.env.REACT_APP_ENV === 'local'
                ? getSameOriginUrl(slideDeckInfo['Blob URL'])
                : slideDeckInfo['Blob URL'],
            ...slideDeckInfo
        }))

    } else {
        throw new GOCException(ExceptionType.PPTsListError, response.msg ?? '')
    }
}

export type { SlideDeckInfo }
export { fetchSlideDecksInfo }
