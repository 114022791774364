import { WaitingForOthers } from 'components/status-panels'
import { ZoomLayout } from 'components/participants'
import { BreakoutRoomsState } from 'pages/useBreakouts'

const SpeakView: React.FC<{ breakouts?: BreakoutRoomsState }> = ({ breakouts }) => (
    <>
        <WaitingForOthers breakouts={breakouts} />
        <ZoomLayout breakouts={breakouts} />
    </>
)

export { SpeakView }
