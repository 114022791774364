export const ExpelDialogContent = {
    title: 'Expel Student',
    dsescription: 'Are you sure you want to expel the following student from the class?',
    buttonName: 'Expel Student'
}

export const ExpelStudentScreenContent = {
    title: 'You have been removed from the class',
    description: 'You will not be able to rejoin this session',
    buttonName: 'Go to dashboard'
}
