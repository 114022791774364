import { WseUserId } from 'api/types'
import { apiRequest } from '../api-request'

interface ZoomSignature {
    token: string
    sessionPassword: string
}

const getZoomSignature = async (auth: string, userId: WseUserId, topic: string, userRole: string): Promise<ZoomSignature> => {
    const response = await apiRequest<ZoomSignature>({
        path: '/zoom-signature',
        method: 'POST',
        auth,
        payload: {
            topic,
            userId,
            userRole,
            roleType: userRole === 'teacher' ? 1 : 0
        }
    })

    return response
}

export type { ZoomSignature }
export { getZoomSignature }
