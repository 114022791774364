import { apiRequest } from '../api-request'
import { Guid } from './types'

type CloseEncounter = {
    override: boolean
    classId: Guid
}

const closeEncounter = async (auth: string, result: CloseEncounter): Promise<void> => {
    const payload: CloseEncounter = {
        ...result,
        classId: process.env.REACT_APP_ACTIVITY_ID ?? result.classId
    }
    return await apiRequest<undefined>({
        baseUrl: process.env.REACT_APP_BS_API_URL,
        path: '/classes/encounters/close',
        method: 'POST',
        auth: process.env.REACT_APP_QUALWORLD_AUTH ?? auth,
        payload
    })
}

export type { CloseEncounter }
export { closeEncounter }
