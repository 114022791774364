import { CommonIconButtonProps, StandardIconButton } from './StandardIconButton'
import { NotesIcon, NotesActiveIcon } from 'icons'

const NotesIconButton: React.FC<CommonIconButtonProps> = ({ id, active, disabled, size = 'medium', onClick }) => (
    <StandardIconButton
        id={id}
        color={active ? 'primary' : 'secondary'}
        icon={active ? NotesActiveIcon : NotesIcon}
        disabled={disabled}
        onClick={onClick}
        size={size}
    />
)

export { NotesIconButton }
