import { styled } from '@mui/material'
import { CommonIconButtonProps, StandardIconButton } from './StandardIconButton'
import { NotificationsIndicator } from 'components/buttons'
import { ChatIcon, ChatActiveIcon } from 'icons'

const NotificationWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    transform: 'translate(15px, -20px) scale(0.8)',
    fontWeight: 600
}))

const ChatIconButton: React.FC<CommonIconButtonProps & { notifications?: number }> = ({ id, active, disabled, size = 'medium', onClick, notifications }) => (
    <StandardIconButton
        id={id}
        color={active ? 'primary' : 'secondary'}
        icon={active ? ChatActiveIcon : ChatIcon}
        disabled={disabled}
        onClick={onClick}
        size={size}
    >
        {notifications && !disabled ? (
            <NotificationWrapper>
                <NotificationsIndicator id='chat-button-notifications-indicator'>
                    {notifications < 10 ? notifications : '9+'}
                </NotificationsIndicator>
            </NotificationWrapper>
        ) : null}
    </StandardIconButton>
)

export { ChatIconButton }
