import { apiRequest } from '../api-request'
import { Guid, ResultTypeId } from './types'

type EncounterResult = {
    score: number | null
    classId: Guid
    studentId: Guid
    comment: string
    contentItemResultType: ResultTypeId | undefined
}

const saveEncounterResult = async (auth: string, result: EncounterResult): Promise<void> => {
    if (!process.env.REACT_APP_USE_ASSESSMENT_MOCKS) {
        const payload: EncounterResult = {
            ...result,
            classId: process.env.REACT_APP_ACTIVITY_ID ?? result.classId
        }
        return await apiRequest<undefined>({
            baseUrl: process.env.REACT_APP_BS_API_URL,
            path: '/classes/encounters/results',
            method: 'PUT',
            auth: process.env.REACT_APP_QUALWORLD_AUTH ?? auth,
            payload
        })

    } else {
        return new Promise(resolve => setTimeout(resolve, 300))
    }
}

export type { EncounterResult }
export { saveEncounterResult }
