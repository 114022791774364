import { IconButton, IconButtonProps } from '@mui/material'
import { CloseIcon, FullscreenIcon, BackIcon } from 'icons'

type SidebarButtonProps = {
    id?: string
    icon?: string
    onClick?: IconButtonProps['onClick']
}

const CloseButton: React.FC<SidebarButtonProps> = ({ id, onClick }) => (
    <IconButton
        id={id}
        onClick={onClick}
        sx={theme => ({
            backgroundColor: 'transparent',
            height: theme.spacing(4),
            width: theme.spacing(4),
            padding: 0,
            img: {
                width: theme.spacing(4.75),
                maxWidth: 'none'
            }
        })}
    >
        <img src={CloseIcon} />
    </IconButton>
)
const FullscreenButton: React.FC<SidebarButtonProps> = ({ id, onClick }) => (
    <IconButton
        id={id}
        onClick={onClick}
        sx={theme => ({
            backgroundColor: 'transparent',
            height: theme.spacing(3.125),
            width: theme.spacing(4),
            padding: 0,
            borderRadius: theme.spacing(0.625),
            overflow: 'hidden',
            img: {
                width: theme.spacing(4.75),
                maxWidth: 'none'
            }
        })}
    >
        <img src={FullscreenIcon} />
    </IconButton>
)
const BackButton: React.FC<SidebarButtonProps> = ({ id, onClick }) => (
    <IconButton
        id={id}
        onClick={onClick}
        sx={theme => ({
            backgroundColor: 'transparent',
            height: theme.spacing(4),
            width: theme.spacing(4),
            padding: 0,
            img: {
                width: theme.spacing(4.75),
                maxWidth: 'none'
            }
        })}
    >
        <img src={BackIcon} />
    </IconButton>
)

export { CloseButton, FullscreenButton, BackButton }
