import { createRef, RefObject, useContext, useEffect, useState } from 'react'
import { ISpringContext } from './ISpringContext'
import { IThumbnail } from 'ispring/IPlayer'

type Thumbnail = {
    ref: RefObject<HTMLImageElement>
    stepsCount: number
    item: IThumbnail
    url: string
}

const useThumbnails = (): Thumbnail[] | undefined => {
    const [thumbnails, setThumbnails] = useState<Thumbnail[]>()
    const ispring = useContext(ISpringContext)

    useEffect(() => {
        if (ispring.status === 'player-ready') {
            const slides = ispring.player.presentation().slides()
            const baseUrl = ispring.currentDeck.sameOriginUrl
            const thumbnails: Thumbnail[] = []

            for (let i = 0; i < slides.count(); i++) {
                const slide = slides.getSlide(i)

                const stepsCount = slide.animationSteps().count()
                const thumbnail = slide.thumbnail()
                const url = new URL(thumbnail.path(), baseUrl)

                // removing timestamp query param
                url.search = ''

                thumbnails.push({
                    ref: createRef<HTMLImageElement>(),
                    item: thumbnail,
                    url: url + '',
                    stepsCount
                })
            }

            setThumbnails(thumbnails)

        } else {
            setThumbnails(void 0)
        }
    }, [ispring.status])

    return thumbnails
}

export type { Thumbnail }
export { useThumbnails }
