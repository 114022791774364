import { useContext, useEffect, useRef, useState, Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/material'
import { ConferenceContext } from 'contexts/conference'
import { ISpringContext } from 'contexts/ispring'
import { SlideDeckNavigationController } from 'ispring'
import { useAnnotations } from 'annotation-tool/useAnnotations'
import { PptActionEvent } from 'primus/types/events'

const SlidesWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    flexWrap: 'nowrap',
    height: '70%'
}))
export const PresentationContainer = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    margin: 'auto',
    position: 'relative',
    '&:before': {
        content: '""',
        outline: `solid ${theme.spacing(1)} ${theme.palette.neutral.main}`,
        borderRadius: theme.spacing(2),
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        aspectRatio: '16/9'
    },
    '&:after': {
        content: '""',
        outline: `solid ${theme.spacing(1)} ${theme.palette.neutral.main} `,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        aspectRatio: '16/9',
        border: '1px solid rgba(0, 0, 0, 0.16)'
    }
}))
const Iframe = styled('iframe')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '103%',
    height: '105%',
    border: 'none',
    pointerEvents: 'none',
    userSelect: 'none'
}))
const SkeletonPresentation = styled('div')(({ theme }) => ({
    flex: 'auto',
    height: '100%',
    borderRadius: theme.spacing(2),
    background: theme.palette.neutral.darker
}))

const AnnotationsCanvas = styled('canvas')(() => ({
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100
}))

const SlidesPresentation: React.FC<{
    bufferedEvent?: PptActionEvent | null
    setBufferedEvent?: Dispatch<SetStateAction<PptActionEvent | null>>
}> = ({ bufferedEvent, setBufferedEvent }) => {
    const iSpring = useContext(ISpringContext)
    const conference = useContext(ConferenceContext)
    const containerRef = useRef<HTMLDivElement>(null)
    const annotationsCanvasRef = useRef<HTMLCanvasElement>(null)
    const [navigationController, setNavigationController] = useState<SlideDeckNavigationController>()
    const { annotationsCanvasParams } = useAnnotations(containerRef, annotationsCanvasRef, navigationController)

    useEffect(() => {
        if (iSpring.status === 'ready' && conference.status === 'session-joined') {
            const wbData = conference.classroomStateOnJoin.dcAppData.wbData
            const storedState = wbData.boards[wbData.activeBoard].mount

            if (storedState) {
                iSpring.setPresentationUrl(storedState.url)
            } else {
                iSpring.storedPresentationUrl && iSpring.setPresentationUrl(iSpring.storedPresentationUrl)
            }
        }

        if (iSpring.status === 'player-ready') {
            const navController = new SlideDeckNavigationController(iSpring.player)
            setNavigationController(navController)

            if (conference.status === 'session-joined') {
                const storedActiveSlide = iSpring.storedActiveSlide

                if (storedActiveSlide.index !== 0 || storedActiveSlide.step !== 0) {
                    navController.gotoTimestamp(storedActiveSlide.index, storedActiveSlide.step)
                }
            }

            return () => {
                setNavigationController(undefined)
                iSpring.close(navController.activeSlide)
            }
        }
    }, [iSpring.status])

    useEffect(() => {
        if (bufferedEvent && navigationController) {
            navigationController.gotoTimestamp(bufferedEvent.slide, bufferedEvent.stepIndex)
            if (setBufferedEvent) {
                setBufferedEvent(null)
            }
        }
    }, [bufferedEvent, navigationController])

    return (
        <SlidesWrapper id='student-slides'>
            {(iSpring.status === 'presentation-selected' || iSpring.status === 'player-ready') ? (
                <PresentationContainer ref={containerRef} id='presentation-container'>
                    <Iframe id='presentation-iframe' src={iSpring.presentationUrl} />
                    <AnnotationsCanvas
                        ref={annotationsCanvasRef}
                        height={annotationsCanvasParams.height}
                        width={annotationsCanvasParams.width}
                    />
                </PresentationContainer>
            ) : <SkeletonPresentation data-testid='skeleton-presentation' />}
        </SlidesWrapper>
    )
}

export { SlidesPresentation }
