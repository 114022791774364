import { styled, Button, ButtonProps } from '@mui/material'
import { ButtonLabelWrapper } from './ButtonWrapper'
import { Icon, IconProps } from 'components/icon-buttons'

type StandardButtonProps = {
    onClick?: ButtonProps['onClick']
    id?: string
    color: ButtonProps['color']
    iconSrc?: IconProps['src']
    disabled?: boolean
    text?: string
    children?: React.ReactNode
}

const StyledButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        height: theme.spacing(5),
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    }
}))

const StandardButton: React.FC<StandardButtonProps> = ({ onClick, id, color, iconSrc, text, disabled, children }) => (
    <StyledButton id={id} disabled={disabled} onClick={onClick} variant='contained' color={color}>
        {iconSrc ? <Icon src={iconSrc} size='medium' /> : null}
        {text ? <ButtonLabelWrapper>{text}</ButtonLabelWrapper> : null }
        {children}
    </StyledButton>
)

type CommonButtonProps = { id?: string; active: boolean; disabled?: boolean; onClick?: StandardButtonProps['onClick'] }

export type { StandardButtonProps, CommonButtonProps }
export { StandardButton }
