import { useState, useContext } from 'react'
import { styled, IconButton } from '@mui/material'
import { BookingContext } from 'contexts/booking'
import { closeEncounter } from 'api/unit-assessment'
import { RectangleButton } from 'components/buttons'
import { CloseIcon, InfoIcon } from 'icons'

const Wrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    zIndex: 100,
    inset: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)'
}))
const InnerWrapper = styled('div')(({ theme }) => ({
    width: '90%',
    maxWidth: theme.spacing(70),
    maxHeight: '90%',
    overflow: 'auto',
    position: 'relative',
    backgroundColor: theme.palette.neutral.main,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
    h3: {
        fontWeight: 800,
        fontSize: theme.spacing(4.5),
        color: theme.palette.primary.darker,
        marginBottom: theme.spacing(4)
    },
    '> p': {
        fontSize: '18px',
        marginBottom: theme.spacing(2),
        b: {
            fontWeight: 'bold'
        }
    }
}))
const CloseButtonWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3)
}))
const NoticeWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    color: theme.palette.secondary.main,
    backgroundColor: '#e0f4fa',
    borderRadius: theme.spacing(1.5),
    img: {
        marginRight: theme.spacing(1),
        width: theme.spacing(4)
    },
    '> p': {
        span: {
            fontWeight: 700
        }
    }
}))
const BottomWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    gap: theme.spacing(1)
}))
const ButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(1)
}))
const SendingMessage = styled('p')<{ isError: boolean }>(({ theme, isError }) => ({
    fontSize: theme.spacing(1.8),
    lineHeight: 1.2,
    color: isError ? theme.palette.error.main : theme.palette.neutral.dark
}))

const SummaryModal: React.FC<{ closeModal: (isOnSubmit: boolean) => void; isError: boolean }> = ({ closeModal, isError = false }) => {
    const booking = useContext(BookingContext)
    const [sendingStatus, setSendingStatus] = useState<'sending' | 'sent' | 'error' | 'idle'>('idle')

    const handleSubmitClass = (): void => {
        if (booking.status === 'validated') {
            setSendingStatus('sending')
            void closeEncounter(booking.auth, { override: true, classId: booking.classroomId }).then(() => {
                setSendingStatus('sent')
                setTimeout(() => {
                    setSendingStatus('idle')
                    closeModal(true)
                }, 1000)
            }).catch(() => setSendingStatus('error'))
        }
    }

    return (
        <Wrapper>
            <InnerWrapper id='assessment-modal-inner-wrapper'>
                {!isError
                    ? <>
                        <CloseButtonWrapper>
                            <IconButton
                                id='assessment-modal-close-button'
                                onClick={() => closeModal(false)}
                                sx={theme => ({
                                    backgroundColor: 'transparent',
                                    height: theme.spacing(3),
                                    width: theme.spacing(3),
                                    marginLeft: theme.spacing(1),
                                    padding: 0,
                                    overflow: 'hidden',
                                    img: {
                                        width: theme.spacing(3.75),
                                        maxWidth: 'none'
                                    }
                                })}
                            >
                                <img src={CloseIcon} />
                            </IconButton>
                        </CloseButtonWrapper>
                        <h3>Send final assessment</h3>
                        <p>Are you sure you want to display the assessment to all the students in the class and send it to their grade books,
                           along with your written feedback?</p>
                        <NoticeWrapper>
                            <img src={InfoIcon} />
                            <p>This action will finalize the assessment for all the students and <span>cannot be reverted.</span></p>
                        </NoticeWrapper>
                        <BottomWrapper>
                            <SendingMessage isError={sendingStatus === 'error'}>
                                {sendingStatus === 'sending' ? 'Sending...'
                                    : sendingStatus === 'sent' ? 'Assessment sent'
                                        : sendingStatus === 'error' ? 'An error occurred while sending'
                                            : ''
                                }
                            </SendingMessage>
                            <ButtonsWrapper>
                                <RectangleButton onClick={() => closeModal(false)} text='Cancel' color='error' variant='outlined' />
                                <RectangleButton onClick={handleSubmitClass} disabled={sendingStatus === 'sending'} text='Finalize assessment' color='error' />
                            </ButtonsWrapper>
                        </BottomWrapper>
                    </>
                    : <>
                        <h3>Send final assessment</h3>
                        <p>You have rated at least one skill for student(s) whose final result is
                            <b> No Show / Tech Repeat (Student/ Teacher/ Platform)</b>. Please <b>deselect</b> the ratings to submit.</p>
                        <ButtonsWrapper>
                            <RectangleButton onClick={() => closeModal(false)} text='OK' color='error' />
                        </ButtonsWrapper>
                    </>
                }
            </InnerWrapper>
        </Wrapper>
    )
}

export { SummaryModal }
