import { SlideDeckNavigationController } from 'ispring'
import { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material'
import { ISpringContext } from '../../contexts/ispring'

const Wrapper = styled('div')(() => ({
    height: '100%',
    overflowY: 'auto'
}))

const Notes: React.FC = () => {
    const [slideNotes, setSlideNotes] = useState('')
    const ispring = useContext(ISpringContext)

    useEffect(() => {
        if (ispring.status === 'player-ready') {
            const navController = new SlideDeckNavigationController(ispring.player)
            const slideIndex = navController.getCurrentSlideIndex()
            setSlideNotes(navController.getSlideNotes(slideIndex))

            const slideNavListener = (index: number): void => {
                setSlideNotes(navController.getSlideNotes(index))
                const noteElement = document.getElementById('notes-panel')
                if (noteElement !== null) {
                    noteElement.scrollTop = 0
                }
            }

            const removeStepEventHandler = navController.addStepEventHandler(slideNavListener)

            return () => {
                removeStepEventHandler()
            }

        }
    }, [ispring.status])

    return (
        <Wrapper className='notes' id='notes-panel' dangerouslySetInnerHTML={{ __html: slideNotes }}></Wrapper>
    )
}

export { Notes }
