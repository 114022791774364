import { VideoQuality } from '@zoom/videosdk'
import { IClientOptions } from 'contexts/conference/types'
import { ZoomClientOptions, ZoomClientPartialOptions } from './types'

const ASPECT_RATIO = 16 / 9
const VIDEO_QUALITY: VideoQuality = 1
const VIDEO_MIRRORED = true

const defaultZoomOptions: ZoomClientOptions = {
    isAudioMuted: true,
    isVideoMuted: true,
    isVideoDisabled: false,
    video: {},
    videoQuality: VIDEO_QUALITY,
    audio: {}
}

const isZoomClientPartialOptions = (options: IClientOptions): options is ZoomClientPartialOptions => (
    'video' in options || 'audio' in options || 'videoQuality' in options || 'isAudioMuted' in options || 'isVideoMuted' in options
)

const getZoomConfig = (options: IClientOptions): ZoomClientOptions => {
    return !isZoomClientPartialOptions(options) ? defaultZoomOptions : {
        isAudioMuted: options.isAudioMuted ?? defaultZoomOptions.isAudioMuted,
        isVideoMuted: options.isVideoMuted ?? defaultZoomOptions.isVideoMuted,
        isVideoDisabled: options.isVideoDisabled ?? defaultZoomOptions.isVideoDisabled,
        video: options.video ?? defaultZoomOptions.video,
        videoQuality: options.videoQuality ?? defaultZoomOptions.videoQuality,
        audio: options.audio ?? defaultZoomOptions.audio
    }
}

export { ASPECT_RATIO, VIDEO_QUALITY, VIDEO_MIRRORED }
export { getZoomConfig }
