import { useState, useEffect, useContext, Dispatch, SetStateAction } from 'react'
import { styled, Select, MenuItem, Button } from '@mui/material'
import { StudentWithScores, AssessmentContext, TaskRating, SavingCallbacks, SavingStatus, Score } from 'contexts/assessment'
import { BookingContext } from 'contexts/booking'
import { saveEncounterResult } from 'api/unit-assessment'
import { getDisplayName, getSameOriginPhotoUrl } from 'utils/get-display-name'
import { ExceptionType, GOCException } from 'exceptions'
import { RectangleButton } from 'components/buttons'
import { ErrorIcon, RepeatIcon, StarIcon, NoShowIcon } from 'icons'
import { SummaryModal } from './SummaryModal'
import { ConferenceContext } from 'contexts/conference'

const MAX_ENCOUNTER_ACTIVITY_SCORE = 4

const classResultEnum = {
    CONTINUE: 1,
    REPEAT: 2,
    NOSHOW: 3,
    TECHSTUDENT: 13,
    TECHTSTAFF: 14,
    TEACHPLATFORM: 15
}

type ClassResultType = {
    id: number
    label: string
    imageUrl: string
}
export const classResultDefaultValues: ClassResultType[] = [
    {
        id: classResultEnum.CONTINUE,
        label: 'Continue',
        imageUrl: StarIcon
    },
    {
        id: classResultEnum.REPEAT,
        label: 'Repeat',
        imageUrl: RepeatIcon
    },
    {
        id: classResultEnum.NOSHOW,
        label: 'No Show',
        imageUrl: NoShowIcon
    },
    {
        id: classResultEnum.TECHSTUDENT,
        label: 'Tech - Student',
        imageUrl: ErrorIcon
    },
    {
        id: classResultEnum.TECHTSTAFF,
        label: 'Tech - Teacher',
        imageUrl: ErrorIcon
    },
    {
        id: classResultEnum.TEACHPLATFORM,
        label: 'Tech - Platform',
        imageUrl: ErrorIcon
    }
]

const Wrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%'
}))
const InnerWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%'
}))
const StudentTabs = styled('div')(({ theme }) => ({
    position: 'absolute',
    marginLeft: theme.spacing(-3),
    width: `calc(100% + ${theme.spacing(6)})`,
    height: theme.spacing(6),
    display: '-webkit-box',
    overflow: 'auto',
    padding: `0 ${theme.spacing(1)}`
}))
const StudentTab = styled('div')<{ active: boolean; isDisabled: boolean }>(({ theme, active, isDisabled }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    cursor: 'pointer',
    border: `solid 1px ${active ? theme.palette.neutral.hover : 'transparent'}`,
    borderBottomColor: active ? theme.palette.neutral.alternate : theme.palette.neutral.hover,
    borderTopLeftRadius: theme.spacing(3),
    borderTopRightRadius: theme.spacing(3),
    opacity: active ? 1 : 0.5,
    pointerEvents: isDisabled ? 'none' : 'auto',
    willChange: 'border-color, opacity',
    transition: 'border-color 0.2s ease-out, opacity 0.2s ease-out',
    '&:after': {
        content: '""',
        position: 'absolute',
        bottom: '-1px',
        right: '-2px',
        width: '2px',
        borderBottom: `solid 1px ${theme.palette.neutral.hover}`
    },
    '&:first-of-type&:before': {
        content: '""',
        position: 'absolute',
        bottom: '-1px',
        left: theme.spacing(-1.25),
        width: theme.spacing(1.25),
        borderBottom: `solid 1px ${theme.palette.neutral.hover}`
    },
    '&:last-of-type&:after': {
        content: '""',
        position: 'absolute',
        bottom: '-1px',
        right: theme.spacing(-1.25),
        width: theme.spacing(1.25),
        borderBottom: `solid 1px ${theme.palette.neutral.hover}`
    }
}))
const Avatar = styled('div')(({ theme }) => ({
    fontSize: 'var(--tile-fontsize)',
    fontWeight: 800,
    width: theme.spacing(4),
    aspectRatio: '1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    backgroundColor: 'rgba(0,0,0,0.35)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.neutral.main,
    span: {
        lineHeight: 1
    }
}))
const ContentWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(7),
    padding: theme.spacing(1),
    width: '100%',
    height: `calc(100% - ${theme.spacing(17)})`,
    backgroundColor: theme.palette.neutral.main,
    borderRadius: theme.spacing(2)
}))
const ResultsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1)
}))
const AvatarWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: theme.spacing(11),
    maxWidth: theme.spacing(11),
    '> div': {
        width: theme.spacing(6),
        marginRight: 0
    },
    p: {
        fontSize: theme.spacing(1.8),
        fontWeight: '700',
        width: '100%',
        marginTop: theme.spacing(1),
        wordWrap: 'break-word',
        lineHeight: 1.1
    }
}))
const OffsetWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
}))
const ScoreWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: `0 ${theme.spacing(1)}`,
    span: {
        fontSize: theme.spacing(1.5),
        lineHeight: 1.1,
        color: theme.palette.secondary.main
    },
    p: {
        fontSize: theme.spacing(2.5),
        fontWeight: '800',
        marginTop: theme.spacing(-0.2)
    }
}))
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    img: {
        marginRight: theme.spacing(1)
    },
    p: {
        fontSize: theme.spacing(1.8),
        span: {
            fontSize: theme.spacing(1.4),
            color: theme.palette.secondary.darker
        }
    },
    '&:first-of-type': {
        display: 'none'
    }
}))
const ActivitiesWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    height: `calc(100% - ${theme.spacing(12)})`,
    '> p': {
        fontSize: theme.spacing(1.8),
        fontWeight: '700',
        color: theme.palette.secondary.main,
        paddingBottom: theme.spacing(1)
    }
}))
const ActivitiesList = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    overflow: 'auto'
}))
const Activity = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '> div:first-of-type': {
        display: 'flex',
        p: {
            lineHeight: 1.1
        },
        span: {
            fontWeight: '700',
            marginRight: theme.spacing(1)
        }
    }
}))
const Grade = styled('div')<{ grade?: number; isDisabled?: boolean }>(({ theme, grade, isDisabled }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(10),
    minWidth: theme.spacing(10),
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.neutral.alternate,
    border: `solid 1px ${theme.palette.neutral.hover}`,
    borderRadius: theme.spacing(4),
    overflow: 'hidden',
    pointerEvents: 'none',
    opacity: isDisabled ? '0.5' : 'none',
    span: {
        flex: 1,
        height: '100%',
        fontSize: theme.spacing(1.6),
        lineHeight: 1.6,
        borderRadius: '50%',
        textAlign: 'center',
        color: theme.palette.secondary.main,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        pointerEvents: 'auto',
        '&:nth-of-type(1)': {
            backgroundColor: grade === 1 ? '#F9B3BD' : 'transparent'
        },
        '&:nth-of-type(2)': {
            backgroundColor: grade === 2 ? '#FFEADD' : 'transparent'
        },
        '&:nth-of-type(3)': {
            backgroundColor: grade === 3 ? '#A7D692' : 'transparent'
        },
        '&:nth-of-type(4)': {
            backgroundColor: grade === 4 ? '#6CBE3E' : 'transparent'
        }
    }
}))
const ButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    gap: theme.spacing(1)
}))
const SavingMessage = styled('p')<{ isError: boolean }>(({ theme, isError }) => ({
    width: theme.spacing(15),
    fontSize: theme.spacing(1.8),
    lineHeight: 1.2,
    color: isError ? theme.palette.error.main : theme.palette.neutral.dark
}))

const CommentsLabel = styled('p')(({ theme }) => ({
    fontSize: theme.spacing(1.8),
    fontWeight: '700',
    color: theme.palette.secondary.main
}))

const StudentComment = styled('textarea')<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
    minHeight: '95px',
    border: `solid 2px ${theme.palette.neutral.hover}`,
    color: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: theme.spacing(2),
    lineHeight: 1.2,
    cursor: isDisabled ? 'not-allowed' : 'auto'
}))

const ResultOffsetWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
}))

const ButtonWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1)
}))

export const calculateFinalScore = (scores: Array<number | undefined>): number | undefined => {
    if (scores.length > 0) {
        let sum = 0
        for (const score of scores) {
            if (score !== void 0 && isFinite(score)) {
                sum = sum + score
            } else {
                return undefined
            }
        }

        return Math.round(sum / (scores.length * MAX_ENCOUNTER_ACTIVITY_SCORE) * 100)
    }
}

type SummaryPanelType = {
    tasksRatings: TaskRating[]
    students: StudentWithScores[]
    savingStatus: SavingStatus
    setSavingStatus: () => SavingCallbacks
    score?: Score
    setScore: Dispatch<SetStateAction<Score | undefined>>
    isClassClosed: boolean
    setIsCurrentTabSaving: Dispatch<SetStateAction<boolean>>
}

export const useSaveEncounterResults = (students: StudentWithScores[], saving: () => SavingCallbacks):
(isClassResultChanged: boolean, currentStudent: StudentWithScores) => Promise<void> => {
    const assessment = useContext(AssessmentContext)
    const booking = useContext(BookingContext)

    const save = async (isClassResultChanged: boolean = false, currentStudent: StudentWithScores): Promise<void> => {
        if (booking.status === 'validated' && assessment.status === 'encounter') {
            const [done, error] = saving()
            if (isClassResultChanged) {
                try {
                    await saveEncounterResult(booking.auth, {
                        classId: assessment.classDetails.classId,
                        studentId: currentStudent.userId,
                        score: currentStudent.finalScore ?? null,
                        comment: currentStudent.comment,
                        contentItemResultType: currentStudent.contentItemResultType ?? null
                    })
                } catch (e) {
                    error(e as Error)
                    throw e
                }
            } else {
                for (const student of students) {
                    if (student.isNoShowTechResultsSelected || student.finalScore !== void 0) {
                        try {
                            await saveEncounterResult(booking.auth, {
                                classId: assessment.classDetails.classId,
                                studentId: student.userId,
                                score: student.finalScore ?? null,
                                comment: student.comment,
                                contentItemResultType: student.contentItemResultType ?? null
                            })
                        } catch (e) {
                            error(e as Error)
                            throw e
                        }
                    } else {
                        throw new GOCException(ExceptionType.AssessmentNotCompleted)
                    }
                }
            }
            done()
        }
    }
    return save
}
const SummaryPanel: React.FC<SummaryPanelType> = (
    { students, tasksRatings, savingStatus, setSavingStatus, score, setScore, isClassClosed, setIsCurrentTabSaving }) => {
    const assessment = useContext(AssessmentContext)
    const conference = useContext(ConferenceContext)
    const saveResults = useSaveEncounterResults(students, setSavingStatus)

    const [currentStudent, setCurrentStudent] = useState<StudentWithScores>(students[0])
    const initialClassResult = currentStudent.contentItemResultType !== undefined ? String(currentStudent.contentItemResultType) : ''
    const [classResult, setClassResult] = useState<string>(initialClassResult)
    const [finalScore, setFinalScore] = useState<number>()
    const [studentsGraded, setStudentsGraded] = useState<boolean>(false)
    const [showModal, setModalVisibility] = useState<boolean>(false)
    const [disableSaving, setDisableSaving] = useState<boolean>(true)
    const [showFinalScore, setShowFinalScore] = useState<boolean>(false)
    const [comments, setComments] = useState<string>(currentStudent.comment)
    const [resultsSent, setResultsSent] = useState<boolean>(false)
    const [showErrorModal, setErrorModalVisibility] = useState<boolean>(false)
    const [isEncounterClassClosed, setIsEncounterClassClosed] = useState<boolean>(isClassClosed)
    const [isCommentsSaving, setIsCommentsSaving] = useState<boolean>(false)
    const isSavingAssessmentStatus = savingStatus === 'saving'
    /**
     * handleGradeChange.
     * @param {HTMLElement} target - Html element value.s
     * @param {Object} student - The Current student's object.
     * @param {Object} rating - The Score rating for the activities.
     */
    const handleGradeChange = (target: HTMLElement, student: StudentWithScores, rating: TaskRating): void => {
        setIsCommentsSaving(false)
        const scoreValue = student.scores.get(rating.rating.id) === +target.innerHTML ? 0 : +target.innerHTML
        setScore({ value: scoreValue, rating: rating.rating, student })
        student.isGraded = scoreValue !== 0
        student.isScoreOrResultChanged = true
        student.isScoreAndResultAvailableInAPI = false
        setResultsSent(false)
    }

    /** Update the class result value */
    const handleClassResult = (): void => {
        if (!currentStudent.isScoreAndResultAvailableInAPI) {
            currentStudent.contentItemResultType = currentStudent.finalScore !== void 0 && currentStudent.finalScore < 70
                ? classResultEnum.REPEAT : classResultEnum.CONTINUE
        }
        setClassResult(currentStudent.finalScore !== void 0 ? String(currentStudent.contentItemResultType) : '')
    }

    /** Web socket triggered when display to button is clicked */
    const displayToStudents = (studentId: string): void => {
        if (conference.status === 'session-joined') {
            conference.primus.send('class-results', { students: [{ id: studentId }] })
            setResultsSent(true)
            currentStudent.isScoreOrResultChanged = false
        }
    }

    /** Web socket triggered to all students when encounter is finished and modal is closed */
    const handleCloseSummaryModal = (isOnSubmit: boolean): void => {
        if (conference.status === 'session-joined') {
            if (isOnSubmit) {
                const studentsPayloadData = students.map((student: StudentWithScores) => ({ id: student.userId }))
                conference.primus.send('class-results', { students: studentsPayloadData })
                if (assessment.status === 'encounter') {
                    assessment.classDetails.isClosed = true
                    setIsEncounterClassClosed(true)
                }
            }
            setModalVisibility(false)
        }
    }

    /**
     * handleTextAreaChange.
     * @param {string} comment - Comments.
     */
    const handleTextAreaChange = (comment: string): void => {
        currentStudent.comment = comment
        setComments(comment)
    }

    /** Used to save the updated comments via API */
    const handleCommentChange = (): void => {
        setIsCommentsSaving(true)
        setIsCurrentTabSaving(true)
        void saveResults(true, currentStudent)
    }

    /** Used to save all the results before clsoing the encounter */
    const saveToGradeBooks = (): void => {
        setIsCommentsSaving(false)
        void saveResults(false, currentStudent).then(() => {
            const isAnyNoShowOrTechRepeatSelected = students.filter(student =>
                student.isNoShowTechResultsSelected && student.finalScore !== undefined && student.finalScore !== 0)
            isAnyNoShowOrTechRepeatSelected.length > 0 ? setErrorModalVisibility(true) : setModalVisibility(true)
        })
    }

    useEffect(() => {
        if (assessment.status === 'encounter') {
            students.forEach(s => {
                s.finalScore = calculateFinalScore(tasksRatings.map(task =>
                    s.scores.get(task.rating.id)
                ))
                if (s === currentStudent) {
                    setComments(s.comment)
                    const isAllRatingsGiven = s.scores.size === tasksRatings.length && ![...s.scores.values()].includes(0)
                    if (s.isGraded && isAllRatingsGiven) {
                        setFinalScore(s.finalScore)
                        handleClassResult()
                    } else {
                        setFinalScore(0)
                        s.contentItemResultType && s.contentItemResultType > 2 ? setClassResult(String(s.contentItemResultType)) : setClassResult('')
                        s.finalScore = undefined
                        s.contentItemResultType = s.contentItemResultType && s.contentItemResultType > 2 ? s.contentItemResultType : undefined
                    }
                }
            })
            setStudentsGraded(students.every(s => {
                const studentScores = [...s.scores.values()]
                if ((s.isNoShowTechResultsSelected && (studentScores.length === 0 || studentScores.filter(Boolean).length === 0))) {
                    return true
                } else {
                    return s.scores.size === tasksRatings.length && !studentScores.includes(0)
                }
            }))
            const currentStudentScores = [...currentStudent.scores.values()]
            setShowFinalScore(currentStudent.scores.size === tasksRatings.length && !currentStudentScores.includes(0))
            currentStudent.isActivitiesCommentsDisabled = (currentStudent.isNoShowTechResultsSelected &&
                (currentStudentScores.length === 0 || currentStudentScores.filter(Boolean).length === 0))
            if (currentStudent.isActivitiesCommentsDisabled) {
                currentStudent.comment = ''
                setComments('')
            }
        }
    }, [currentStudent, score])

    useEffect(() => {
        setResultsSent(false)
        const scrollElement = document.getElementById('summary-panel')
        if (scrollElement !== null) {
            scrollElement.scrollTop = 0
        }
    }, [currentStudent])

    useEffect(() => {
        if (assessment.status === 'encounter') {
            if (currentStudent.isGraded || currentStudent.isScoreOrResultChanged) {
                const currentStudentScores = [...currentStudent.scores.values()]
                currentStudent.isActivitiesCommentsDisabled = (currentStudent.isNoShowTechResultsSelected &&
                (currentStudentScores.length === 0 || currentStudentScores.filter(Boolean).length === 0))
                if (currentStudent.isActivitiesCommentsDisabled) {
                    currentStudent.comment = ''
                    setComments('')
                }
                setStudentsGraded(students.every(s => {
                    const studentScores = [...s.scores.values()]
                    if ((s.isNoShowTechResultsSelected && (studentScores.length === 0 || studentScores.filter(Boolean).length === 0))) {
                        return true
                    } else {
                        return s.scores.size === tasksRatings.length && !studentScores.includes(0)
                    }
                }))
                if (!assessment.classDetails.isClosed) {
                    void saveResults(true, currentStudent)
                }
            }
        }
    }, [classResult, finalScore])

    useEffect(() => {
        if (assessment.status === 'encounter') {
            assessment.classDetails.isClosed = isEncounterClassClosed
            setDisableSaving(assessment.classDetails.isClosed || !studentsGraded || isSavingAssessmentStatus || (!studentsGraded && isCommentsSaving))
        }
    }, [assessment, studentsGraded, savingStatus])

    useEffect(() => {
        if (!isSavingAssessmentStatus) {
            setIsCommentsSaving(false)
        }
    }, [savingStatus])

    return (
        <Wrapper>
            <InnerWrapper>
                <StudentTabs>
                    {students.map(s => (
                        <StudentTab key={s.userId} active={s.firstName === currentStudent.firstName}
                            onClick={() => {
                                setCurrentStudent(s)
                                setIsCommentsSaving(false)
                            }}
                            isDisabled={isSavingAssessmentStatus && !isCommentsSaving}
                        >
                            <Avatar sx={{ backgroundImage: s.photoUris.medium ? `url('${getSameOriginPhotoUrl(s.photoUris.medium)}')` : 'none' }}>
                                {!s.photoUris.medium ? (
                                    <span id='participant-initials'>{getDisplayName(s, 'initials')}</span>
                                ) : null}
                            </Avatar>
                            <p>{getDisplayName(s, 'student-name')}</p>
                        </StudentTab>
                    ))}
                </StudentTabs>
                <ContentWrapper>
                    <ResultsWrapper>
                        <AvatarWrapper>
                            <Avatar sx={{
                                backgroundImage: currentStudent.photoUris.medium
                                    ? `url('${getSameOriginPhotoUrl(currentStudent.photoUris.medium)}')` : 'none'
                            }}>
                                {!currentStudent.photoUris.medium ? (
                                    <span id='participant-initials'>{getDisplayName(currentStudent, 'initials')}</span>
                                ) : null}
                            </Avatar>
                            <p>{getDisplayName(currentStudent, 'student-name')}</p>
                        </AvatarWrapper>
                        <OffsetWrapper>
                            {showFinalScore ? (
                                <ScoreWrapper>
                                    <span>Final Score</span>
                                    <p>{finalScore}%</p>
                                </ScoreWrapper>
                            ) : null}
                            <ResultOffsetWrapper>
                                <Select
                                    value={classResult} displayEmpty
                                    onChange={e => {
                                        setIsCommentsSaving(false)
                                        setClassResult(e.target.value)
                                        currentStudent.contentItemResultType = +e.target.value
                                        currentStudent.isScoreOrResultChanged = true
                                        currentStudent.isScoreAndResultAvailableInAPI = true
                                        currentStudent.isNoShowTechResultsSelected = currentStudent.contentItemResultType > 2
                                        currentStudent.isActivitiesCommentsDisabled = currentStudent.isNoShowTechResultsSelected &&
                                            [...currentStudent.scores.values()].length === 0
                                        setResultsSent(false)
                                    }}
                                    disabled={isEncounterClassClosed || isSavingAssessmentStatus}
                                    sx={theme => ({
                                        borderRadius: theme.spacing(1),
                                        width: theme.spacing(21),
                                        minWidth: theme.spacing(21),
                                        '> div': {
                                            padding: theme.spacing(1),
                                            display: 'flex',
                                            img: { marginRight: theme.spacing(1) },
                                            p: {
                                                fontSize: theme.spacing(1.8),
                                                span: {
                                                    fontSize: theme.spacing(1.4),
                                                    color: theme.palette.secondary.darker
                                                }
                                            }
                                        }
                                    })}
                                    MenuProps={{
                                        PaperProps: { sx: theme => ({ borderRadius: theme.spacing(1) }) },
                                        MenuListProps: { sx: theme => ({ padding: theme.spacing(1) }) }
                                    }}
                                >
                                    <StyledMenuItem disabled value=''>
                                        <em>Final Result</em>
                                    </StyledMenuItem>
                                    { classResultDefaultValues.map((result: ClassResultType, i: number) =>
                                        <StyledMenuItem value={result.id} key={i}>
                                            <img src={result.imageUrl} />
                                            { result.id !== 0 && result.id <= 2 ? <p>{result.label}</p> : <p>(R) <span>{result.label}</span></p>}
                                        </StyledMenuItem>)
                                    }
                                </Select>
                            </ResultOffsetWrapper>
                            <ButtonWrapper>
                                <Button
                                    id='display-to-the-student'
                                    color='error'
                                    variant='contained'
                                    sx={theme => ({
                                        height: theme.spacing(5),
                                        padding: '4px 8px 4px 12px',
                                        backgroundColor: (!currentStudent.isScoreOrResultChanged) ? '#fbb5bc' : '#f12c3e',
                                        pointerEvents: (!currentStudent.isScoreOrResultChanged) ? 'none' : 'all',
                                        '&:hover': {
                                            backgroundColor: (!currentStudent.isScoreOrResultChanged) ? '#fbb5bc' : '#f12c3e'
                                        }
                                    })}
                                    disabled={
                                        !showFinalScore ||
                                        isSavingAssessmentStatus && !isCommentsSaving ||
                                        currentStudent.isActivitiesCommentsDisabled ||
                                        currentStudent.isNoShowTechResultsSelected ||
                                        isEncounterClassClosed
                                    }
                                    onClick={() => displayToStudents(currentStudent.userId)}>
                                    { !resultsSent ? 'Display to the student' : 'Displayed to the student'}
                                </Button>
                            </ButtonWrapper>
                        </OffsetWrapper>
                    </ResultsWrapper>
                    <ActivitiesWrapper>
                        <p>Activities</p>
                        <ActivitiesList id='summary-panel'>
                            {tasksRatings.map(r => (
                                <Activity key={r.rating.id}>
                                    <div>
                                        <span>{r.number}</span>
                                        <p>{r.description}</p>
                                    </div>
                                    <Grade
                                        onClick={e =>
                                            currentStudent.isActivitiesCommentsDisabled || isEncounterClassClosed ||
                                            isSavingAssessmentStatus && !isCommentsSaving ? {}
                                                : handleGradeChange(e.target as HTMLElement, currentStudent, r)
                                        }
                                        grade={currentStudent.scores.get(r.rating.id)}
                                        isDisabled={currentStudent.isActivitiesCommentsDisabled || isEncounterClassClosed ||
                                            isSavingAssessmentStatus && !isCommentsSaving}
                                    >
                                        <span>1</span>
                                        <span>2</span>
                                        <span>3</span>
                                        <span>4</span>
                                    </Grade>
                                </Activity>
                            ))}
                            <CommentsLabel>Comments</CommentsLabel>
                            <StudentComment
                                onBlur={handleCommentChange}
                                value={comments}
                                onChange={e => handleTextAreaChange(e.target.value)}
                                disabled={currentStudent.isActivitiesCommentsDisabled || isEncounterClassClosed || isSavingAssessmentStatus}
                                isDisabled={currentStudent.isActivitiesCommentsDisabled || isEncounterClassClosed || isSavingAssessmentStatus}
                            />
                        </ActivitiesList>
                    </ActivitiesWrapper>
                </ContentWrapper>
                <ButtonsWrapper>
                    <SavingMessage isError={savingStatus === 'error'}>
                        {isSavingAssessmentStatus ? 'Saving...' : savingStatus === 'error' ? 'An error occurred while saving' : ''}
                    </SavingMessage>
                    <RectangleButton
                        onClick={saveToGradeBooks}
                        text='Send to Grade Books'
                        color='error'
                        onMouseDown={saveToGradeBooks}
                        disabled={disableSaving || isEncounterClassClosed}
                    />
                </ButtonsWrapper>
            </InnerWrapper>
            {showModal ? <SummaryModal closeModal={isOnSubmit => handleCloseSummaryModal(isOnSubmit)} isError={false} /> : null}
            {showErrorModal ? <SummaryModal closeModal={() => setErrorModalVisibility(false)} isError={true} /> : null}
        </Wrapper>
    )
}

export { SummaryPanel }
