enum ExceptionType {
    NoAVDecodingCapability,
    ClassroomStateError,
    BookingCheckError,
    ActivityScheduleError,
    RtcClientNotSupported,
    PPTsListError,
    UnknownPresentationUrl,
    UnexpectedPresentationStatus,
    GetPrivateChatError,
    UnitAssessmentNotFound,
    AssessmentNotCompleted,
    HttpError
}

export { ExceptionType }
