import { WseUserId, ClassroomId, ChatList } from 'api/types'
import { apiRequest } from 'api/api-request'
import { ExceptionType, GOCException } from 'exceptions'

const getPrivateChat = async (auth: string, classroomId: ClassroomId, userId: WseUserId): Promise<ChatList> => {
    const response = await apiRequest<{ data: ChatList | null; msg: string | null }>({
        path: `/chat/getPrivateChat/${classroomId}/${userId}`,
        method: 'GET',
        auth
    })

    if (response.data) {
        return response.data

    } else {
        throw new GOCException(ExceptionType.GetPrivateChatError, response.msg ?? '')
    }
}

export { getPrivateChat }
