import { styled } from '@mui/system'
import { RectangleButton } from 'components/buttons'
import { GroupIcon } from 'icons'
import { LogoContainer } from 'pages/logoContainer/LogoContainer'
import { useNavigateBackDisabled } from 'pages/useNavigateBackDisabled'

const InnerWrapper = styled('div')(() => ({
    textAlign: 'center'
}))

const ThanksMessageWrapper = styled('div')(() => ({
    textAlign: 'center',
    marginTop: 45,
    marginBottom: 40
}))

const Title = styled('div')(({ theme }) => ({
    fontWeight: 800,
    fontSize: theme.spacing(6),
    color: theme.palette.primary.darker,
    lineHeight: theme.spacing(8),
    letterSpacing: '-0.01em'
}))

const Message = styled('div')<{ isTeacher?: boolean }>(({ theme, isTeacher }) => ({
    fontWeight: 400,
    fontSize: 18,
    color: 'rgba(0, 0, 0, 0.65)',
    paddingTop: theme.spacing(2),
    width: isTeacher ? '395px' : '100%',
    letterSpacing: '0.02em',
    lineHeight: '130%'
}))

const ThankYouScreen: React.FC = () => {
    useNavigateBackDisabled()
    const isTeacher = sessionStorage.getItem('role') === 'teacher'
    const coreCourseDashboardUrl = sessionStorage.getItem('callbackUrl')!

    const goBackToDashboard = (): void => {
        window.location.href = coreCourseDashboardUrl
    }

    return (
        <LogoContainer>
            <InnerWrapper>
                {!isTeacher && <img src={GroupIcon} />}
                <ThanksMessageWrapper>
                    <Title>Thanks for joining</Title>
                    <Message isTeacher={isTeacher}>
                        {
                            (isTeacher)
                                ? 'Congratulations! You just helped your students in their path to English excellence.'
                                : 'Did you enjoy your class? Get ready for the next one!'
                        }
                    </Message>
                </ThanksMessageWrapper>
                <RectangleButton
                    id='book-your-next-class-button'
                    color='error'
                    onClick={goBackToDashboard}
                    text={(isTeacher) ? 'Go to dashboard' : 'Book your next class'}
                />
            </InnerWrapper>
        </LogoContainer>
    )
}

export { ThankYouScreen, ThanksMessageWrapper, Title, Message }
