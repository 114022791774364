import { BreakoutRoomsState } from 'pages/useBreakouts'
import { BookingContext } from 'contexts/booking'
import { ConferenceContext } from 'contexts/conference'
import { ConferenceParticipant } from 'contexts/conference/types'
import { useContext } from 'react'
import { useFeatureCheck } from 'hooks/useFeatureCheck'

const order = (others: ConferenceParticipant[]): ConferenceParticipant[] => {
    const teacher = others.find(p => p.role === 'teacher')
    if (teacher) {
        others.unshift(...others.splice(others.indexOf(teacher), 1)) // put teacher first
    }

    return others
}

const getList = (breakouts?: BreakoutRoomsState): ConferenceParticipant[] => {
    const conference = useContext(ConferenceContext)
    const booking = useContext(BookingContext)

    if (conference.status === 'session-joined' && booking.status === 'validated') {
        const others = conference.participants.slice(1)

        if (breakouts) {
            const currentRoom = breakouts.getCurrentRoom()
            const roomUsersIds = currentRoom !== null
                ? breakouts.rooms[currentRoom].users
                : void 0

            if (roomUsersIds) {
                return others.filter(participant => {
                    const isTeacherJoined = participant.role === 'teacher' && breakouts.teacherRoom === currentRoom
                    const isAssigned = roomUsersIds.includes(participant.wseUserId)

                    if (isAssigned || isTeacherJoined) {
                        void conference.rtcSession.unmuteLocally(participant)
                        return true

                    } else {
                        void conference.rtcSession.excludeLocally(participant)
                        return false
                    }
                })

            } else {
                others.forEach(participant => {
                    void conference.rtcSession.unmuteLocally(participant)
                })
                return order(others)
            }

        } else {
            return order(others)
        }

    } else {
        return []
    }
}

export const useFilterParticipants = (breakouts?: BreakoutRoomsState): ConferenceParticipant[] => {
    const conference = useContext(ConferenceContext)
    const { isFullFeaturedMode } = useFeatureCheck()
    const list = getList(breakouts)

    if (isFullFeaturedMode) {
        return list

    } else {
        if (conference.status === 'session-joined') {
            const lastSpeaker = list.find(participant => participant.wseUserId === conference.lastSpeakerId)
            if (lastSpeaker) {
                for (const participant of list) {
                    if (participant.wseUserId !== conference.lastSpeakerId) {
                        void conference.rtcSession.stopRenderVideo(participant)
                    }
                }

                return [lastSpeaker]

            } else {
                return list.slice(0, 1)
            }

        } else {
            return []
        }
    }

}
