import { CommonIconButtonProps, StandardIconButton } from './StandardIconButton'
import { AssessmentIcon, AssessmentActiveIcon } from 'icons'

const AssessmentIconButton: React.FC<CommonIconButtonProps> = ({ id, active, disabled, size = 'medium', onClick }) => (
    <StandardIconButton
        id={id}
        color={active ? 'primary' : 'secondary'}
        icon={active ? AssessmentActiveIcon : AssessmentIcon}
        disabled={disabled}
        onClick={onClick}
        size={size}
    />
)

export { AssessmentIconButton }
