import { PrimusClient } from 'primus'
import { ChatNotificationEvent, ChatPrivateEvent, ChatPublicEvent } from 'primus/types/events'
import { ChatMessage, ChatList, WseUserId, NotificationCount, User } from 'api/types'
import { Conversation, Message } from './types'
import { mapChatListToConversations, mapEntireClassConversationMessages, mapMessage } from './utils'

const PUBLIC_CHAT = 'EVERYONE'

type ChatNotifications = { [key: WseUserId]: number }
type ChatMessageHandler = (message: Message, conversation: Conversation) => void
type NotificationHandler = (notification: ChatNotificationEvent) => void

class ChatClient {
    public conversations: Conversation[] = []
    public notifications: ChatNotifications
    private chatMessageHandlers: ChatMessageHandler[] = []
    private notificationHandlers: NotificationHandler[] = []

    constructor (
        private readonly currentUser: User,
        private readonly users: User[],
        private readonly primusClient: PrimusClient,
        privateChatList: ChatList,
        publicChat: ChatMessage[],
        notificationCount: NotificationCount
    ) {
        const userConversations = mapChatListToConversations(currentUser, users, privateChatList)
        const entireClassConversation: Conversation = {
            chatId: 'EVERYONE',
            firstName: 'Everyone',
            lastName: '',
            role: 'student',
            messages: mapEntireClassConversationMessages(currentUser, users, publicChat)
        }

        this.conversations = [entireClassConversation, ...userConversations]

        this.notifications = notificationCount[currentUser.id]

        this.registerPrimusEventsHandlers()
    }

    sendPublicMessage = (text: string): void => {
        const messageEventData: ChatPublicEvent = {
            text,
            recipient: PUBLIC_CHAT,
            sender: { id: this.currentUser.id },
            sentOn: Date.now(),
            taggedTo: ''
        }

        const notificationEventData = { senderId: this.currentUser.id, receiverId: PUBLIC_CHAT, notificationCount: 1 }

        this.primusClient.send('chat:public', messageEventData)
        this.primusClient.send('chat:notification', notificationEventData)

        const entireClassConversation = this.conversations.find(c => c.chatId === 'EVERYONE')

        if (entireClassConversation) {
            const message = mapMessage(this.currentUser, this.currentUser, messageEventData)
            entireClassConversation.messages.push(message)
            this.executeChatMessageHandlers(message, entireClassConversation)
        }
    }

    sendPrivateMessage = (text: string, recipient: WseUserId): void => {
        const messageEventData: ChatPrivateEvent = {
            text,
            recipient,
            sender: { id: this.currentUser.id },
            sentOn: Date.now(),
            taggedTo: ''
        }

        const notificationEventData = { senderId: this.currentUser.id, receiverId: recipient, notificationCount: 1 }

        this.primusClient.send('chat:private', messageEventData)
        this.primusClient.send('chat:notification', notificationEventData)

        const conversation = this.conversations.find(c => c.chatId === recipient)

        if (conversation) {
            const message = mapMessage(this.currentUser, this.currentUser, messageEventData)
            conversation.messages.push(message)
            this.executeChatMessageHandlers(message, conversation)
        }
    }

    addChatMessageHandler = (handler: ChatMessageHandler): void => {
        this.chatMessageHandlers.push(handler)
    }

    removeChatMessageHandler = (handler: ChatMessageHandler): void => {
        this.chatMessageHandlers = this.chatMessageHandlers.filter(h => h !== handler)
    }

    addNotificationHandler = (handler: NotificationHandler): void => {
        this.notificationHandlers.push(handler)
    }

    removeNotificationHandler = (handler: NotificationHandler): void => {
        this.notificationHandlers = this.notificationHandlers.filter(h => h !== handler)
    }

    readNotifications = (chatId: WseUserId): void => {
        const notificationData = { senderId: this.currentUser.id, receiverId: chatId, notificationCount: 0 }

        this.notifications[chatId] = 0
        this.primusClient.send('chat:notification', notificationData)
        this.executeNotificationHandlers(notificationData)
    }

    getTotalNotifications = (): number => {
        return Object.values(this.notifications).reduce((total, current) => total + current, 0)
    }

    private readonly executeChatMessageHandlers = (message: Message, conversation: Conversation): void => {
        this.chatMessageHandlers.forEach(handler => handler(message, conversation))
    }

    private readonly executeNotificationHandlers = (notification: ChatNotificationEvent): void => {
        this.notificationHandlers.forEach(handler => handler(notification))
    }

    private readonly registerPrimusEventsHandlers = (): void => {
        const publicMessageHandler = (messageData: ChatPublicEvent): void => {
            const conversation = this.conversations.find(c => c.chatId === 'EVERYONE')
            const sender = this.users.find(u => u.id === messageData.sender.id)

            if (conversation && sender) {
                const message = mapMessage(this.currentUser, sender, messageData)
                conversation.messages.push(message)

                this.executeChatMessageHandlers(message, conversation)
            }
        }

        const privateMessageHandler = (messageData: ChatPrivateEvent): void => {
            if (messageData.recipient === this.currentUser.id) {
                const conversation = this.conversations.find(c => c.chatId === messageData.sender.id)
                const sender = this.users.find(u => u.id === messageData.sender.id)

                if (conversation && sender) {
                    const message = mapMessage(this.currentUser, sender, messageData)
                    conversation.messages.push(message)

                    this.executeChatMessageHandlers(message, conversation)
                }
            }
        }

        const notificationHandler = (notificationEventData: ChatNotificationEvent): void => {
            if (notificationEventData.notificationCount > 0) {
                if (notificationEventData.receiverId === this.currentUser.id) {
                    this.notifications[notificationEventData.senderId] += notificationEventData.notificationCount
                } else if (notificationEventData.receiverId === 'EVERYONE') {
                    this.notifications.EVERYONE += notificationEventData.notificationCount
                }
                this.executeNotificationHandlers(notificationEventData)
            }
        }

        this.primusClient.on('chat:public', publicMessageHandler)
        this.primusClient.on('chat:private', privateMessageHandler)
        this.primusClient.on('chat:notification', notificationHandler)
    }
}

export { ChatClient }
export type { ChatNotifications }
