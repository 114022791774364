import { Theme } from '@mui/material/styles'
import { Components } from '@mui/material'

const MuiIconButton: Components<Theme>['MuiIconButton'] = {
    variants: [],
    styleOverrides: {
        root: ({ theme }) => ({
            backgroundColor: theme.palette.neutral.darker,
            width: theme.spacing(6),
            height: theme.spacing(6),
            '&:hover': {
                backgroundColor: theme.palette.neutral.hover
            }
        }),
        colorPrimary: ({ theme }) => ({
            backgroundColor: theme.palette.primary.darker,
            color: theme.palette.neutral.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.darker
            }
        }),
        colorError: ({ theme }) => ({
            backgroundColor: theme.palette.error.main,
            color: theme.palette.neutral.main,
            '&:hover': {
                backgroundColor: theme.palette.error.hover
            }
        }),
        sizeSmall: ({ theme }) => ({
            width: theme.spacing(4),
            height: theme.spacing(4)
        })
    }
}

export { MuiIconButton }
