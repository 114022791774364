import { useContext, useEffect, useState } from 'react'
import { ConferenceContext } from 'contexts/conference'
import { SnackbarOrigin } from '@mui/material'
import { MessageWrapper, StatusPanel } from '.'
import { VIDEO_MIRRORED, VIDEO_QUALITY } from 'zoom/config'
import { ZoomClientPartialOptions } from 'zoom/types'
import { StandardButton } from 'components/buttons'
import { CustomizedSnackBar } from 'components/snackbar/CustomizedSnackBar'

interface State extends SnackbarOrigin {
    open: boolean
}

const ConnectionStatus: React.FC<{ isDiagnosticPage: boolean }> = ({ isDiagnosticPage }) => {
    const conference = useContext(ConferenceContext)
    const [joining, setJoining] = useState<boolean>(false)
    const viewRetryErrorSnackbar = conference.viewRetryErrorSnackbar

    const [states] = useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    })

    const { vertical, horizontal } = states

    useEffect(() => {
        const diagnoseStatus = sessionStorage.getItem('diagnoseStatus')
        const { isVideoMuted, isAudioMuted, isVideoDisabled, isDiagnosed, selectedCamera } =
        JSON.parse(diagnoseStatus || '{"isVideoMuted":false,"isAudioMuted":false,"isVideoDisabled":false,"isDiagnosed":true, "selectedCamera": ""}')
        if (conference.status === 'ready-to-join' && isDiagnosed && isDiagnosticPage) {
            void conference.joinSession<ZoomClientPartialOptions>({
                videoQuality: VIDEO_QUALITY,
                video: {
                    mirrored: VIDEO_MIRRORED,
                    originalRatio: false,
                    cameraId: selectedCamera
                },
                isVideoMuted,
                isAudioMuted,
                isVideoDisabled
            }).catch(error => {
                console.log('Join-session-connectionstatus-Join class button click', error)
                conference.setRetryError(true)
            })
        }
    }, [conference.status])

    if (conference.status === 'session-joined') {
        return null

    } else if (conference.status === 'waiting-for-classroom-info' || conference.status === 'initializing-client') {
        return (
            <StatusPanel id='session-not-joined-panel'><MessageWrapper>Checking the meeting booking</MessageWrapper></StatusPanel>
        )

    } else if (conference.status === 'ready-to-join') {
        const { selectedCamera } = JSON.parse(sessionStorage.getItem('diagnoseStatus') || '{"selectedCamera": ""}')
        if (!isDiagnosticPage && !joining) {
            return (
                <StatusPanel id='session-not-joined-panel'>
                    <StandardButton
                        id='enter-classroom-button'
                        text='Enter the classroom'
                        color='error'
                        onClick={() => {
                            void conference.joinSession<ZoomClientPartialOptions>({
                                videoQuality: VIDEO_QUALITY,
                                video: {
                                    mirrored: VIDEO_MIRRORED,
                                    originalRatio: false,
                                    cameraId: selectedCamera
                                }
                            }).catch(error => {
                                console.log('Join-session-connectionstatus-Enter the classroom button click', error)
                                conference.setRetryError(true)
                            })
                            setJoining(true)
                        }} />
                </StatusPanel>
            )
        } else {
            return (
                <>
                    <StatusPanel id='session-not-joined-panel'><MessageWrapper>Joining the classroom</MessageWrapper></StatusPanel>
                    <CustomizedSnackBar
                        open={viewRetryErrorSnackbar}
                        anchorOrigin={{ vertical, horizontal }}
                        message='Something went wrong while joining the class. Please try again.'
                        isActionNeeded={true}
                    />
                </>
            )
        }
    } else {
        return null // never
    }
}

export { ConnectionStatus }
