import { useTimer } from 'components/timer/useTimer'
import { MinusIconButton, PlayPauseIconButton, PlusIconButton } from 'components/icon-buttons'
import { FC } from 'react'
import { Panel } from './Panel'
import { Indicator } from './Indicator'

type TimerProperties = {
    onDurationChange?: (requestedValue: number, delta: number) => void
    startTime?: number | undefined
    duration: number | undefined
    step?: number
    max?: number
    min?: number
    id?: string
}

export const Timer: FC<TimerProperties> = ({ startTime, duration, id, max, min, onDurationChange, step }) => {
    const [remainingTime, toggle, isPaused] = useTimer(startTime, duration)

    const isDurationSet = duration !== void 0 && isFinite(duration)
    const canIncrease = isDurationSet && duration < (max ?? 60)
    const canDecrease = isDurationSet && duration > (min ?? 0)
    const delta = (step ?? 1)

    if (remainingTime !== void 0) {
        return (
            <Panel id={id}>
                <PlayPauseIconButton size='small' disabled={remainingTime === void 0} onClick={toggle} active={!isPaused} />
                <Indicator seconds={remainingTime} />

                {onDurationChange && isDurationSet ? (<>
                    <MinusIconButton active={canDecrease} disabled={!canDecrease} size='small' onClick={() => {
                        onDurationChange(duration - delta, -delta)
                    }} />
                    <PlusIconButton active={canIncrease} disabled={!canIncrease} size='small' onClick={() => {
                        onDurationChange(duration + delta, delta)
                    }} />
                </>) : null}

            </Panel>
        )

    } else if (duration !== void 0) {
        return (
            <Panel id={id}>

                {onDurationChange && isDurationSet ? (<>
                    <MinusIconButton active={canDecrease} disabled={!canDecrease} size='small' onClick={() => {
                        onDurationChange(duration - delta, -delta)
                    }} />
                </>) : null}

                <Indicator seconds={duration * 60} />

                {onDurationChange && isDurationSet ? (<>
                    <PlusIconButton active={canIncrease} disabled={!canIncrease} size='small' onClick={() => {
                        onDurationChange(duration + delta, delta)
                    }} />
                </>) : null}

            </Panel>
        )

    } else {
        return null
    }
}
