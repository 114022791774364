import { apiRequest } from '../api-request'
import { mock } from './mocks/units'
import { Guid, ResultTypeId } from './types'

export type Rating = {
    id: Guid
    sequence: number
    name: string
    scoreType: ResultTypeId
    helpText: string
}

export type UnitRating = {
    /**
     * Unit number
     */
    number: number

    /**
     * Ratings
     */
    ratingSchemaPartItems: Rating[]
}

const getUnitRatingsByGuid = async (auth: string, unitGuid: string): Promise<UnitRating[]> => {
    if (!process.env.REACT_APP_USE_ASSESSMENT_MOCKS) {
        return await apiRequest<UnitRating[]>({
            baseUrl: process.env.REACT_APP_BS_API_URL,
            path: `/units/${unitGuid}/ratingSchema`,
            method: 'GET',
            auth: process.env.REACT_APP_QUALWORLD_AUTH ?? auth
        })

    } else {
        return mock
    }
}

export { getUnitRatingsByGuid }
