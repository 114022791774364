import { Route } from 'react-router-dom'
import { StudentClassroom } from './student/classroom'
import { TeacherClassroom } from './teacher/classroom'
import { DiagnosticsPage } from './Diagnostics/DiagnosticsPage'
import { Logo } from './logo/Logo'
import { AppError } from '../exceptions'
import { Autologin } from './auto-login/Autologin'
import { Quickslot } from './quick-slot/QuickSlot'
import { ThankYouScreen } from './thankyouscreen/ThankYouScreen'
import { ExpelScreen } from './ExpelScreen/ExpelScreen'

const routes = (
    <Route path='/' errorElement={<AppError />}>
        <Route index element={<Logo />} />

        <Route path='/quickslot' element={<Quickslot />} />

        <Route path='/autologin/:classroomId/:userId/:auth/:callbackUrl'>
            <Route path=':isTeens' element={<Autologin />} />
            <Route path='' element={<Autologin />} />
        </Route>

        <Route path='/classroom/*' element={<StudentClassroom />} />

        <Route path='/teacher'>
            <Route path='classroom/*' element={<TeacherClassroom />} />
        </Route>

        <Route path='/thanks' element={<ThankYouScreen />} />
        <Route path='/expel' element={<ExpelScreen />} />

        <Route path='/diagnostics' element={<DiagnosticsPage startTime={Date.now()} />} />

    </Route>
)

export { routes }
