import { CommonIconButtonProps, StandardIconButton } from './StandardIconButton'
import { MicIcon, MuteIcon } from 'icons'

const MuteIconButton: React.FC<CommonIconButtonProps> = ({ id, active, disabled, size = 'medium', onClick }) => (
    <StandardIconButton
        id={id}
        color='secondary'
        icon={active ? MuteIcon : MicIcon}
        disabled={disabled}
        onClick={onClick}
        size={size}
    />
)

export { MuteIconButton }
