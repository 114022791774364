import { useContext, useEffect, useState, useRef } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { ConferenceContext } from 'contexts/conference'
import { SessionWrapper } from 'components/wrappers'
import { ConnectionStatus } from 'components/status-panels'
import { ZoomArea } from 'components/participants'
import { Sidebar, SidebarProps } from 'components/sidebar'
import { useBreakouts } from 'pages/useBreakouts'
import { TopControls, BottomControls, BreakoutRoomsModal, ShareCueCardsModal } from './components'
import { ContentWrapper, InnerWrapper } from './components/wrappers'
import { SpeakView, SlidesView, CueCardsView, WhiteboardView } from '.'
import { LeaveDialog } from 'components/dialog/LeaveDialog'
import { useTabClose } from 'pages/useTabClose'
import { useLeaveLesson } from 'hooks/useLeaveLesson'
import { useFeatureCheck } from 'hooks/useFeatureCheck'
import { useConnectionStatus } from 'hooks/useConnectionStatus'
import { CustomizedSnackBar } from 'components/snackbar/CustomizedSnackBar'
import { SnackbarOrigin } from '@mui/material'
import { WiFiIcon } from 'components/snackbar/WiFiIcon'
import { WiFiWarningIcon } from 'components/snackbar/WiFiWarningIcon'
import { useNavigateBackDisabled } from 'pages/useNavigateBackDisabled'
import { UserAwayEvent } from 'primus/types/events'

interface State extends SnackbarOrigin {
    open: boolean
}

const TeacherClassroom: React.FC = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const conference = useContext(ConferenceContext)
    const breakouts = useBreakouts()
    const { onLeaveHandler } = useLeaveLesson()
    useTabClose()
    const { isFullFeaturedMode } = useFeatureCheck()
    useNavigateBackDisabled()

    const [currentSidebar, setCurrentSidebar] = useState<SidebarProps>({ type: null })
    const [viewBorModal, setBorModalVisibility] = useState<boolean>(false)
    const [viewShareCueCardModal, setShareCueCardModalVisibility] = useState<boolean>(false)
    const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState(false)
    const [isDiagnosticPage, setIsDiagnosticPage] = useState(false)
    const [innerWrapperHeight, setInnerWrapperHeight] = useState<number>(0)
    const [zoomAreaHeight, setZoomAreaHeight] = useState<number>(0)
    const [topControlsHeight, setTopControlsHeight] = useState<number>(0)
    const [isSpeakMode, setIsSpeakMode] = useState<boolean>(true)
    const topControlsRef = useRef<HTMLDivElement | null>(null)
    const bottomControlsRef = useRef<HTMLDivElement | null>(null)
    const participantsWrapperRef = useRef<HTMLDivElement | null>(null)
    const [viewNetworkOnlineSnackbar, setNetworkOnlineSnackbar] = useState<boolean>(false)
    const [viewNetworkOfflineSnackbar, setNetworkOfflineSnackbar] = useState<boolean>(false)
    const { connectionAlive } = useConnectionStatus()
    const [states] = useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    })

    const { vertical, horizontal } = states

    const restoreActiveScreen = (activeScreen: string): void => {
        switch (activeScreen) {
            case 'avchat':
                return navigate('/teacher/classroom')
            case 'slides':
                return navigate('/teacher/classroom/slides')
            case 'whiteboard':
                return navigate('/teacher/classroom/whiteboard')
            default:
                return void 0
        }
    }

    const toggleLeaveDialog = (): void => {
        setIsLeaveDialogOpen(prevState => !prevState)
    }
    const userAwayHandler = (eventData: UserAwayEvent): void => {
        if (conference.status === 'session-joined') {
            conference.setStudentAwayStatus(eventData)
        }
    }

    const showCueCardHandler = async (): Promise<void> => {
        setShareCueCardModalVisibility(true)
    }

    useEffect(() => {
        const { isDiagnostic } = state || { isDiagnostic: false }
        setIsDiagnosticPage(!!isDiagnostic)
    }, [])

    useEffect(() => {
        if (conference.status === 'session-joined') {
            if (connectionAlive) {
                setNetworkOfflineSnackbar(false)
                setNetworkOnlineSnackbar(true)
            } else {
                setNetworkOfflineSnackbar(true)
                setNetworkOnlineSnackbar(false)
            }
        }
    }, [connectionAlive])

    useEffect(() => {
        if (conference.status === 'session-joined' && (conference.timeLeft <= 0)) {
            onLeaveHandler(true, '/thanks')
        }
    }, [conference.timeLeft])

    useEffect(() => {
        if (conference.status === 'session-joined') {
            restoreActiveScreen(conference.classroomStateOnJoin.dcAppData.activeScreen)
            conference.primus.on('user:away', userAwayHandler)
        }
    }, [conference.status])

    useEffect(() => {
        if (viewBorModal && conference.participants.length < 3) {
            setBorModalVisibility(false)
        }
    }, [conference.participants])

    useEffect(() => {
        if (breakouts?.isActive && breakouts?.mode === 'speak') {
            navigate('/teacher/classroom')
        } else if (breakouts?.isActive && breakouts?.mode === 'cue-cards') {
            navigate('/teacher/classroom/cue-cards')
        } else if (breakouts?.isActive && breakouts?.mode === 'slides') {
            navigate('/teacher/classroom/slides')
        }
    }, [breakouts.isActive, breakouts.mode])

    useEffect(() => {
        if (conference.status === 'session-joined' && !isFullFeaturedMode && breakouts?.isActive) {
            conference.participants.forEach(participant => {
                if (participant.role !== 'teacher') {
                    void conference.rtcSession.stopRenderVideo(participant)
                }
            })
        }
    }, [breakouts.isActive])

    useEffect(() => {
        if (topControlsRef.current && bottomControlsRef.current) {
            const onResize = (): void => {
                if (topControlsRef.current && bottomControlsRef.current) {
                    const height = topControlsRef.current.clientHeight + bottomControlsRef.current.clientHeight
                    setTopControlsHeight(topControlsRef.current.clientHeight)
                    setInnerWrapperHeight(height)
                    setZoomAreaHeight(participantsWrapperRef.current?.clientHeight ?? height)
                }
            }

            const resObs = new ResizeObserver(onResize)
            resObs.observe(topControlsRef.current)
        }
    }, [topControlsRef.current, bottomControlsRef.current, participantsWrapperRef.current])

    useEffect(() => {
        setIsSpeakMode(!participantsWrapperRef.current)
    }, [participantsWrapperRef.current])

    return (
        <SessionWrapper restrictTo='teacher'>
            {(isFullFeaturedMode || (!isFullFeaturedMode && !breakouts?.isActive)) &&
                <ZoomArea sidebarOpen={!!currentSidebar.type}
                    top={topControlsHeight}
                    height={zoomAreaHeight}
                    isSpeakMode={isSpeakMode}
                />
            }
            <LeaveDialog isOpen={isLeaveDialogOpen} toggleLeaveDialog={toggleLeaveDialog} isTeacher />
            <div ref={topControlsRef}>
                <TopControls breakouts={breakouts}
                    viewBorModal={viewBorModal}
                    showBorModal={() => setBorModalVisibility(true)}
                    toggleLeaveDialog={toggleLeaveDialog}
                />
            </div>
            <InnerWrapper height={innerWrapperHeight}>
                <CustomizedSnackBar
                    open={viewNetworkOnlineSnackbar}
                    anchorOrigin={{ vertical, horizontal }}
                    Icon={WiFiIcon}
                    messageTitle='You are back online! '
                    message='Please reload the page.'
                    isActionNeeded={true} />
                <CustomizedSnackBar
                    open={viewNetworkOfflineSnackbar}
                    anchorOrigin={{ vertical, horizontal }}
                    Icon={WiFiWarningIcon}
                    messageTitle='Heads up!'
                    message='Your connection seems a little slow. Your audio or video may not be working well.'
                />
                <ContentWrapper sidebarOpen={!!currentSidebar.type}>
                    {
                        conference.status !== 'session-joined' ? (
                            <ConnectionStatus isDiagnosticPage={isDiagnosticPage} />
                        ) : (
                            <Routes>
                                <Route index element={<SpeakView breakouts={breakouts} />} />
                                <Route path='/slides' element={<SlidesView breakouts={breakouts} wrapperRef={participantsWrapperRef} />} />
                                <Route path='/whiteboard' element={<WhiteboardView breakouts={breakouts} wrapperRef={participantsWrapperRef} />} />
                                <Route path='/cue-cards' element={<CueCardsView />} />
                            </Routes>
                        )
                    }
                </ContentWrapper>
                <Sidebar currentSidebar={currentSidebar} setCurrentSidebar={setCurrentSidebar} />
            </InnerWrapper>
            <div ref={bottomControlsRef}>
                <BottomControls breakouts={breakouts}
                    currentSidebar={currentSidebar}
                    setCurrentSidebar={setCurrentSidebar}
                    showCueCardModal={showCueCardHandler}
                />
            </div>
            {viewBorModal ? (
                <BreakoutRoomsModal breakouts={breakouts} close={() => setBorModalVisibility(false)} />
            ) : null}
            {viewShareCueCardModal ? (
                <ShareCueCardsModal close={() => setShareCueCardModalVisibility(false)} />
            ) : null}
        </SessionWrapper>
    )
}

export { TeacherClassroom }
