import { FC, ReactNode, useContext, useEffect, useState, useReducer, useRef, MutableRefObject } from 'react'
import { BookingContext } from '../booking'
import { RtcSession } from './types'
import { ConferenceContext, initialState } from './ConferenceContext'
import { reducer } from './ConferenceReducer'
import { ZoomClientInitializerParams, ZoomRTCProviderId } from 'zoom'
import { ExceptionType, GOCException } from 'exceptions'
import { initializePrimusClient } from 'primus'
import { User } from 'api/types'

interface setTimerParams {
    (callback: () => void, startAfter: number): void
}

const useTimer = (): { setTimer: setTimerParams; timerId: MutableRefObject<NodeJS.Timeout | undefined> } => {
    const timerId = useRef<NodeJS.Timeout>()

    const setTimer: setTimerParams = (callback, startAfter = 0) => {
        timerId.current = setTimeout(() => {
            callback()
        }, startAfter * 1000)
    }

    return { timerId, setTimer }
}

const useInterval = (): { intervalHandler: setTimerParams; intervalId: MutableRefObject<NodeJS.Timeout | undefined> } => {
    const intervalId = useRef<NodeJS.Timeout>()

    const intervalHandler = (callback: () => void, interval: number): void => {
        intervalId.current = setInterval(callback, interval)
    }

    return { intervalId, intervalHandler }
}

const Conference: FC<{ children: ReactNode }> = ({ children }) => {
    const [conferenceCanvas, setConferenceCanvas] = useState<HTMLCanvasElement | null>(null)
    const [selfView, setSelfView] = useState<HTMLCanvasElement | null>(null)
    const booking = useContext(BookingContext)
    const classroomId = useRef<string>()
    const rtcSession = useRef<RtcSession>()
    const auth = useRef<string>()
    const currentUser = useRef<User>()
    const { timerId, setTimer } = useTimer()
    const { intervalId, intervalHandler } = useInterval()

    const setTimerInterval = (): void => {
        const updateTimeLeft = (): void => {
            dispatch({ method: 'update-time-left' })
        }

        dispatch({ method: 'check-time-left' })

        intervalHandler(updateTimeLeft, 60000)
    }

    const setSessionTimer = (timeLeft: number): void => {
        const timeInMinutes = Math.floor(timeLeft / 60)
        const startAfter = timeLeft % 60

        dispatch({ method: 'set-time-left', timeLeft: timeInMinutes })
        setTimer(setTimerInterval, startAfter)
    }

    const [state, dispatch] = useReducer(reducer({ auth, classroomId, rtcSession, currentUser, setConferenceCanvas }), {
        ...initialState,

        setCanvas: (canvas, canvasSize, isSelfView = false) => {
            if (canvas) {
                if (isSelfView) {
                    setSelfView(canvas)

                } else if (rtcSession.current) {
                    void rtcSession.current.setCanvasSize(canvasSize.width, canvasSize.height).then(() => {
                        dispatch({
                            method: 'canvas-update', canvas, canvasSize
                        })
                    })

                } else {
                    dispatch({ method: 'canvas-update', canvas, canvasSize })
                }
            }
        },
        setSlideAnnotation: slideAnnotations => {
            if (slideAnnotations) {
                dispatch({ method: 'update-slide-annotations', slideAnnotations })
            }
        },
        setWhiteBoardAnnotation: whiteBoardAnnotations => {
            if (whiteBoardAnnotations) {
                dispatch({ method: 'update-whiteboard-annotations', whiteBoardAnnotations })
            }
        },
        setClassResults: isViewed => {
            dispatch({ method: 'update-class-result', isViewed })
        },
        setCueCardInfo: cueCardInfo => {
            if (cueCardInfo) {
                dispatch({ method: 'update-cue-card-data', cueCardInfo })
            }
        },
        setCueCardForStudent: cueCard => {
            if (cueCard) {
                dispatch({ method: 'update-student-cue-card', cueCard })
            }
        },
        setStudentAwayStatus: studentAwayStatus => {
            if (studentAwayStatus) {
                dispatch({ method: 'update-user-away', studentAwayStatus })
            }
        },
        setRetryError: viewRetryErrorSnackbar => {
            if (viewRetryErrorSnackbar) {
                dispatch({ method: 'set-retry-error', viewRetryErrorSnackbar })
            }
        }
    })

    useEffect(() => {
        if (booking.status === 'validated' && state.status === 'waiting-for-classroom-info' && conferenceCanvas) {
            dispatch({ method: 'set-status', status: 'initializing-client' })
            classroomId.current = booking.classroomId
            auth.current = booking.auth
            currentUser.current = booking.check.user
            const userId = booking.check.user.id
            const userRole = booking.check.user.role

            window.clarity('identify', userId, booking.check.session[ZoomRTCProviderId].topic)

            window.clarity('set', 'User Id', userId)
            window.clarity('set', 'Firstname', booking.check.user.firstName)
            window.clarity('set', 'Lastname', booking.check.user.lastName)
            window.clarity('set', 'Session Details', booking.check.session[ZoomRTCProviderId].topic)

            void Promise.all([
                new Promise<ZoomClientInitializerParams>((resolve, reject) => {
                    if (booking.check.session.rtcServiceProvider === ZoomRTCProviderId) {
                        resolve({
                            dispatch,
                            topic: booking.check.session[ZoomRTCProviderId].topic,
                            auth: booking.auth,
                            canvas: conferenceCanvas,
                            selfView,
                            wseEncodedUserInfo: {
                                fullName: `${booking.check.user.firstName} ${booking.check.user.lastName}`.substring(0, 16),
                                role: userRole,
                                id: userId,
                                micHardMuted: booking.check.user.micHardMuted
                            }
                        })

                    } else {
                        reject(new GOCException(ExceptionType.RtcClientNotSupported))
                    }
                }),
                initializePrimusClient({ classroomId: booking.classroomId, userId, userRole })
            ]).then(([clientParams, primus]) => {
                dispatch({
                    method: 'ready-to-join',
                    clientParams,
                    primus,
                    setSessionTimer
                })
            })
        }
    }, [booking.status, state.status, conferenceCanvas])

    useEffect(() => {
        return () => {
            clearTimeout(timerId.current)
            clearInterval(intervalId.current)
        }
    }, [])

    return (
        <ConferenceContext.Provider value={state}>
            {children}
        </ConferenceContext.Provider>
    )
}

export { Conference }
