import { styled } from '@mui/system'
import { FC } from 'react'

const Time = styled('span')<{ state: 'normal' | 'ending'; isForDiagnosticPage: boolean }>(({ theme, state, isForDiagnosticPage }) => ({
    display: 'inline-block',
    width: theme.spacing(7),
    textAlign: 'center',
    verticalAlign: !isForDiagnosticPage ? 'text-top' : 'unset',
    color: !isForDiagnosticPage ? state === 'ending' && theme.palette.error.main || theme.palette.secondary.main : '#000000'
}))

const toTimerString = (remainingTime: number): string => {
    const seconds = Math.floor(remainingTime)

    const m = Math.floor(seconds / 60).toString().padStart(2, '0')
    const s = (seconds % 60).toString().padStart(2, '0')

    return m + ':' + s
}

export const Indicator: FC<{ seconds: number; isForDiagnosticPage?: boolean }> = ({ seconds, isForDiagnosticPage = false }) => {
    return <Time state={ seconds < 10 ? 'ending' : 'normal' } isForDiagnosticPage={isForDiagnosticPage}>
        {toTimerString(seconds)}
    </Time>
}
