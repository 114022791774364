import { useState, useContext } from 'react'
import { styled } from '@mui/material'
import { AssessmentContext, useSaveScore, useSavingStatus } from 'contexts/assessment'
import { ActivityPanel, SummaryPanel } from '.'

const Tabs = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(1.5)
}))
const Tab = styled('span')<{ active: boolean; isDisabled: boolean }>(({ theme, active, isDisabled }) => ({
    fontWeight: '700',
    fontSize: theme.spacing(2.2),
    color: active ? theme.palette.primary.darker : theme.palette.secondary.darker,
    willChange: 'color',
    transition: 'color 0.2s ease-out',
    cursor: 'pointer',
    pointerEvents: isDisabled ? 'none' : 'auto'
}))

const Assessment: React.FC = () => {
    const assessment = useContext(AssessmentContext)

    const [savingStatus, setSavingStatus] = useSavingStatus()
    const [score, setScore] = useSaveScore(setSavingStatus)
    const [currentTab, setCurrentTab] = useState<'activity' | 'comments' | 'summary'>('activity')
    const [currentActivityIndex, setCurrentActivityIndex] = useState<number>(0)
    const [isCurrentTabSaving, setIsCurrentTabSaving] = useState<boolean>(false)
    const savingAssessment = savingStatus === 'saving' && !isCurrentTabSaving

    const handleTabClick = (tab: 'activity' | 'comments' | 'summary'): void => {
        if (!savingAssessment) {
            setCurrentTab(tab)
            isCurrentTabSaving && setIsCurrentTabSaving(false)
        }
    }

    if (assessment.status === 'encounter') {
        return (
            <>
                <Tabs>
                    <Tab
                        active={currentTab === 'activity'}
                        onClick={() => handleTabClick('activity')}
                        isDisabled={savingAssessment}
                    >
                        Activity
                    </Tab>
                    <Tab
                        active={currentTab === 'comments'}
                        onClick={() => handleTabClick('comments')}
                        isDisabled={savingAssessment}
                    >
                        Comments
                    </Tab>
                    <Tab
                        active={currentTab === 'summary'}
                        onClick={() => handleTabClick('summary')}
                        isDisabled={savingAssessment}
                    >
                        Summary
                    </Tab>
                </Tabs>
                {currentTab === 'summary' ? (
                    <SummaryPanel
                        tasksRatings={assessment.unitAssessment.tasksRatings}
                        students={assessment.students}
                        savingStatus={savingStatus}
                        setSavingStatus={setSavingStatus}
                        score={score}
                        setScore={setScore}
                        isClassClosed={assessment.classDetails.isClosed}
                        setIsCurrentTabSaving={setIsCurrentTabSaving}
                    />
                ) : (
                    <ActivityPanel
                        setCurrentTab={setCurrentTab}
                        currentTab={currentTab}
                        activitiesRatings={assessment.unitAssessment.activitiesRatings}
                        students={assessment.students}
                        currentActivityIndex={currentActivityIndex}
                        setCurrentActivityIndex={setCurrentActivityIndex}
                        score={score}
                        setScore={setScore}
                        setSavingStatus={setSavingStatus}
                        tasksRatings={assessment.unitAssessment.tasksRatings}
                        isClassClosed={assessment.classDetails.isClosed}
                    />
                )}
            </>
        )
    } else {
        return null
    }

}
export { Assessment }
