import { ZoomRTCProviderId } from 'zoom'
import { ExceptionType, GOCException } from 'exceptions'
import { apiRequest } from '../api-request'
import { ClassroomState } from './ClassroomState'

const createQuickslot = async (auth: string, sessionDuration: Number = 60, delay: Number = 0): Promise<ClassroomState> => {
    const response = await apiRequest<{ data: ClassroomState | null; msg: string }>({
        path: `/activity/x/${sessionDuration}/${delay}`,
        method: 'POST',
        auth,
        payload: {
            rtcServiceProvider: ZoomRTCProviderId
        }
    })

    if (response.data) {
        return response.data

    } else {
        throw new GOCException(ExceptionType.ActivityScheduleError, response.msg)
    }
}

export { createQuickslot }
