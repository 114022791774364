export type DisplayNameType = 'initials' | 'student-name' | 'teacher-name'
export interface PersonName {
    firstName: string
    lastName: string
}

export const getDisplayName = ({ firstName, lastName }: PersonName, type: DisplayNameType, showFullName?: boolean): string => {
    const fnInitial = firstName ? firstName[0] : ''
    const lnInitial = lastName ? lastName[0] : ''

    switch (type) {
        case 'teacher-name':
            return `${firstName} ${lnInitial} (Teacher)`

        case 'student-name':
            if (showFullName) {
                return `${firstName} ${lastName}`

            } else {
                return `${firstName} ${lnInitial}`
            }

        case 'initials':
            return fnInitial + lnInitial

    }
}

export const getSameOriginPhotoUrl = (url: string): string => {
    if (!url.includes('/userphoto')) {
        return url
    }

    const originalURL = new URL(url)

    originalURL.protocol = location.protocol
    originalURL.host = location.host

    return originalURL + ''
}
