import { styled } from '@mui/material'
import { CommonButtonProps, StandardButton } from './StandardButton'
import { ChatIcon, ChatActiveIcon } from 'icons'

const NotificationsIndicator = styled('span')(({ theme }) => ({
    display: 'block',
    color: theme.palette.neutral.main,
    backgroundColor: theme.palette.error.main,
    borderRadius: '50%',
    fontSize: theme.spacing(1.75),
    width: theme.spacing(3),
    aspectRatio: '1',
    lineHeight: theme.spacing(3),
    textAlign: 'center',
    border: `solid ${theme.spacing(0.4)} ${theme.palette.neutral.main}`,
    boxSizing: 'content-box'
}))
const NotificationWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    transform: 'translate(-15px, -10px) scale(0.6)',
    fontWeight: 600
}))

const ChatButton: React.FC<CommonButtonProps & { notifications?: number }> = ({ id, active, disabled, onClick, notifications }) => (
    <StandardButton
        id={id}
        color={active ? 'primary' : 'secondary'}
        iconSrc={active ? ChatActiveIcon : ChatIcon}
        disabled={disabled}
        onClick={onClick}
        text='Chat'
    >
        {notifications && !disabled ? (
            <NotificationWrapper>
                <NotificationsIndicator id='chat-button-notifications-indicator'>
                    {notifications < 10 ? notifications : '9+'}
                </NotificationsIndicator>
            </NotificationWrapper>
        ) : null}
    </StandardButton>
)

export { ChatButton, NotificationsIndicator }
