import { Dispatch } from 'react'
import { AudioChangeAction, Stream } from '@zoom/videosdk'
import { ZoomSignature } from 'api/zoom-signature/zoom-signature'
import { ClassroomState } from 'api/classroom/ClassroomState'
import { MediaCapabilities, WSEEncodedUserInfo, ZoomSDKClient, ZoomSDKStream } from './types'
import { ConferenceAction, RtcSession, ConferenceClient, IClientOptions } from 'contexts/conference/types'
import { ZoomSession } from './ZoomSession'
import { PrimusClient } from 'primus'
import { ChatClient } from 'chat'
import { getZoomConfig } from './config'
import { RollbarExceptionNames, rollbarService } from 'services/rollbar'

type ZoomClientConstructorParameters = {
    wseEncodedUserInfo: WSEEncodedUserInfo
    dispatch: Dispatch<ConferenceAction>
    signature: ZoomSignature
    canvas: HTMLCanvasElement
    selfView?: HTMLCanvasElement | null
    client: ZoomSDKClient
    topic: string
}

class ZoomClient implements ConferenceClient<IClientOptions> {
    private readonly params: ZoomClientConstructorParameters

    public constructor (params: ZoomClientConstructorParameters) {
        this.params = { ...params }
    }

    async waitForMediaCapabilities (mediaStream: ZoomSDKStream): Promise<MediaCapabilities> {
        return new Promise<MediaCapabilities>(resolve => {
            const mediaCapabilities: MediaCapabilities = { audio: {}, video: {}, share: {} }

            const timeout = setTimeout(() => {
                console.warn('Not all capabilities are ready', JSON.stringify(mediaCapabilities))
                resolve(mediaCapabilities)
            }, 5000)

            this.params.client.on('media-sdk-change', change => {
                mediaCapabilities[change.type][change.action] = change.result === 'success'
                const audio = mediaCapabilities.audio
                const video = mediaCapabilities.video
                const share = mediaCapabilities.share
                const status = [audio.decode, audio.encode, video.decode, video.encode, share.decode, share.encode]
                if (!status.includes(void 0)) {
                    resolve(mediaCapabilities)
                    clearTimeout(timeout)
                }
            })
        })
    }

    async enableHwAcceleration (mediaStream: ZoomSDKStream): Promise<void> {
        try {
            await mediaStream.enableHardwareAcceleration(true)

        } catch (e) {
            console.warn('hardware acceleration not available')
        }
    }

    async startAudioStream (mediaStream: typeof Stream, startMuted?: boolean): Promise<'muted' | 'unmuted'> {
        await new Promise((resolve, reject) => {
            console.log('before-startAudioStream')
            mediaStream.startAudio()
                .catch(reject)
            console.log('after-startAudio')

            this.params.client.on('current-audio-change', change => {
                if (change.action === AudioChangeAction.Join) {
                    if (startMuted) {
                        setTimeout(() => void mediaStream.muteAudio().catch(reject), 100)

                    } else {
                        resolve('unmuted')
                    }

                } else if (change.action === AudioChangeAction.Muted) {
                    resolve('muted')
                }
            })
        })

        return mediaStream.isAudioMuted() ? 'muted' : 'unmuted'
    }

    async joinSession (
        primus: PrimusClient,
        chatClient: ChatClient,
        classroomStateAtJoin: ClassroomState,
        options: IClientOptions
    ): Promise<RtcSession> {
        const zoomOptions = getZoomConfig(options)
        const { micHardMuted, ...wseEncodedUserInfo } = this.params.wseEncodedUserInfo

        await this.params.client.join(
            this.params.topic,
            this.params.signature.token,
            JSON.stringify(wseEncodedUserInfo),
            this.params.signature.sessionPassword
        ).catch(error => {
            console.log('join-session', error)
            rollbarService.rollbar.error(RollbarExceptionNames.JoinSessionError, error)
            throw error
        })

        const mediaStream = await this.params.client.getMediaStream()
        await this.waitForMediaCapabilities(mediaStream)
        await this.enableHwAcceleration(mediaStream)

        zoomOptions.isAudioMuted = micHardMuted ? true : zoomOptions.isAudioMuted
        await this.startAudioStream(mediaStream, zoomOptions.isAudioMuted)

        return new ZoomSession(
            primus,
            chatClient,
            this.params.wseEncodedUserInfo.id,
            this.params.client,
            classroomStateAtJoin,
            mediaStream,
            this.params.dispatch,
            this.params.canvas,
            this.params.selfView,
            zoomOptions
        )
    }
}

export type { MediaCapabilities }
export { ZoomClient }
